import React, { useState } from 'react';
import styles from './chatInputForm.module.scss';
import EventReaction from './EventReaction';
import { useTranslation } from 'react-i18next';

export default function ChatInputForm(props) {
    const { t } = useTranslation();
    const [inputMessage, setInputMessage] = useState('');
    const onSend = async (event) => {
        event.preventDefault();
        if (inputMessage && inputMessage !== '') {
            props.sendMessage(inputMessage, 'normal');
            setInputMessage('');
        }
    };

    return (
        <div className={`${styles.messageInput}`}>
            <form>
                <input
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder={t('WriteSomething')}
                />
                <button type="submit" value={t('Send')} onClick={onSend}>
                    <svg
                        className={'icon'}
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.56697 23.7548C9.31309 23.5009 9.22217 23.1231 9.33666 22.7797L11.3354 16.7834C11.4452 16.4541 11.7267 16.2103 12.0688 16.147L19.6685 15.0001L12.0688 13.8519C11.726 13.7893 11.4459 13.5442 11.3354 13.2155L9.33666 7.22064C9.21746 6.86035 9.32251 6.46302 9.60536 6.20981C9.88753 5.95593 10.295 5.89464 10.6391 6.05425L28.1484 14.1354C28.4838 14.2917 28.702 14.6271 28.702 15.0001C28.7026 15.3712 28.4858 15.7093 28.1484 15.8648L10.6391 23.946C10.2943 24.105 9.88753 24.0444 9.60536 23.7905C9.59256 23.779 9.57909 23.7669 9.56697 23.7548Z"
                            fill="#367B4E"
                        />
                    </svg>
                </button>
            </form>
            <EventReaction sendReaction={() => props.handleReactionSend(t('ReactionMessage'))} />
        </div>
    );
}
