import {CONVERSION_STATUS_RECEIVED, CONVERT_VIDEO_RECEIVED} from '../constants/actionTypes';
import ConversionStatus from '../core/entitites/conversionStatus';
import ConvertVideo from '../core/entitites/convertVideo';

export default (
    state = {
        convertVideoJobIds: [],
        conversionStatus: []
    },
    action
) => {
    switch (action.type) {
        case CONVERT_VIDEO_RECEIVED: {
            let newEntry = ConvertVideo.new(action.payload.data);

            if (newEntry) {
                let _convertVideoJobIds = state.convertVideoJobIds;

                if (_convertVideoJobIds.find((x) => x.fileName == newEntry.fileName)) {
                    _convertVideoJobIds = _convertVideoJobIds.map((x) => {
                        if (x.fileName == newEntry.fileName) {
                            return newEntry;
                        }
                        return x;
                    });
                    return {
                        ...state,
                        convertVideoJobIds: _convertVideoJobIds
                    };
                }

                return {
                    ...state,
                    convertVideoJobIds: [...state.convertVideoJobIds, newEntry]
                };
            }
            return state;
        }
        case CONVERSION_STATUS_RECEIVED: {
            let newEntry = ConversionStatus.new(action.payload.data);

            if (newEntry) {
                let _conversionStatus = state.conversionStatus;

                if (_conversionStatus.find((x) => x.jobId == newEntry.jobId)) {
                    _conversionStatus = _conversionStatus.map((x) => {
                        if (x.jobId == newEntry.jobId) {
                            return newEntry;
                        }
                        return x;
                    });
                    return {
                        ...state,
                        conversionStatus: _conversionStatus
                    };
                }

                return {
                    ...state,
                    conversionStatus: [...state.conversionStatus, newEntry]
                };
            }
            return state;
        }
        default:
            return state;
    }
};
