import {CLEAN_LAST_STREAM_DATA} from '../../../../constants/actionTypes';
import {store} from '../../../../store';

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const stopLiveStream = async () => {
    const storeState = store.getState();
    const { streamingClient, liveStreamStatus, config } = storeState.liveStreaming;
    const { currentUser } = storeState.user;
    if (liveStreamStatus) {
        if (window.debugEnabled === true) alert('Found active live stream. Stopping...');

        if (streamingClient) {
            try {
                streamingClient.stopBroadcast();
                store.dispatch({ type: CLEAN_LAST_STREAM_DATA });
            } catch (e) {
                console.error(e);
            }
        }

        if (config) {
            await timeout(1000);
            console.info('request deletion of active stream');
            await fetch(`${process.env.REACT_APP_API_URL}/ivs/deleteStream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    arn: config?.data?.channel?.arn,
                    userId: currentUser?.data?.sub ?? '-1'
                })
            })
                .then((r) => r.json())
                .then((r) => console.log(r))
                .catch((e) => console.error(e));
        }
    }

    return true;
};
