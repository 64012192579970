import imageCompression from 'browser-image-compression';

/**
 * Converts & compressed file, also cleans exif & geo tags from images.
 * @param {File} file
 * @param {Object} options
 * @returns converted file of type File
 */
export default async function useImageConversion(
    file,
    options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true, maxIteration: 10 }
) {
    return await imageCompression(file, {
        ...options,
        exifOrientation: await imageCompression.getExifOrientation(file)
    });
}
