import React from 'react';

const ErrorMessage = (props) => {
    return (
        <React.Fragment>
            <p style={{ color: 'red', marginTop: '74px', marginBottom: '-64px' }}>
                {props.errorMessage}
            </p>
        </React.Fragment>
    );
};

export default ErrorMessage;
