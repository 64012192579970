import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EventTag from './EventTag';
import liveHomepage from '../../assets/images/black-logo.svg';
import sanity from '../../sanity';
import ReactTimeAgo from 'react-time-ago';
import { useTranslation } from 'react-i18next';

const EventSummary = ({
    event,
    isLive,
    marginTop,
    countDownTrigger,
    publishingEvents,
    isPortrait
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [logo, setLogo] = useState(null);
    const company = useSelector((state) => state.common.company);

    useEffect(() => {
        let companyLogo = sanity.urlFor(company?.photo?.asset?._ref);
        setLogo(companyLogo || liveHomepage);
    }, [company]);

    const slug = typeof event.slug === 'string' ? event.slug : event.slug.current;

    return (
        <Box
            padding={2}
            sx={{
                marginTop: marginTop
                    ? countDownTrigger
                        ? ''
                        : publishingEvents?.length > 0
                        ? '37vh'
                        : // : isPortrait
                          // ? '50vh'
                          '28vh'
                    : ''
            }}>
            <div onClick={() => history.push(`/events/${slug}`)}>
                <Box display="flex">
                    <img src={logo} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />
                    <Box display="flex" flexDirection="column" paddingLeft={1}>
                        <Typography
                            fontFamily="Urbanist-Bold"
                            variant="p"
                            fontSize="17px"
                            fontWeight="700">
                            {isLive && <span>LIVE: </span>}
                            {event.title}
                        </Typography>

                        <Typography variant="body2" sx={{ margin: '0px !important' }}>
                            <ReactTimeAgo date={Date.parse(event.start_date)} />
                        </Typography>

                        <div style={{ display: 'flex' }}>
                            {event.isPremium && <EventTag key="Premium" tag="Premium" />}
                            {event.subscriber_exclusive && (
                                <EventTag key="Exclusive" tag="Exclusive" />
                            )}
                            {event.tags &&
                                event.tags.map((tag) => {
                                    return <EventTag key={tag} tag={tag} />;
                                })}
                        </div>
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default EventSummary;
