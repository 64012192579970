import React, { useEffect, useState } from 'react';
import MessageCard from './MessageCard';

export default function ChatContainer(props) {
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        if (props.messages.length > 0 && !props.chatLoading) {
            props.messages[props.messages.length - 1].animate = true;
        }
        // console.info( "REDUCED messsages" , Object.entries(props?.messages).reduce((previousValue, currentValue) => {
        //     return !previousValue.find((x) => x.messageId == currentValue[1].messageId)
        //         ? [...previousValue, currentValue[1]]
        //         : previousValue;
        // }, []))
        // iterate through all objects and filter out duplicates.
        setMessages(
            props.messages
            // Object.entries(props?.messages).reduce((previousValue, currentValue) => {
            //     return !previousValue.find((x) => x.messageId == currentValue[1].messageId)
            //         ? [...previousValue, currentValue[1]]
            //         : previousValue;
            // }, [])
        );
    }, [props.messages]);

    return (
        <>
            {/* <div>Messages: {props.messages.length}</div> */}
            <div id={'chat-scrollable'}>
                {messages.map((message, index) => {
                    return (
                        // <div key={index}>test</div>
                        <MessageCard
                            key={index}
                            isPubliclyVisible={index < 3}
                            message={message}
                            animate={message.animate}
                            eventSlug={props.eventSlug}
                            eventId={props.eventId}
                            setIsClicked={props.setIsClicked}
                            setEventOptionsProps={props.setEventOptionsProps}
                            moderator={props.moderator}
                            user={props.user}
                            renderChatLineActions={props.renderChatLineActions}
                            // publicUser={props.publicUser}
                            // publicUser={true}
                        />
                    );
                })}
            </div>
        </>
    );
}
