import React from 'react';
import styles from './messageCard.module.scss';
import toast from 'react-hot-toast';
import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
import { useIonToast } from '@ionic/react';
import { checkmarkCircleSharp } from 'ionicons/icons';

export default function MessageCard({
    message,
    animate,
    eventSlug,
    eventId,
    setEventOptionsProps,
    setIsClicked,
    isPubliclyVisible,
    publicUser,
    moderator,
    user,
    renderChatLineActions
}) {
    const { t } = useTranslation();
    const [present] = useIonToast();
    const presentToast = (message) => {
        present({
            message: message,
            duration: 5000,
            position: 'top',
            icon: checkmarkCircleSharp,
            color: 'success'
        });
    };
    const nickname =
        message.username?.trim().length > 0 ? message.username.split(' ') : null ?? ['C', 'B'];
    const initials =
        nickname.length === 1
            ? nickname[0].substring(0, 2)
            : nickname[0].charAt(0) + nickname[nickname.length - 1].charAt(0);
    // prettier-ignore
    const bind = publicUser
        ? {}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        : useLongPress(() => {
            if (message) {
                setEventOptionsProps({ eventSlug, eventId, isClicked: true, isMessage: true, message, setIsClicked, notify, initials });
            }
            setIsClicked(true);
        });

    const notify = (message) => {
        if (message === 'Event already exists') {
            presentToast(message);
        } else {
            presentToast(message);
        }
    };

    const getUserImage = () => {
        if (message && message.avatar) {
            return <img src={message.avatar} alt={t('Avatar')} className={styles.unselectable} />;
        }

        return (
            <div className={`${styles.profilePictureGenerated} ${styles.unselectable}`}>
                {initials}
            </div>
        );
    };

    const getUserMessage = () => {
        return (
            <aside className={styles.unselectable}>
                <span className={styles.unselectable}>{user.nickname}</span>
                <p style={{ display: 'flex' }} className={styles.unselectable}>
                    {message.message}{' '}
                    {/* {moderator && message.userId !== user.sub ? renderChatLineActions(message) : ''} */}
                </p>
            </aside>
        );
    };

    return (
        <div
            {...bind}
            className={`
                ${styles.messageLine} 
                ${animate ? styles.animated : ''} 
                ${styles.unselectable} 
                ${isPubliclyVisible === false && publicUser ? styles.blur : ''} 
                ${styles.pl} 
                ${styles.pr}
            `}>
            {getUserImage()}
            {getUserMessage()}
        </div>
    );
}
