export const getVideoAudioPermissions = async (t) => {
    let permissions;
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        });
        for (const track of stream.getTracks()) {
            track.stop();
        }
        permissions = { video: true, audio: true };
    } catch (err) {
        permissions = { video: false, audio: false };
        console.error(err.message);
    }

    if (!permissions.video) {
        alert(t('AccessPermissionRequestVideo'));
    } else if (!permissions.audio) {
        alert(t('AccessPermissionRequestAudio'));
    }

    return permissions;
};
