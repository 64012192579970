import React, {useEffect, useState} from 'react';
import sanity from '../../sanity';
import styles from './widget.module.scss';
import QuizOption from './QuizOption';
import {SET_ACTIVE_WIDGET, SET_WIDGET_RESULT} from '../../constants/actionTypes';
import {useDispatch} from 'react-redux';
import Confetti from 'react-confetti';
import {useTranslation} from 'react-i18next';

export default function QuizMultiWidget(props) {
    console.log('props', props);
    const { t } = useTranslation();
    const [question, setQuestion] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [countCorrect, setCountCorrect] = useState(0);
    const dispatch = useDispatch();
    let width;
    let height;
    width = window.offsetWidth;
    height = window.offsetHeight;
    const [quiz, setQuiz] = useState(null);
    useEffect(async () => {
        const quizId = props.data.options[0]._ref;
        const quizObject = await sanity.Widgets.GetQuizById(quizId);
        setQuiz(quizObject);

        setQuestion(quizObject.questions_set[0]);
        setQuestionIndex(0);
        setCountCorrect(0);
        let timer = scheeduleFinishGame(quizObject.reward_widget, props.data.duration);
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, []);
    const scheeduleFinishGame = (reward_widget, duration) => {
        return setTimeout(() => {
            finishGame(reward_widget);
        }, duration * 1000);
    };

    const finishGame = (reward_widget) => {
        let widgetElement = document.getElementById('widget');
        widgetElement.style.overflow = 'hidden';
        widgetElement.style.minHeight = 'unset';
        setShowResult(true);
        setTimeout(() => {
            dispatch({
                type: SET_ACTIVE_WIDGET,
                payload: quiz?.reward_widget ? { ...reward_widget, duration: 20 } : null
            });
            dispatch({
                type: SET_WIDGET_RESULT,
                payload: null
            });
        }, 20000);
    };

    const pickOption = (optionId) => {
        const correctAnswer = question.suggested_answers.find((answer) => answer.isCorrect);
        if (correctAnswer?._key === optionId) {
            setCountCorrect(countCorrect + 1);
        }

        if (questionIndex >= quiz.questions_set.length - 1) {
            finishGame(quiz.reward_widget);
        } else {
            setQuestion(quiz.questions_set[questionIndex + 1]);
            setQuestionIndex(questionIndex + 1);
        }
    };
    return (
        <div id={'quiz'} className={styles.quizWrapper}>
            {!showResult && question && (
                <div className={styles.multiQuestionHeader}>
                    <label>Fråga #{questionIndex + 1}</label>
                    <h1>{question.question}</h1>
                </div>
            )}
            {!showResult ? (
                question &&
                question.suggested_answers.map((option) => {
                    return (
                        <QuizOption
                            key={option._key}
                            value={option}
                            pickOption={pickOption}
                            textField={'answer'}
                            // selected={selected}
                        />
                    );
                })
            ) : (
                <div>
                    {countCorrect / quiz.questions_set.length >= 0.5 ? (
                        <div className={`${styles.multiAnswers} ${styles.multiSuccess}`}>
                            <Confetti
                                width={width}
                                height={height}
                                colors={['#55AD74', '#255A47', '#4FC53C', '#367B4E']}
                            />
                            <h1>{t('GoodJob')}</h1>
                            <label>{t('YouGot')}</label>
                            <div>
                                <h1>
                                    {countCorrect} / {quiz.questions_set.length}
                                </h1>
                                <label>{t('NumberCorrectAnswer')}</label>
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles.multiAnswers} ${styles.multiFail}`}>
                            <h1>{t('BetterLuckNextTime')}</h1>
                            <label>{t('YouGot')}</label>
                            <div>
                                <h1>
                                    {countCorrect} / {quiz.questions_set.length}
                                </h1>
                                <label>{t('NumberCorrectAnswer')}</label>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
