import React, { useEffect, useState } from 'react';
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { getVideoAudioPermissions } from './Helpers/videoAudioPerms';
import ChooseMediaDevices from './ChooseMediaDevices';
import PreviewLiveStream from './PreviewLiveStream';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    CREATE_EVENT,
    SET_CURRENT_LIVESTREAM,
    SET_IVS_STREAM_CONFIG,
    SET_MEDIA_DEVICES,
    SET_STREAMING_CLIENT
} from '../../../constants/actionTypes';
import { stopLiveStream } from './Helpers/stopLiveStream';
import styles from '../streaming.module.scss';
import LiveStreamInputs from './LiveStreamInputs';
import { EventTypes } from './Helpers/eventTypes';
import LiveStreamHeader from './LiveStreamHeader';
import rotate from '../../../assets/images/rotate-icon.svg';
import { useTranslation } from 'react-i18next';
import { IonContent, IonPage } from '@ionic/react';

const streamConfig = IVSBroadcastClient.STANDARD_LANDSCAPE;

const API_ENDPOINTS = {
    createStream: `${process.env.REACT_APP_API_URL}/ivs/createStream`,
    prepareStreamEvent: `${process.env.REACT_APP_API_URL}/ivs/prepare-stream`
};

const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming,
        user: state.user.currentUser,
        company: state.common.company,
        orientation: state.appState.deviceOrientation
    };
};

const mapDispatchToProps = (dispatch) => ({
    setMediaDevices: (mediaDevices) =>
        dispatch({
            type: SET_MEDIA_DEVICES,
            payload: mediaDevices
        }),
    setIvsConfig: (ivsConfig) =>
        dispatch({
            type: SET_IVS_STREAM_CONFIG,
            payload: ivsConfig
        }),
    setLiveStreamStatus: (livestreamLive) =>
        dispatch({
            type: SET_CURRENT_LIVESTREAM,
            payload: livestreamLive
        }),
    setStreamingClient: (streamingClient) =>
        dispatch({
            type: SET_STREAMING_CLIENT,
            payload: streamingClient
        }),
    createEventSanity: (eventPayload) => dispatch({ type: CREATE_EVENT, payload: eventPayload })
});

function LiveStreaming({
    mediaDevices,
    setMediaDevices,
    config,
    setIvsConfig,
    setLiveStreamStatus,
    streamingClient,
    setStreamingClient,
    liveStreamStatus,
    user,
    company,
    createEventSanity,
    orientation
}) {
    const { t } = useTranslation();
    const eventTypes = EventTypes();
    const history = useHistory();
    const [eventTitle, setEventTitle] = useState('');
    const [eventType, setEventType] = useState('video_event');
    const [initialTags, setInitialTags] = useState([]);
    const [isStoppingStream, setIsStoppingStream] = useState(false);

    useEffect(() => history.listen(() => stopLiveStream()), [history]);

    const initStreamConfig = () => {
        fetch(API_ENDPOINTS.createStream)
            .then((r) => r.json())
            .then((r) => {
                if (r.success) {
                    if (r.data.channel.ingestEndpoint) {
                        setStreamingClient(
                            IVSBroadcastClient.create({
                                streamConfig,
                                ingestEndpoint: `rtmps://${r.data.channel.ingestEndpoint}:443/app/`
                            })
                        );
                    }

                    setIvsConfig(r);
                } else {
                    alert(r.message);
                }
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        initStreamConfig();
        (async () => {
            const perms = await getVideoAudioPermissions(t);
            if (perms.video && perms.audio) {
                navigator.mediaDevices.enumerateDevices().then((devices) => {
                    window.videoDevices = devices.filter((d) => d.kind === 'videoinput');
                    window.audioDevices = devices.filter((d) => d.kind === 'audioinput');
                    setMediaDevices({
                        ...mediaDevices,
                        videoDevices: window.videoDevices,
                        audioDevices: window.audioDevices
                    });
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (streamingClient && mediaDevices.devicesRegistered === false) {
            (async () => {
                await Promise.all([
                    mediaDevices.videoDevices.map(async (videoDevice, index) => {
                        if (index === mediaDevices.selectedVideoDevice) {
                            streamingClient.addVideoInputDevice(
                                await navigator.mediaDevices.getUserMedia({
                                    video: {
                                        deviceId: videoDevice.deviceId,
                                        width: {
                                            ideal: streamConfig.maxResolution.width,
                                            max: streamConfig.maxResolution.width
                                        },
                                        height: {
                                            ideal: streamConfig.maxResolution.height,
                                            max: streamConfig.maxResolution.height
                                        }
                                    }
                                }),
                                `videoDevice-${index}`,
                                { index }
                            );
                        }
                    }),
                    mediaDevices.audioDevices.map(async (audioDevice, index) => {
                        if (mediaDevices.selectedAudioDevice === index) {
                            //? needs to be like this because on switch for audio remove and add is called

                            streamingClient.addAudioInputDevice(
                                await navigator.mediaDevices.getUserMedia({
                                    audio: {
                                        deviceId: audioDevice.deviceId
                                    }
                                }),
                                `audioDevice-${index}`,
                                { index }
                            );
                        }
                    })
                ]);

                const previewDom = document.getElementById('preview');
                streamingClient.attachPreview(previewDom);

                setMediaDevices({ ...mediaDevices, devicesRegistered: true });

                previewDom.removeAttribute('width');
                previewDom.removeAttribute('height');
            })();
        }
    }, [streamingClient, mediaDevices]);

    const chooseVideoDevice = (index = 0) => {
        streamingClient.removeVideoInputDevice(`videoDevice-${mediaDevices.selectedVideoDevice}`);
        (async () => {
            streamingClient.addVideoInputDevice(
                await navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: mediaDevices.videoDevices[index].deviceId,
                        width: {
                            ideal: streamConfig.maxResolution.width,
                            max: streamConfig.maxResolution.width
                        },
                        height: {
                            ideal: streamConfig.maxResolution.height,
                            max: streamConfig.maxResolution.height
                        }
                    }
                }),
                `videoDevice-${index}`,
                { index }
            );

            setMediaDevices({ ...mediaDevices, selectedVideoDevice: index });
        })();
    };

    const chooseAudioDevice = (index = 0) => {
        streamingClient.removeAudioInputDevice(`audioDevice-${mediaDevices.selectedAudioDevice}`);

        (async () => {
            streamingClient.addAudioInputDevice(
                await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: mediaDevices.audioDevices[index].deviceId
                    }
                }),
                `audioDevice-${index}`,
                { index }
            );

            setMediaDevices({ ...mediaDevices, selectedAudioDevice: index });
        })();
    };

    const muteMicrophone = () => {
        const audioStream = streamingClient.getAudioInputDevice(
            `audioDevice-${mediaDevices.selectedAudioDevice}`
        );
        audioStream.getAudioTracks()[0].enabled = false;
        setMediaDevices({ ...mediaDevices, mutedMicrophone: true });
    };

    const unmuteMicrophone = () => {
        const audioStream = streamingClient.getAudioInputDevice(
            `audioDevice-${mediaDevices.selectedAudioDevice}`
        );
        audioStream.getAudioTracks()[0].enabled = true;
        setMediaDevices({ ...mediaDevices, mutedMicrophone: false });
    };

    const startStream = async () => {
        streamingClient
            .startBroadcast(config.data.streamKey.value) //ingest endpoint
            .then(() => {
                setLiveStreamStatus({ live: true });

                var today = new Date();
                createEventSanity({
                    title: eventTitle,
                    slug: {
                        type: 'slug',
                        current: eventTitle + Math.ceil(Math.random() * 1000000)
                    },
                    startDate: today,
                    event_type: 'video_event',
                    stream_type: 'webrtc_ivs',
                    endpoint: config.data.channel.playbackUrl,
                    liveStreamUrl: config.data.channel.playbackUrl,
                    channel_id: config.data.channel.arn,
                    channel_status: 'created',
                    streamStatus: 'upcoming',
                    user_id: user.data.sub,
                    tags: [
                        eventTypes.find((et) => et.value === eventType)?.title,
                        ...getAllCheckedTags()
                    ],
                    resource_creation_time: 0,
                    sports_team: {
                        _ref: process.env.REACT_APP_COMPANY_ID,
                        _type: 'reference'
                    }
                });

                setTimeout(() => {
                    fetch(API_ENDPOINTS.prepareStreamEvent, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ivs: { playbackUrl: config.data.channel.playbackUrl }
                        })
                    })
                        .then((r) => r.json())
                        .catch((e) => console.log(e));
                }, 1000);
            })
            .catch((error) => {
                console.error('Something drastically failed while broadcasting!', error);
            });
    };

    const stopStream = async () => {
        setIsStoppingStream(true);
        await stopLiveStream();
        setIsStoppingStream(false);
        setEventTitle('');
        //TODO: find better way to reload then page reload.
        removeVideoAndAudioActiveDevices();
        history.push('/profile');
    };

    const getAllCheckedTags = () => {
        var tags = initialTags.filter((x) => x['tagStatus'] === true);
        let tagNames = [];
        tags.forEach((element) => {
            tagNames.push(element.tagName);
        });

        return tagNames;
    };

    const removeVideoAndAudioActiveDevices = () => {
        const audioStream = streamingClient?.getAudioInputDevice(
            `audioDevice-${mediaDevices.selectedAudioDevice}`
        );
        if (audioStream) {
            streamingClient.removeAudioInputDevice(
                `audioDevice-${mediaDevices.selectedAudioDevice}`
            );
        }
        const videoStream = streamingClient?.getVideoInputDevice(
            `videoDevice-${mediaDevices.selectedVideoDevice}`
        );
        if (videoStream) {
            streamingClient.removeVideoInputDevice(
                `videoDevice-${mediaDevices.selectedVideoDevice}`
            );
        }
    };

    return (
        <IonPage>
            <IonContent>
                <div className={`${styles.streamingWrapper}`}>
                    {/* Header */}
                    <LiveStreamHeader
                        historyPush={(e) => {
                            removeVideoAndAudioActiveDevices();
                            history.push(e);
                        }}
                        setMediaDevices={(e) => setMediaDevices(e)}
                        mediaDevices={mediaDevices}
                    />
                    <PreviewLiveStream
                        mediaDevices={mediaDevices}
                        liveStreamStatus={liveStreamStatus}
                        config={config}
                    />
                    <ChooseMediaDevices
                        mediaDevices={mediaDevices}
                        setMediaDevices={setMediaDevices}
                        chooseVideoDevice={chooseVideoDevice}
                        chooseAudioDevice={chooseAudioDevice}
                        muteMicrophone={muteMicrophone}
                        unmuteMicrophone={unmuteMicrophone}
                    />
                    <LiveStreamInputs
                        setEventTitle={(e) => setEventTitle(e)}
                        setEventType={(e) => setEventType(e)}
                        eventType={eventType}
                        initialTags={initialTags}
                        setInitialTags={(c) => setInitialTags(c)}
                        company={company}
                    />

                    {liveStreamStatus.live === false ? (
                        <>
                            {config ? (
                                <>
                                    {orientation === 'PORTRAIT' && (
                                        <div className={styles.showRotateMessage}>
                                            <img src={rotate} alt={t('RotatePhone')} />
                                            <p>{t('RotatePhoneToStartStream')}</p>
                                        </div>
                                    )}
                                    <button
                                        onClick={startStream}
                                        disabled={eventTitle == '' || orientation == 'PORTRAIT'}>
                                        {t('StartStream')}
                                    </button>
                                </>
                            ) : (
                                <i>{t('StreamPreparing')}</i>
                            )}
                        </>
                    ) : (
                        <button onClick={stopStream} disabled={isStoppingStream}>
                            {t('StopStream')}
                        </button>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreaming);
