import { useEffect, useState } from 'react';
import sanity from '../sanity.js';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const Spinner = (props) => {
    const { visible } = props;

    const [company, setCompany] = useState(null);

    useEffect(() => {
        const getCompany = async () => {
            const companyId = await sanity.Companies.GetCompanyById(
                process.env.REACT_APP_COMPANY_ID
            );
            setCompany(companyId);
        };
        getCompany();
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.8)',
                    zIndex: '10000'
                }}></div>
            <div
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '10001',
                    textAlign: 'center'
                }}>
                <Loader
                    type="TailSpin"
                    color={'#32E7B2'}
                    secondaryColor="Grey"
                    height={100}
                    width={100}
                    visibale={props.visible}
                />
                <div
                    style={{
                        color: 'white',
                        fontWeight: 600,
                        fontSize: '42px',
                        textAlign: 'center',
                        marginTop: '50px'
                    }}>
                    {visible.percentage && `${visible?.percentage} %`}
                    {visible.description && `${visible?.description}`}
                </div>
            </div>
        </div>
    );
};

export default Spinner;
