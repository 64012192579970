import {applyMiddleware, createStore} from 'redux';
import reducer from './reducer';
import {createLogger} from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {createBrowserHistory, createHashHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import {promiseMiddleware} from './middleware';
import createSagaMiddleware from 'redux-saga';
import chatSaga from './sagas/chatSaga';
import videoSaga from './sagas/videoSaga';
import socketSaga from './sagas/socketSaga';
import NewSocket from './socket';

let historyInstance = createBrowserHistory();

if (window.cordova) {
    historyInstance = createHashHistory();
}

export const history = historyInstance;

const sagaMiddleware = createSagaMiddleware();

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware(history), promiseMiddleware, sagaMiddleware);
    } else {
        return applyMiddleware(
            routerMiddleware(history),
            promiseMiddleware,
            sagaMiddleware,
            createLogger()
        );
    }
};

const createdStore = createStore(reducer(history), composeWithDevTools(getMiddleware()));

const socket = NewSocket(createdStore.dispatch);
socket.connect();

createdStore.chatSaga = sagaMiddleware.run(chatSaga, { socket, createdStore });
createdStore.videoSaga = sagaMiddleware.run(videoSaga, { socket });
createdStore.socketSaga = sagaMiddleware.run(socketSaga, { socket, createdStore });
export const store = createdStore;
