import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { CLEAR_ERROR } from '../../constants/actionTypes';
import { useEffect } from 'react';
import { closeCircleSharp } from 'ionicons/icons';
import { useIonToast } from '@ionic/react';

const ErrorToast = () => {
    const error = useSelector((state) => state.errors);
    const dispatch = useDispatch();
    const [present] = useIonToast();
    const presentToast = (message) => {
        present({
            message: message,
            duration: 4000,
            position: 'top',
            icon: closeCircleSharp,
            color: 'danger',
            onDidDismiss: dispatch({ type: CLEAR_ERROR })
        });
    };

    useEffect(() => {
        if (error.message) {
            presentToast(error.message.message);
        }
    }, [error, dispatch]);

    return null;
};

export default ErrorToast;
