import {ORIENTATION_CHANGE, SET_EVENT_TAB_ACTIVE, SET_VIDEO_MUTED_STATE} from '../constants/actionTypes';

export default (
    state = {
        eventTabActive: 0,
        video: {
            muted: true
        },
        deviceOrientation: 'LANDSCAPE'
    },
    action
) => {
    switch (action.type) {
        case SET_EVENT_TAB_ACTIVE: {
            return {
                ...state,
                eventTabActive: action.payload
            };
        }
        case SET_VIDEO_MUTED_STATE: {
            return {
                ...state,
                video: { ...state.video, muted: action.payload }
            };
        }

        case ORIENTATION_CHANGE: {
            return {
                ...state,
                deviceOrientation: action.payload
            };
        }

        default:
            return state;
    }
};
