import React from 'react';
import styles from '../streaming.module.scss';
import OnBack from '../../Auth/OnBack';
import camera from '../../../assets/images/camera.svg';
import { useTranslation } from 'react-i18next';

function LiveStreamHeader({ setMediaDevices, historyPush, mediaDevices }) {
    const { t } = useTranslation();
    return (
        <header>
            <OnBack defaultHref={'/profile'} title={t('StartStream')} />
            <img
                src={camera}
                className={styles.cameraIcon}
                onClick={() =>
                    setMediaDevices({ ...mediaDevices, showChoice: !mediaDevices.showChoice })
                }
            />
        </header>
    );
}

export default LiveStreamHeader;
