import { CircularProgress, IconButton, Typography } from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Document, Page, pdfjs } from 'react-pdf';
import { IonContent, IonPage } from '@ionic/react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import dayjs from 'dayjs';
import { connect, useSelector } from 'react-redux';
import fetch from '../../fetch';
import { getSymbolForCurrency } from '../../utils';
import { fromUnitAmount } from '../../utils/unitAmountHelper';
import OnBack from '../Auth/OnBack';
import styles from '../Profile/Customize/customize.module.scss';
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack())
});

const InvoicesPage = (props) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.currentUser);
    const isAdmin = useSelector(
        (state) => state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    );
    const connected_account = useSelector((state) => state.common.connected_account);
    // const streamingStats = useSelector((state) => state.common?.company?.streamingStats);
    // const [drawerOpen, setDrawerOpen] = useState(false);
    // const [selectedItem, setSelectedItem] = useState(null);
    // const [pdf, setPdf] = useState(null);
    // const [loadingPdf, setLoadingPdf] = useState(true);
    const [loading, setLoading] = useState(false);
    const [invoicesList, setInvoicesList] = useState([]);
    const [upcomingInvoice, setUpcomingInvoice] = useState(null);

    const handleItemClick = (item) => {
        const link = document.createElement('a');
        link.download = item.number;
        link.href = item.invoice_pdf;
        link.click();
    };

    // const handleItemClick = async (item) => {
    //     setDrawerOpen(true);
    //     setSelectedItem(item);
    //     setPdf(null);
    //     setLoadingPdf(true);
    //     let pdfRes = await handlePdf(item.invoice_pdf);
    //     if (pdfRes) {
    //         setPdf({ data: JSON.parse(pdfRes).data });
    //     }
    //     setLoadingPdf(false);
    // };

    // const closeDrawer = () => {
    //     setDrawerOpen(false);
    //     setSelectedItem(null);
    // };

    useEffect(() => {
        const fetchData = async () => {
            if (user && user.premium_services?.subscription) {
                setLoading(true);

                try {
                    const accountId = !isAdmin ? connected_account?.accountId : undefined;
                    const invoicesList = await fetch.Invoice.getAllInvoices(
                        user.premium_services?.subscription,
                        accountId
                    );
                    setInvoicesList(invoicesList);

                    const upcomingInvoice = await fetch.Invoice.getUpcomingInvoice(
                        user.premium_services?.subscription,
                        accountId
                    );
                    setUpcomingInvoice(upcomingInvoice);
                    console.log('upcomingInvoice: ', upcomingInvoice);

                    // const upcomingInvoiceList = {
                    //         storage: '25666 $',
                    //         traffic: '25666 $',
                    //         stream: '25666 $',
                    //         created: '25-08-2023'
                    // };
                } catch (error) {
                    console.error('Error:', error);
                }

                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    // async function handlePdf(prurl) {
    //     let buffer = await fetch.Invoice.getInvoicePdf(prurl);
    //     return buffer;
    // }

    return (
        <IonPage>
            <OnBack defaultHref={'/profile'} title={t('MyInvoices')} />
            <IonContent>
                <div className={styles.profileWrapper}>
                    <div className={styles.header}></div>
                    <div style={{ padding: '2vh 2vw 0vh' }}>
                        {loading ? (
                            <div
                                style={{
                                    position: 'relative'
                                }}>
                                <CircularProgress
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '45%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                />
                            </div>
                        ) : (
                            <>
                                {isAdmin && (
                                    <div>
                                        {/* {streamingStats && (
                                    <div
                                        className={styles.flexBetween}
                                        style={{
                                            marginBottom: '2vh'
                                        }}>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: 'center'
                                            }}>
                                            <Typography textAlign={'center'} fontSize={'30px'}>
                                                {streamingStats.storage} GB
                                            </Typography>
                                            <Typography
                                                color={'gray'}
                                                textAlign={'center'}
                                                fontSize={'14px'}>
                                                Storage
                                            </Typography>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: 'center',
                                                borderLeft: '1px solid #888',
                                                borderRight: '1px solid #888',
                                                padding: '0 4vw'
                                            }}>
                                            <Typography textAlign={'center'} fontSize={'30px'}>
                                                {streamingStats.traffic}
                                            </Typography>
                                            <Typography
                                                color={'gray'}
                                                textAlign={'center'}
                                                fontSize={'14px'}>
                                                Traffic
                                            </Typography>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                textAlign: 'center'
                                            }}>
                                            <Typography textAlign={'center'} fontSize={'30px'}>
                                                {streamingStats.stream}
                                            </Typography>
                                            <Typography
                                                color={'gray'}
                                                textAlign={'center'}
                                                fontSize={'14px'}>
                                                Streams
                                            </Typography>
                                        </div>
                                    </div>
                                )} */}
                                        {upcomingInvoice && (
                                            <div
                                                className={styles.flexBetween}
                                                style={{
                                                    marginBottom: '2vh'
                                                }}>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textAlign: 'center',
                                                        padding: '0 4vw'
                                                    }}>
                                                    <Typography
                                                        textAlign={'center'}
                                                        fontSize={'20px'}>
                                                        {t('UpcomingInvoice')}
                                                    </Typography>
                                                    <Typography
                                                        textAlign={'center'}
                                                        fontSize={'30px'}>
                                                        {`${getSymbolForCurrency(
                                                            upcomingInvoice.currency
                                                        )}
                                                  ${upcomingInvoice.amount_due}`}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div>
                                    {invoicesList.map((item) => (
                                        // <ListItem key={item.id} onClick={() => handleItemClick(item)}>
                                        //     <ListItemText primary={item.amount} />
                                        //     <EuroSymbol fontSize="inherit" style={{ fontSize: '17px' }} />
                                        //     {item.created}
                                        //     <ListItemIcon>
                                        //         <ArrowForwardIosIcon />
                                        //     </ListItemIcon>
                                        // </ListItem>
                                        <div
                                            key={item.id}
                                            className={styles.flexBetween}
                                            style={{
                                                borderBottom: '1px solid rgba(198, 198, 200, 0.5)'
                                            }}>
                                            <div
                                                className={styles.flexBetween}
                                                style={{
                                                    padding: '2vh 0 '
                                                }}>
                                                <Typography
                                                    style={{ marginRight: '12px', color: 'gray' }}>
                                                    {`${getSymbolForCurrency(
                                                        item.currency
                                                    )} ${fromUnitAmount(
                                                        item.amount_paid + item.amount_remaining,
                                                        item.currency
                                                    )}`}
                                                </Typography>
                                                <Typography>
                                                    {dayjs(item.created * 1000).format(
                                                        'MMM D, YYYY h:mm A'
                                                    )}
                                                </Typography>
                                                {/* <Typography>{item.created}</Typography> */}
                                            </div>
                                            <IconButton onClick={() => handleItemClick(item)}>
                                                <FileDownloadOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>

                                {/* <Drawer anchor="bottom" open={drawerOpen} onClose={closeDrawer}>
                            <div>
                                <div
                                    style={{
                                        backgroundColor: '#E4E4E4',
                                        height: 'calc(100vh - 100px)',
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        zIndex: 999
                                    }}>
                                    <div
                                        style={{
                                            backgroundColor: '#FFFFFF',
                                            padding: '5px',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                        <IconButton onClick={closeDrawer}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 'calc(100% - 70px)',
                                            overflow: 'auto'
                                        }}>
                                        {loadingPdf ? (
                                            <CircularProgress />
                                        ) : pdf ? (
                                            <div style={{ width: '380px', margin: '0 auto' }}>
                                                <Document loading={null} file={pdf}>
                                                    <Page
                                                        loading={null}
                                                        pageNumber={1}
                                                        width={380}
                                                    />
                                                </Document>
                                            </div>
                                        ) : (
                                            <Typography>No data selected</Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Drawer> */}
                            </>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(null, mapDispatchToProps)(InvoicesPage);
