import {takeEvery} from 'redux-saga/effects';
import {
    CHANGE_USER_GROUP,
    CREATE_EVENT,
    CUSTOM_PHONE_CREATE_CHALLENGE,
    EVENT_PAGE_LOADED, GET_FOLLOWERS, GET_WATCH_COUNT,
    INVITE_BY_SMS,
    PEOPLE_WATCHING,
    PUSH_TOKEN_UPDATE, SET_WATCH_COUNT,
    SOCKET_CONNNECT,
    SOCKET_DISCONNNECT,
    STOP_CHANNEL,
    UPDATE_EVENT,
    VOTE_WIDGET
} from '../constants/actionTypes';

let isPublicUser = true;

const sendMessage = (socket, message) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => sendMessage(socket, message), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'sendmessage',
                room_id: message.roomId,
                user_id: message.userName,
                body: message.messageContent,
                type: message.messageType,
                user: message.user,
                company_id: process.env.REACT_APP_COMPANY_ID
            })
        );
    }
};

const sendGetEventRoom = (socket, message) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => sendGetEventRoom(socket, message), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'geteventroom',
                event_id: message.id
            })
        );
    }
};


const voteWidget = (socket, payload) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => voteWidget(socket, payload), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'widgetvote',
                user: {
                    username: payload.username
                },
                option_id: payload.optionId,
                widget_id: payload.widgetId
            })
        );
    }
};

const inviteBySms = (socket, payload) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => inviteBySms(socket, payload), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'invitefriendssms',
                phoneNumber: payload.phoneNumber,
                name: payload.name
            })
        );
    }
};

const pushTokenUpdate = (socket, payload) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => pushTokenUpdate(socket, payload), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'addusertopinpoint',
                ...payload
            })
        );
    }
};
const peopleWatching = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => peopleWatching(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'getpeoplewatching',
                ...body
            })
        );
    }
};

const increaseWatchCount = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => increaseWatchCount(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'increasewatchcount',
                ...body
            })
        );
    }
};

const getWatchCount = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => getWatchCount(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'getwatchcount',
                ...body
            })
        );
    }
};

const customPhoneCreateChallenge = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => customPhoneCreateChallenge(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'customphonecreatechallenge',
                ...body
            })
        );
    }
};

// const customPhoneDefineChallenge = (socket, body) => {
//     if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
//         setTimeout(() => customPhoneDefineChallenge(socket, body), 300);
//     } else {
//         socket.Client.send(
//             JSON.stringify({
//                 action: 'customphonedefinechallenge',
//                 ...body
//             })
//         );
//     }
// };

// const customPhoneVerifyChallenge = (socket, body) => {
//     if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
//         setTimeout(() => customPhoneVerifyChallenge(socket, body), 300);
//     } else {
//         socket.Client.send(
//             JSON.stringify({
//                 action: 'customphoneverifychallenge',
//                 ...body
//             })
//         );
//     }
// };

const createEvent = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => createEvent(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'createevent',
                ...body
            })
        );
    }
};

const updateEvent = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => updateEvent(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'updateevent',
                ...body
            })
        );
    }
};

const stopChannel = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => sendMessage(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'stopchannel',
                ...body
            })
        );
    }
};


const getCompanyFollowers = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => getCompanyFollowers(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'company_followers',
                company_id: body.company_id
            })
        );
    }
};

const changeUserGroup = (socket, body) => {
    if (isPublicUser) return;

    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => changeUserGroup(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'change_user_group',
                company_id: body.company_id,
                type: body.type,
                group_name: body.group,
                user: body.user
            })
        );
    }
};



const socketConnect = async (socket) => {
    if (!socket.Client) {
        await socket.connect();
    }
};

const socketDisconnect = (socket) => {
    socket.disconnect();
};

const handleNewMessage = function* handleNewMessage({ socket, createdStore }) {
    createdStore.subscribe(() => {
        isPublicUser = createdStore.getState()?.user?.currentUser?.token === null;
    });

    yield takeEvery(SOCKET_CONNNECT, () => {
        socketConnect(socket);
    });

    yield takeEvery(SOCKET_DISCONNNECT, () => {
        socketDisconnect(socket);
    });

    yield takeEvery(EVENT_PAGE_LOADED, (action) => {
        sendGetEventRoom(socket, action.payload);
    });

    yield takeEvery(VOTE_WIDGET, (action) => {
        voteWidget(socket, action.payload);
    });

    yield takeEvery(INVITE_BY_SMS, (action) => {
        inviteBySms(socket, action.payload);
    });

    yield takeEvery(CREATE_EVENT, (action) => {
        createEvent(socket, action.payload);
    });
    yield takeEvery(UPDATE_EVENT, (action) => {
        updateEvent(socket, action.payload);
    });

    yield takeEvery(STOP_CHANNEL, (action) => {
        stopChannel(socket, action.payload);
    });

    yield takeEvery(PUSH_TOKEN_UPDATE, (action) => {
        pushTokenUpdate(socket, action.payload);
    });

    yield takeEvery(PEOPLE_WATCHING, (action) => {
        peopleWatching(socket, action.payload);
    });

    yield takeEvery(SET_WATCH_COUNT, (action) => {
        increaseWatchCount(socket, action.payload);
    });

    yield takeEvery(GET_WATCH_COUNT, (action) => {
        getWatchCount(socket, action.payload);
    });

    yield takeEvery(CUSTOM_PHONE_CREATE_CHALLENGE, (action) => {
        customPhoneCreateChallenge(socket, action.payload);
    });

    yield takeEvery(GET_FOLLOWERS, (action) => {
        getCompanyFollowers(socket, action.payload);
    });

    yield takeEvery(CHANGE_USER_GROUP, (action) => {
        changeUserGroup(socket, action.payload);
    });

    // yield takeEvery(CUSTOM_PHONE_DEFINE_CHALLENGE, (action) => {
    //     customPhoneDefineChallenge(socket, action.payload);
    // });

    // yield takeEvery(CUSTOM_PHONE_VERIFY_CHALLENGE, (action) => {
    //     customPhoneVerifyChallenge(socket, action.payload);
    // });
};

export default handleNewMessage;
