import { Button, CircularProgress, Modal, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import styles from './createVideo.module.scss';
import { useTranslation } from 'react-i18next';

const PermissionsModal = ({ showPermissionsModal, setShowPermissionsModal }) => {
    const { t } = useTranslation();
    if (!showPermissionsModal) {
        return null;
    }
    return (
        <Modal open={showPermissionsModal}>
            <div className={isMobile ? styles.modalMobile : styles.modal}>
                <Typography
                    marginTop={isMobile ? '3%' : '-1%'}
                    fontSize={'17px'}
                    textAlign={'center'}>
                    {t('PermissionsModal')}
                </Typography>
                <div className={styles.flexContainer}>
                    <Button
                        className={isMobile ? styles.confirmButtonMobile : styles.confirmButton}
                        onClick={() => {
                            setShowPermissionsModal(false);
                            window.location.reload();
                        }}>
                        {t('OK')}
                    </Button>
                    <Button
                        className={isMobile ? styles.cancelButtonMobile : styles.cancelButton}
                        onClick={() => {
                            setShowPermissionsModal(false);
                        }}>
                        {t('Cancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default PermissionsModal;
