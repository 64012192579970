import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import OnBack from '../Auth/OnBack';
import styles from './reportInfo.module.scss';
import { push } from 'connected-react-router';
import { friendlyTime } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        reportMessage: state.event.reportMessage,
        events: state.event.events,
        company: state.common.company
    };
};

const ReportInfo = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [shareOnEmail, setShareOnEmail] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [textReport, setTextReport] = useState('');
    const [subjectReport, setSubjectReport] = useState('');
    const [emailLink, setEmailLink] = useState('');

    const sendReport = () => {
        var report = document.getElementById('reportText').value;
        if (report.length > 0) {
            setDisabled(false);
            setShareOnEmail(true);
        } else {
            setDisabled(true);
        }
        let eventId = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

        let { title, startDate } = props.events.find((x) => x.id == eventId) ?? {
            title: undefined,
            startDate: undefined
        };

        setSubjectReport(
            `${t('ChatNotification')} - ${title ?? eventId} - ${friendlyTime(startDate)}`
        );

        if (eventId.includes('%')) eventId = decodeURI(eventId);

        const event = props.events.find((x) => x.id == eventId);
        const message = props.reportMessage;
        // console.log('message: ', props.reportMessage); .user e visak

        const textReport = `
${props.reportTitle}
${
    message
        ? `

${t('ReportedUserId')}: ${message.user.userId}
${t('ReportedUser')}: ${message.user.nickname} 
${t('MessageId')}: ${message.id}
${t('MessageContent')}: ${message.messageContent}
    
`
        : ''
}
${t('Rapport')}: ${report}
`;

        setSubjectReport(`${t('ChatNotification')} ${event?.title ?? eventId} (${event?.id})`);
        setTextReport(textReport);
        return textReport;
    };

    const dispatch = useDispatch();
    const shareReport = () => {
        setTimeout(function () {
            history.push('/events');
        }, 5000);
    };

    const email = props.company.creator;

    useEffect(() => {
        let link = `mailto:${email}?subject=${encodeURIComponent(
            subjectReport
        )}&body=${encodeURIComponent(textReport)}`;
        setEmailLink(link);
    }, [subjectReport, textReport]);

    return (
        <React.Fragment>
            <div className={styles.profileWrapper}>
                <header>
                    <OnBack defaultHref={'/events'} title={t('ReportContents')} />
                </header>

                <p className={styles.reportOption}> {props.reportTitle}</p>
                <div>
                    <textarea
                        id="reportText"
                        className={styles.textReport}
                        onChange={sendReport}
                        placeholder={t('ReportContentDetail')}></textarea>
                </div>

                {shareOnEmail && (
                    <div className={styles.sharingPopup}>
                        <a href={emailLink} data-rel="external">
                            <button
                                className={`${
                                    disabled
                                        ? `${styles.sendReportButtonDisabled}`
                                        : `${styles.sendReportButton}`
                                } ${styles.shareButton}`}
                                onClick={shareReport}
                                disabled={disabled}>
                                {t('SendViaEmail')}
                            </button>
                        </a>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};
export default connect(mapStateToProps)(ReportInfo);
