import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import bajen from '../../assets/images/bajen-idag.svg';
import sanity from '../../sanity';
import styles from '../../styles/auth.module.scss';

const Auth = () => {
    const company = useSelector((state) => state.common.company);
    const { t } = useTranslation();
    const history = useHistory();

    const [enableMobileRegistration, setEnableMobileRegistration] = useState(false);
    const [enableEmailRegistration, setEnableEmailRegistration] = useState(false);

    const [enableMobileLogin, setEnableMobileLogin] = useState(false);
    const [enableEmailLogin, setEnableEmailLogin] = useState(false);

    const email = 'hej@bajenidag.app';
    const subject = 'Medlemskap Bajen Idag!';
    const body = `Hej!\n\nJag vill bli medlem i Bajen Idag! med den här e-postadressen: ${email}`;

    const emailLink = `mailto:${email}?subject=${encodeURIComponent(
        subject
    )}&body=${encodeURIComponent(body)}`;

    useEffect(() => {
        if (company !== null) {
            setEnableEmailRegistration(company.enable_mail_registration);
            setEnableMobileRegistration(company.enable_mobile_registration);
            setEnableMobileLogin(company.enable_mobile_register);
            setEnableEmailLogin(company.enable_email_register);
        }
    });

    useEffect(() => {
        const el = document.getElementById('bg-wrapper');
        if (el) el.style.background = 'white';
        if (company) {
            // if (company.used_login_background == 'color' && company.backgroundCompanyColor != '') {
            //     el.style.background =
            //         'linear-gradient(294.74deg,var(--background-company-color) 9.44%,var(--background-company-color) 103.14%)';
            // } else
            if ((company.used_login_background = 'img' && company.bgPhoto_loginScreen)) {
                el.style.backgroundImage =
                    'url(' +
                    `${
                        company && company.bgPhoto_loginScreen.asset
                            ? sanity.urlFor(company.bgPhoto_loginScreen.asset)
                            : null
                    }` +
                    ')';
                el.style.position = 'left center';
                el.style.backgroundSize = 'cover';
                el.style.backgroundRepeat = 'no-repeat';
            }
        }
    }, [company]);

    return (
        <IonPage>
            <IonContent>
                <div id="bg-wrapper" className={`${styles.authWrapper} ${styles.splash}`}>
                    <header>
                        {company && company.used_logo == 'logo-icon' && (
                            <img src={sanity.urlFor(company.photo.asset._ref)} alt="Client logo" />
                        )}
                    </header>
                    <main>
                        <div className={styles.splashInfo}>
                            {company &&
                                company.name == 'Bajen Idag!' &&
                                company.used_logo == 'logo-icon' && (
                                    <img src={bajen} alt="bajen idag text" />
                                )}

                            {company &&
                                company.used_logo != 'logo-text' &&
                                company.name != 'Bajen Idag!' && (
                                    <span style={{ fontSize: '40px', color: 'black' }}>
                                        <b>{company.name.toUpperCase()}</b>
                                    </span>
                                )}

                            {company && company.used_logo == 'logo-text' && (
                                <img
                                    src={sanity.urlFor(company.company_logo_text.asset._ref)}
                                    alt="Client logo"
                                />
                            )}
                        </div>
                        <div className={styles.splashActions}>
                            {(enableMobileRegistration === true ||
                                enableEmailRegistration === true) && (
                                <>
                                    {(enableMobileLogin == true || enableEmailLogin == true) && (
                                        <Link to={'/login'}>
                                            <button>{t('Login')}</button>
                                        </Link>
                                    )}
                                    <p>
                                        {t('HaveNoAccount')}{' '}
                                        <a onClick={() => history.push('/register')}>
                                            {t('RegisterYourself')}
                                        </a>
                                    </p>
                                </>
                            )}
                        </div>
                        {enableEmailRegistration === false &&
                            enableMobileRegistration === false && (
                                <div className={styles.loginAction}>
                                    <Link to={'/login'}>
                                        <button>{t('Login')}</button>
                                    </Link>
                                    <div className={styles.registrationMessage}>
                                        {t('AppOnlyForRegisteredUsers')}{' '}
                                        {/* <Link to={'/login'} style={{ textDecoration: 'underline' }}>
                                här
                            </Link> */}
                                        <a href={emailLink} style={{ textDecoration: 'underline' }}>
                                            här
                                        </a>
                                        ;
                                    </div>
                                </div>
                            )}
                    </main>
                </div>
            </IonContent>
        </IonPage>
    );
};
export default Auth;
