import { IonContent, IonPage } from '@ionic/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Modal, Typography } from '@mui/material';
import { goBack } from 'connected-react-router';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import OnBack from '../../Auth/OnBack';
import styles from './customize.module.scss';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack())
});

const API_DOMAIN = process.env.REACT_APP_API_URL;

const CustomizePage = (props) => {
    const company = useSelector((state) => state.common.company);
    const { t } = useTranslation();
    const baseDomain = API_DOMAIN.split('api')[1];
    const user = useSelector((state) => state.user.currentUser);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    const handleCustomURL = () => {
        if (!user.premium_services?.premium) {
            setOpenModal(true);
        } else {
            history.push('/profile/customize/customurl');
        }
    };

    return (
        <IonPage>
            <OnBack expicitRoute={'/profile'} defaultHref={'/profile'} title={t('Customize')} />
            <IonContent>
                <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                    <div className={styles.header}></div>
                    <div style={{ padding: '6vh 2vw 0vh 2vw' }}>
                        <div
                            className={styles.link}
                            onClick={() => {
                                handleCustomURL();
                            }}>
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>
                                        {t('CustomURL')}
                                    </Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {company?.domain || `${company?.name}${baseDomain}`}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </div>
                        <Link to="/profile/customize/colors">
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>Colors</Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {t('Colors')}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </Link>
                        <Link to="/profile/customize/logotype">
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>
                                        {t('Logotype')}
                                    </Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {t('UploadOwnLogotype')}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </Link>
                        <Link to={'/profile/customize/appicon'}>
                            <div className={styles.itemContainer}>
                                <div>
                                    <Typography className={styles.itemTitle}>
                                        {t('AppIcon')}
                                    </Typography>
                                    <Typography className={styles.itemSubtitle}>
                                        {t('UploadOwnAppIcon')}
                                    </Typography>
                                </div>
                                <ChevronRightIcon />
                            </div>
                        </Link>
                    </div>
                    <Modal open={openModal}>
                        <div className={isMobile ? styles.modalMobile : styles.modal}>
                            <Typography
                                marginTop={isMobile ? '3%' : '-1%'}
                                fontSize={'18px'}
                                textAlign={'center'}>
                                {t('PremiumFeature')}
                            </Typography>
                            <div className={styles.flexContainer}>
                                <Button
                                    className={
                                        isMobile ? styles.confirmButtonMobile : styles.confirmButton
                                    }
                                    onClick={() => {
                                        setOpenModal(false);
                                        history.push('/profile/manage-subscriptions');
                                    }}>
                                    {t('BecomePremium')}
                                </Button>
                                <Button
                                    className={
                                        isMobile ? styles.cancelButtonMobile : styles.cancelButton
                                    }
                                    onClick={() => {
                                        setOpenModal(false);
                                    }}>
                                    {t('Close')}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePage);
