import { ORIENTATION_CHANGE } from '../../constants/actionTypes';
import { store } from '../../store';

class OrientationService {
    constructor() {
        this.isWindowScreenSupported = window.screen.orientation !== undefined;
        this.isStreaming = false; // Streaming state
        this.isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream; // Check if it's iOS
        this.init();
    }

    init = () => {
        if (this.isWindowScreenSupported) {
            window.screen.orientation.addEventListener('change', this.onOrientationChange);
        } else {
            this.portraitMediaQuery = window.matchMedia('(orientation: portrait)');
            if (this.portraitMediaQuery.addEventListener) {
                this.portraitMediaQuery.addEventListener('change', this.onOrientationChange);
            } else if (this.portraitMediaQuery.addListener) {
                this.portraitMediaQuery.addListener(this.onOrientationChange);
            }
        }

        // Additional fallback for iOS and mobile Chrome
        window.addEventListener('resize', this.onResize);

        // Invoke for the initial render
        this.onOrientationChange();
    };

    startStream = () => {
        this.isStreaming = true;

        // Attempt to lock orientation only if the device supports it and is not iOS
        if (!this.isiOS) {
            this.lockOrientation();
        }

        console.info('OrientationService: Stream started. Orientation changes will be blocked.');
    };

    stopStream = () => {
        this.isStreaming = false;
        console.info(
            'OrientationService: Stream stopped. Orientation changes will be allowed again.'
        );
    };

    lockOrientation = () => {
        if (this.isWindowScreenSupported) {
            const currentOrientation = window.screen.orientation.type;
            window.screen.orientation.lock(currentOrientation).catch((error) => {
                console.warn('OrientationService: Failed to lock orientation:', error);
                // Optional: Additional handling like informing the user
            });
        }
    };

    onOrientationChange = () => {
        if (this.isStreaming) {
            console.info('OrientationService: Orientation change blocked during streaming.');
            return; // Block orientation changes while streaming
        }

        const isLandscape = this.isWindowScreenSupported
            ? window.screen.orientation.type.toLowerCase().includes('landscape')
            : !this.portraitMediaQuery.matches;

        const currentOrientation = isLandscape ? 'LANDSCAPE' : 'PORTRAIT';
        const currentState = store.getState().orientation; // Assuming you have an orientation reducer

        if (currentOrientation !== currentState) {
            store.dispatch({
                type: ORIENTATION_CHANGE,
                payload: currentOrientation
            });
        }

        console.info(
            'OrientationService: Orientation change detected',
            JSON.stringify({
                isWindowScreenSupported: this.isWindowScreenSupported,
                orientation: window.screen.orientation?.type,
                isLandscape
            })
        );
    };

    debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    onResize = this.debounce(() => {
        if (this.isStreaming) {
            console.info('OrientationService: Resize change blocked during streaming.');
            return; // Block orientation changes via resize while streaming
        }

        const isLandscape = window.innerWidth > window.innerHeight;
        const currentOrientation = isLandscape ? 'LANDSCAPE' : 'PORTRAIT';
        const currentState = store.getState().orientation;

        if (currentOrientation !== currentState) {
            store.dispatch({
                type: ORIENTATION_CHANGE,
                payload: currentOrientation
            });
        }
    }, 100);



    removeListeners = () => {
        if (this.isWindowScreenSupported) {
            window.screen.orientation.removeEventListener('change', this.onOrientationChange);
        } else if (this.portraitMediaQuery) {
            if (this.portraitMediaQuery.removeEventListener) {
                this.portraitMediaQuery.removeEventListener('change', this.onOrientationChange);
            } else if (this.portraitMediaQuery.removeListener) {
                this.portraitMediaQuery.removeListener(this.onOrientationChange);
            }
        }

        window.removeEventListener('resize', this.onResize);
    };
}

export const orientationService = new OrientationService();
