import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = () => ({});

const PrivateRoute = ({ component: Component, user, ...rest }) => {
    if (!user.loaded) {
        return null;
    }
    const isLoggedIn = !!user.data;

    return (
        <Route
            render={(props) =>
                isLoggedIn ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect to={{ pathname: '/' }} />
                )
            }
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
