import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './components/App';
import { Provider } from 'react-redux';
import { history, store } from './store';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router';
import './styles/globals.scss';
import 'react-circular-progressbar/dist/styles.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enResource from './locales/en.json';
import svResource from './locales/sv.json';
import detector from 'i18next-browser-languagedetector';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(detector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: enResource
            },
            sv: {
                translation: svResource
            }
        },
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

Sentry.init({
    dsn: 'https://b7f14da4822a4c269bfbe4882b45e087@o4505170038358016.ingest.sentry.io/4505170066800640',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment:
        process.env.REACT_APP_SANITY_DATASET_NAME === 'preview' ? 'development' : 'production',
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    }
});

TimeAgo.addDefaultLocale(en);

function iOS() {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

function iosRedirect(url) {
    window.location = `bajenidag.app:/${url}`;
    const timeout = setTimeout(() => {
        if (
            confirm('You do not seem to have the App installed, do you want to go download it now?')
        ) {
            document.location = '${itunesUrl}';
        }
    }, 1500);
    clearTimeout(timeout);
    //window.addEventListener('pagehide', preventPopup);
}

const renderReactDom = () => {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    root.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
};

serviceWorkerRegistration.register();

if (window.cordova) {
    window.handleOpenURL = (url) => {
        setTimeout(() => {
            let eventSlug = url.substr(url.lastIndexOf('/') + 1);
            let redirectToURL = '/events/' + eventSlug;
            if (!iOS()) {
                redirectToURL = redirectToURL.substring(0, redirectToURL.length - 1);
            }
            history.push(redirectToURL);
        }, 2000);
    };
    document.addEventListener(
        'deviceready',
        () => {
            if (window.device.platform === 'Android') {
                const { permissions } = window.cordova.plugins;

                permissions.requestPermissions([
                    permissions.CAMERA,
                    permissions.RECORD_AUDIO,
                    permissions.MODIFY_AUDIO_SETTINGS
                ]);
            }

            // if (window.device.platform === 'iOS') {
            //     const { iosrtc } = window.cordova.plugins;
            //
            //     // Connect 'iosrtc' plugin, only for iOS devices
            //     iosrtc.registerGlobals();
            //     // Use speaker audio output
            //     iosrtc.selectAudioOutput('speaker');
            //     // Request audio and/or camera permission if not requested yet
            //     iosrtc.requestPermission(true, true, function (permissionApproved) {
            //         console.log(
            //             'requestPermission status: ',
            //             permissionApproved ? 'Approved' : 'Rejected'
            //         );
            //     });
            //     // Refresh video element
            //     window.addEventListener('orientationchange', () => iosrtc.refreshVideos());
            //     window.addEventListener('scroll', () => iosrtc.refreshVideos());
            // }

            renderReactDom();
        },
        false
    );
} else {
    renderReactDom();

    window.onload = () => {
        if (iOS() && process.env.REACT_APP_HAS_MOBILE_APP === 'true') {
            const url = location.href;
            let eventSlug = url.substr(url.lastIndexOf('/') + 1);
            let redirectToURL = '/events/' + eventSlug;
            iosRedirect(redirectToURL);
        }
    };
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
