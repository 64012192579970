import {Button, Typography} from '@mui/material';
import React from 'react';

const CoreButton = ({ title, onClick, color, bgColor }) => {
    return <Button
        onClick={() => onClick()}
        sx={{
            backgroundColor: bgColor || 'black',
            margin: '20px',
            padding: 2,
            width: '-webkit-fill-available',
            borderRadius: '8px'
        }}>
        <Typography
            sx={{ fontFamily: 'Urbanist-Bold', textTransform: 'capitalize', color: color || 'white' }}>
            {title}
        </Typography>
    </Button>;
};

export default CoreButton;
