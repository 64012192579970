import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    REFETCH_USER_DATA,
    SET_USER_DATA,
    SHOW_PWA_PROMPT,
    SPINNER_VISIBLE
} from '../../constants/actionTypes';
import fetchData from '../../fetch';
import { getBase64 } from '../../helpers/FileReader';
import styles from '../../styles/auth.module.scss';
import ErrorMessage from './ErrorMessage';
import OnBack from './OnBack';
import SubmitButton from './SubmitButton';

import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import cognitoService from '../../core/service/cognito.service';
import useImageConversion from '../hooks/useImageConversion';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onShowPwaPrompt: (payload) => dispatch({ type: SHOW_PWA_PROMPT, payload }),
    onRefetchUserData: () => dispatch({ type: REFETCH_USER_DATA })
});

const EnterPhotoStep = (props) => {
    const { t } = useTranslation();
    const [imagePreview, setImagePreview] = useState(props.user.imagePreview || '');
    const [photo, setPhoto] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const hiddenFileInput = React.useRef(null);
    const user = props.user;
    const history = useHistory();

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];

        // eslint-disable-next-line react-hooks/rules-of-hooks
        currentFile = await useImageConversion(currentFile);

        reader.onloadend = () => {
            setImagePreview(reader.result);
            props.onSetUserData({ imagePreview: reader.result });
        };
        setPhoto(currentFile);
        reader.readAsDataURL(currentFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (photo) {
                props.onSpinner(true);
                const base64Image = await getBase64(photo);
                const image = base64Image.split(';base64,')[1];

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ image })
                };
                const { thumbnailUrl } = await fetchData.Image.Upload(requestOptions);

                await cognitoService.addPicture(thumbnailUrl);
                props.onSpinner(false);
            }

            setErrorMessage(null);
            props.onShowPwaPrompt(true);
            const currentUser = await cognitoService.getUserData();
            props.onSetUserData(currentUser);
            props.onRefetchUserData();
            history.push('/events');
        } catch (e) {
            props.onSpinner(false);
            setErrorMessage(t('UploadError'));
        }
    };

    return (
        <IonPage>
            <OnBack
                defaultHref={"/user-info'"}
                title={t('ChooseProfilePicture', {
                    name: `${user.name}`,
                    surname: `${user.surname}`
                })}
            />
            <IonContent>
                <div className={styles.authWrapper}>
                    <ErrorMessage errorMessage={errorMessage} />
                    <form onSubmit={handleSubmit} style={{ flex: 0 }}>
                        <div className={styles.authPhoto}>
                            {!imagePreview && (
                                <div>
                                    <svg
                                        onClick={handleClick}
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            fill="var(--primary-color)"
                                        />
                                        <g clipPath="url(#clip0_989_3576)">
                                            <path
                                                d="M13.75 20H26.25"
                                                stroke="white"
                                                strokeWidth="2.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M20 13.75V26.25"
                                                stroke="white"
                                                strokeWidth="2.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_989_3576"
                                                x1="36"
                                                y1="-1.44541e-06"
                                                x2="3.5"
                                                y2="40"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#57B177" />
                                                <stop offset="1" stopColor="#367B4E" />
                                            </linearGradient>
                                            <clipPath id="clip0_989_3576">
                                                <rect width="40" height="40" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            )}
                            {imagePreview && (
                                <img
                                    style={{ width: 163, height: 160, borderRadius: '85px' }}
                                    src={imagePreview}
                                    alt={t('Avatar')}
                                    onClick={handleClick}
                                />
                            )}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={hiddenFileInput}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div style={{ display: 'flex', marginTop: '5%' }}>
                            <SubmitButton buttonText={t('Next')} />
                        </div>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterPhotoStep);
