import { Route } from 'react-router';
import PrivateRoute from './Private/PrivateRoute';
import AdminRoute from './Private/AdminRoute';
import AuthRoute from './Auth/AuthRoute';
import Auth from './Auth/Auth';
import Login from './Auth/Login';
import Register from './Auth/Register';
import EnterCodeStep from './Auth/EnterCodeStep';
import EnterNameStep from './Auth/EnterNameStep';
import EnterPhotoStep from './Auth/EnterPhotoStep';
import PermissionsStep from './Auth/PermissionsStep';
import Privacy from './General/privacy';
import TermsAndConditions from './General/termsAndConditions';
import EventList from './Event/EventList';
import Event from './Event/Event';
import ProfileSettings from './Profile/Settings';
import Invite from './Profile/Invite';
import Notifications from './Profile/Notifications';
import Account from './Profile/Account';
import Preferences from './Profile/Preferences';
import Streaming from './Profile/Streaming';
import ReportList from './Event/ReportList';
import EventPlan from './Profile/EventPlan';
import BlogPost from './BlogPost/BlogPost';
import BlogPosts from './BlogPost/BlogPostItem';
import CookiePolicy from './General/cookiePolicy';
import Secrecy from './Profile/Secrecy';
import BlockedUsers from './Profile/BlockedUsers';
import Followers from './Profile/Followers';
import ModifyEvent from './Event/Modify/ModifyEvent';
import DeleteAccount from './Profile/Account/DeleteAccount';
import LiveStreaming from './Profile/Streaming/LiveStreaming';
import CreateVideoPage from './CreateVideo/CreateVideoPage';
import CustomUrlPage from './Profile/Customize/CustomUrlPage';
import LogotypePage from './Profile/Customize/LogotypePage';
import ColorsPage from './Profile/Customize/ColorsPage';
import CustomizePage from './Profile/Customize/CustomizePage';
import AppIconPage from './Profile/Customize/AppIconPage';
import ProfilePage from './Profile/ProfileDesign/ProfilePage';
import ManageSubscriptions from './Profile/ProfileDesign/ManageSubscriptions';
import CancelPayment from './Profile/ProfileDesign/CancelPayment';
import SuccessPayment from './Profile/ProfileDesign/SuccessPayment';
import InvoicesPage from './Invoices/InvoicesPage';
import GoLive from './Profile/GoLive/GoLive';
import StreamDetails from './CreateVideo/StreamDetails';
import Search from './Search/Search';
import Products from './Profile/Products';
import MyVideos from './Profile/MyVideos';

import { IonApp, IonRouterOutlet, createAnimation } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { isMobile } from 'react-device-detect';
import UserSubscriptions from './Profile/ProfileDesign/UserSubscriptions';
import Transactions from './Profile/ProfileDesign/Transactions';
import Welcome from './Auth/Welcome';
import LocationProvider from '../contexts/provider';

const Routes = () => {
    const animationBuilder = (baseEl, opts) => {
        if (isMobile) {
            if (opts.direction === 'forward') {
                const enteringAnimation = createAnimation()
                    .addElement(opts.enteringEl)
                    .fromTo('opacity', '0', '1')
                    .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
                    .duration(300)
                    .easing('linear');

                const leavingAnimation = createAnimation()
                    .addElement(opts.leavingEl)
                    .fromTo('opacity', '1', '0')
                    .fromTo('transform', 'translateX(0%)', 'translateX(-100%)')
                    .duration(300)
                    .easing('linear');

                return createAnimation().addAnimation([enteringAnimation, leavingAnimation]);
            } else {
                const enteringAnimation = createAnimation()
                    .addElement(opts.enteringEl)
                    .fromTo('opacity', '0', '1')
                    .fromTo('transform', 'translateX(-100%)', 'translateX(0%)')
                    .duration(300)
                    .easing('linear');

                const leavingAnimation = createAnimation()
                    .addElement(opts.leavingEl)
                    .fromTo('opacity', '1', '0')
                    .fromTo('transform', 'translateX(0%)', 'translateX(100%)')
                    .duration(400)
                    .easing('linear');

                return createAnimation().addAnimation([enteringAnimation, leavingAnimation]);
            }
        }
    };
    return (
        // <Switch>
        <IonApp>
            <IonReactRouter>
                {/* <LocationProvider> */}
                <IonRouterOutlet
                    animated={isMobile ? true : undefined}
                    animation={animationBuilder}>
                    <AuthRoute exact path="/" component={Auth} />
                    <Route path="/privacyPolicy" component={Privacy} />
                    <Route path="/termsAndConditions" component={TermsAndConditions} />
                    <Route path="/cookiePolicy" component={CookiePolicy} />
                    <PrivateRoute path="/events/:slug/modify" component={ModifyEvent} />
                    <Route exact path="/events/:slug" component={Event} />
                    <Route exact path="/events" component={EventList} />
                    <Route exact path="/search" component={Search} />

                    <Route path="/welcome/:token" component={Welcome} />
                    <AuthRoute path="/login" component={Login} />
                    <AuthRoute path="/register" component={Register} />
                    <AuthRoute path="/confirmation" step={true} component={EnterCodeStep} />
                    <AuthRoute path="/user-info" step={true} component={EnterNameStep} />
                    <AuthRoute path="/upload-image" step={true} component={EnterPhotoStep} />
                    <AuthRoute path="/permissions" step={true} component={PermissionsStep} />

                    <AdminRoute path="/profile/streaming/live" component={LiveStreaming} />
                    <AdminRoute path="/profile/streaming" component={Streaming} />

                    <PrivateRoute
                        path="/profile/settings/secrecy/blocked-users"
                        component={BlockedUsers}
                    />
                    <PrivateRoute path="/create-video/stream-details" component={StreamDetails} />
                    <PrivateRoute exact path="/create-video" component={CreateVideoPage} />
                    <PrivateRoute path="/go-live" component={GoLive} />
                    <PrivateRoute path="/profile/invoices" component={InvoicesPage} />
                    <PrivateRoute path="/profile/settings/preferences" component={Preferences} />
                    <PrivateRoute
                        path="/profile/settings/account/deletion"
                        component={DeleteAccount}
                    />
                    <PrivateRoute path="/profile/settings/products" component={Products} />
                    <PrivateRoute exact path="/profile/settings/account" component={Account} />
                    <PrivateRoute exact path="/profile/settings/secrecy" component={Secrecy} />
                    <PrivateRoute path="/profile/eventplan" component={EventPlan} />
                    <PrivateRoute exact path="/profile/settings" component={ProfileSettings} />
                    <PrivateRoute path="/profile/customize/customurl" component={CustomUrlPage} />
                    <PrivateRoute path="/profile/customize/colors" component={ColorsPage} />
                    <PrivateRoute path="/profile/customize/logotype" component={LogotypePage} />
                    <PrivateRoute path="/profile/customize/appicon" component={AppIconPage} />
                    <PrivateRoute exact path="/profile/customize" component={CustomizePage} />
                    <PrivateRoute path="/profile/myvideos/followers" component={Followers} />
                    <PrivateRoute exact path="/profile/myvideos" component={MyVideos} />
                    <PrivateRoute
                        exact
                        path="/profile/manage-subscriptions"
                        component={ManageSubscriptions}
                    />
                    <PrivateRoute exact path="/profile/transactions" component={Transactions} />
                    <PrivateRoute
                        exact
                        path="/profile/user-subscriptions"
                        component={UserSubscriptions}
                    />
                    <PrivateRoute path="/profile/success-payment" component={SuccessPayment} />
                    <PrivateRoute path="/profile/cancel-payment" component={CancelPayment} />
                    <PrivateRoute exact path="/profile" component={ProfilePage} />
                    <PrivateRoute path="/invite" component={Invite} />
                    <PrivateRoute path="/notifications" component={Notifications} />
                    <PrivateRoute path="/report/:slug" component={ReportList} />
                    <PrivateRoute path="/blog/:slug" component={BlogPost} />
                    <PrivateRoute path="/blog/" component={BlogPosts} />
                    <AuthRoute component={Auth} />
                </IonRouterOutlet>
                {/* </LocationProvider> */}
            </IonReactRouter>
        </IonApp>
    );
};

export default Routes;
