import React, {useEffect} from 'react';
import styles from './widget.module.scss';
import Confetti from 'react-confetti';
import {useTranslation} from 'react-i18next';

export default function QuizOption(props) {
    const { t } = useTranslation();
    const option = props.value;
    let selected = props.selected;
    const textField = props.textField ? props.textField : 'text';
    let selectedPhoto = '/images/selected.png';
    let width;
    let height;

    if (window.cordova) {
        selectedPhoto = 'images/selected.png';
    }

    useEffect(() => {
        width = document.getElementById('quiz').offsetWidth;
        height = document.getElementById('quiz').offsetHeight;
    }, []);

    return (
        <React.Fragment>
            {option._key === selected && option.important && option.resultCount && (
                <Confetti
                    width={width}
                    height={height}
                    colors={['#55AD74', '#255A47', '#4FC53C', '#367B4E']}
                />
            )}
            <div
                className={`
                ${
                    selected === option._key
                        ? `${styles.quizOptionSelected} ${styles.quizOption}`
                        : styles.quizOption
                }
                ${
                    option.resultPercentage || option.resultPercentage === 0
                        ? styles.widgetResults
                        : ''
                }`}
                onClick={() => props.pickOption(option._key)}>
                <span>{option[textField]}</span>
                {(option.resultPercentage || option.resultPercentage === 0) && (
                    <React.Fragment>
                        <div
                            className={`${
                                option._key === selected && option.important
                                    ? styles.widgetResultsBg
                                    : styles.widgetResultsFalse
                            }
                            ${option._key !== selected ? styles.widgetsEmpty : ''}`}
                            style={{ width: option.resultPercentage + '%' }}
                        />
                        <div className={styles.widgetResult}>
                            {option._key !== selected && option.important && (
                                <span className={styles.widgetRight}>{t('CorrectAnswer')}</span>
                            )}
                            {option.resultPercentage}%
                        </div>
                    </React.Fragment>
                )}
                {selected === option._key && !option.resultCount && (
                    <div className={styles.selectedIcon}>
                        <img src={selectedPhoto} alt={t('SelectedOption')} />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
