import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import CoreButton from '../core-components/CoreButton';
import {useHistory} from 'react-router-dom';

function RequestAuthForChat() {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Box position="relative">
            <CoreButton title={t('SignupToComment')} onClick={() => history.push('/register')} />
        </Box>
    );
}

export default RequestAuthForChat;
