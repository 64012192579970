export default class ConversionStatus {
    constructor(props) {
        this.jobId = props.JobId;
        this.conversionDone = props.ConversionDone;
        this.status = props.Status;
        this.statusUpdateInterval = props.StatusUpdateInterval ? +props.StatusUpdateInterval : null;
        this.jobPercentComplete = props.jobPercentComplete ? props.jobPercentComplete : 0;
    }

    static new(props) {
        return new ConversionStatus(props);
    }
}
