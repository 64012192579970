import getSymbolFromCurrency from 'currency-symbol-map';

const calculateTimeLeft = (fromTime) => {
    let timeLeftDate = {};

    const scheduled = new Date(fromTime);
    const now = new Date();

    let total = 0;
    let isTimerNegative = false;

    if (scheduled.getTime() < now.getTime()) {
        total = Date.parse(now) - Date.parse(scheduled);
        isTimerNegative = true;
    } else if (scheduled.getTime() > now.getTime()) {
        total = Date.parse(scheduled) - Date.parse(now);
        isTimerNegative = false;
    } else {
        isTimerNegative = false;
    }

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    timeLeftDate = {
        days: isTimerNegative ? '-' + zeroPadding(days.toString()) : zeroPadding(days.toString()),
        hours: zeroPadding(hours.toString()),
        minutes: zeroPadding(minutes.toString()),
        seconds: zeroPadding(seconds.toString())
    };
    return timeLeftDate;
};

const zeroPadding = (number) => {
    return number.padStart(2, '0');
};

const isUrlValid = (url) => {
    if (!url) {
        return Promise.resolve(false);
    }
    return fetch(url).then(
        (response) => {
            return Promise.resolve(response.status === 200);
        },
        (error) => {
            return Promise.resolve(false);
        }
    );
};

const parseMatchTime = (start_date) => {
    var scheduledMatch =
        new Date(start_date).getFullYear() +
        '-' +
        zeroPadding((new Date(start_date).getMonth() + 1).toString()) +
        '-' +
        zeroPadding(new Date(start_date).getDate().toString()) +
        ', ' +
        zeroPadding(new Date(start_date).getHours().toString()) +
        ':' +
        zeroPadding(new Date(start_date).getMinutes().toString());
    return scheduledMatch;
};

const getTruncatedFileName = (fileName) => {
    if (!fileName) {
        return '';
    }
    if (fileName.length <= 30) {
        return fileName;
    }
    return `${fileName.substring(0, 30)}...`;
};

const canSeeEvent = (user, event) => {
    if (user.groups?.includes('Admin')) return true;
    else if (event.isPremium != true) return true;
    else if (user.premium_services?.events.includes(event.id)) return true;
    else if (user.premium_services?.premium) return true;
    else return false;
};

const getSymbolForCurrency = (currency) => {
    return getSymbolFromCurrency(currency);
};

const calculateBrightness = (hexColor) => {
    if (hexColor) {
        const color = hexColor.replace('#', '');

        const r = parseInt(color.substr(0, 2), 16);
        const g = parseInt(color.substr(2, 2), 16);
        const b = parseInt(color.substr(4, 2), 16);

        const brightness = (r * 299 + g * 587 + b * 114) / 1000;

        return brightness;
    } else {
        return 130;
    }
};

const isColorSimilar = (color1, color2, threshold) => {
    const delta = Math.abs(calculateBrightness(color1) - calculateBrightness(color2));
    return delta < threshold;
};

const updateMetaTagsUrl = (url) => {
    document.querySelector('meta[property="og:url"]').setAttribute('content', url);
};

const updateMetaTags = (tags) => {
    Object.entries(tags).forEach(([tag, value]) => {
        // Update Facebook meta tags
        document.querySelector(`meta[property="og:${tag}"]`).setAttribute('content', value);

        // Update Twitter meta tags
        document.querySelector(`meta[name="twitter:${tag}"]`).setAttribute('content', value);
    });
};

const transformToSanityUrl = (options) => {
    if (
        !options ||
        !options.baseUrl ||
        !options.dataset ||
        !options.projectId ||
        !options.source ||
        !options.source._ref
    ) {
        console.error('Invalid options object:', options);
        return null;
    }

    const { baseUrl, dataset, projectId, source } = options;
    const sourceRef = source._ref;
    const parsedSource = sourceRef.replace('image-', '').replace(/-(\w+)$/, '.$1');

    return `${baseUrl}/images/${projectId}/${dataset}/${parsedSource}`;
};

const changeDynamicFavicon = (logoUrl) => {
    if (logoUrl) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = logoUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
};

const checkPermissions = async () => {
    try {
        const cameraPermission = await navigator.permissions.query({ name: 'camera' });
        const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

        return cameraPermission.state === 'granted' && microphonePermission.state === 'granted';
    } catch (error) {
        console.error('Error checking permissions:', error);
        return false;
    }
};

export {
    calculateTimeLeft,
    isUrlValid,
    parseMatchTime,
    zeroPadding,
    getTruncatedFileName,
    canSeeEvent,
    getSymbolForCurrency,
    calculateBrightness,
    isColorSimilar,
    updateMetaTagsUrl,
    transformToSanityUrl,
    changeDynamicFavicon,
    checkPermissions,
    updateMetaTags
};
