import React, { useEffect, useRef } from 'react';
import styles from './eventReaction.module.scss';
// import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import sanity from '../../sanity';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        reactionMessage: state.event.reactionsReceived,
        company: state.common.company
    };
};

const EventReaction = (props) => {
    const { t } = useTranslation();
    // TODO: Connect colors with assets from sanity (custom reaction theme)

    const reactionButton = useRef(null);
    const reactionsReceived = props.reactionMessage;

    useEffect(() => {
        animateElement(reactionButton.current);
    }, [reactionsReceived]);

    let animateElement = function (e) {
        const reactionsNumber = 10;

        if (window && e.offsetTop !== 0 && e.offsetLeft !== 0) {
            for (let i = 0; i < reactionsNumber; i++) {
                createParticle(e.offsetLeft + e.clientWidth / 2, window.innerHeight - 30);
            }
        }
    };

    const onClickHandler = () => {
        props.sendReaction();
    };

    function createParticle(x, y) {
        const particle = document.createElement('particle');
        document.body.appendChild(particle);

        const sizeHeart = Math.floor(Math.random() * 35 + 20);

        const sizeLogo = Math.floor(Math.random() * 40 + 25);

        let randomizeReaction = Math.random() * 100;

        if (randomizeReaction < 12) {
            // Generate logo reaction
            particle.style.backgroundColor = props.company ? props.company.primaryColor : 'red';

            if (window.cordova) {
                particle.style.backgroundImage = `url(${sanity.urlFor(
                    props.company?.company_logo_text?.asset?._ref
                )})`;
            } else {
                particle.style.backgroundImage = `url(${sanity.urlFor(
                    props.company?.company_logo_text?.asset?._ref
                )})`;
            }
            particle.style.width = `${sizeLogo}px`;
            particle.style.height = `${sizeLogo}px`;
        } else {
            var svg = `
            <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.4777 4.98052C27.7548 3.18115 26.3042 1.66785 24.2971 0.730751C22.5232 -0.0978484 20.6118 -0.19743 18.8744 0.308824C17.1376 0.815078 15.5757 2.19483 14.4998 3.78986C13.4242 2.19483 11.8623 0.815078 10.1255 0.308824C8.38869 -0.19743 6.47673 -0.0978484 4.70281 0.730751C2.69602 1.66757 1.24545 3.18115 0.522194 4.98052C-0.200772 6.7799 -0.203962 8.8691 0.707213 10.9468C2.65658 15.3911 14.4381 25.8218 14.4998 26C14.5616 25.8218 26.3433 15.3911 28.2924 10.9468C29.2042 8.86881 29.2007 6.7799 28.4777 4.98052Z" fill="${
                    props.company ? props.company.primaryColor : 'red'
                }"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="-9.22726e-08" y1="-2" x2="23" y2="26" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#55AD74"/>
                        <stop offset="1" stop-color="#4FC53C"/>
                    </linearGradient>
                </defs>
            </svg>`;
            var encoded = window.btoa(svg);
            if (window.cordova) {
                particle.style.backgroundImage = 'url(data:image/svg+xml;base64,' + encoded + ')';
            } else {
                particle.style.backgroundImage = 'url(data:image/svg+xml;base64,' + encoded + ')';
            }
            particle.style.width = `${sizeHeart}px`;
            particle.style.height = `${sizeHeart}px`;
        }

        // animation direction based on mouse
        const destinationX = x + (Math.random() - 0.5) * 2 * 75;
        const destinationY = -(y - 900);

        const animation = particle.animate(
            [
                {
                    transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
                    opacity: 1
                },
                {
                    transform: `translate(${destinationX}px, ${destinationY}px)`,
                    opacity: 0
                }
            ],
            {
                duration: Math.random() * 1500 + 500,
                delay: Math.random() * 200
            }
        );

        animation.onfinish = () => {
            particle.remove();
        };
    }

    return (
        <button onClick={onClickHandler} className={styles.eventReaction} ref={reactionButton}>
            {/*TODO: Create component containing only img tag with logo, theme*/}
            {props.company && props.company?.photo && (
                <img
                    src={sanity.urlFor(props.company?.company_logo_text.asset?._ref)}
                    alt={t('ClientLogo')}
                />
            )}
        </button>
    );
};

export default connect(mapStateToProps, null)(EventReaction);
