import Resizer from 'react-image-file-resizer';

/**
 * Sleeps for X ms
 * @param {number} ms
 * @returns promise
 */
export const sleepMs = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Sleeps for X seconds
 * @param {number} s
 * @returns promise
 */
export const sleepSeconds = (s) => new Promise((resolve) => setTimeout(resolve, s * 1000));

/**
 * Creates string with human readable date format.
 * @param {Date | String} t
 * @returns formatted string yyyy-mm-dd hh:mm | '' if error.
 */
export const friendlyTime = (t) => {
    if (!t) return '';

    let _t = t;
    if (!(_t instanceof Date)) {
        try {
            _t = new Date(_t);
        } catch (e) {
            return '';
        }
    }

    _t = _t.toISOString().split('T');

    return `${_t[0]} ${_t[1].split(':').slice(0, -1).join(':')}`;
};

/**
 * Gets video preset for given user.
 * @param {User} user
 * @returns Number 2 == auto, 1 = 720, 0 = 360
 */
export const getVideoQualityPreset = (user) => {
    try {
        // If in the future you want auto quality that would be preset 2 then change default to return 2 and implement logic in Preferences. Clients now wants 2 options high, low
        if (isNaN(+user['custom:video_quality'])) return 2; // if this is not set defaults to 720

        //? Default case === auto quality based on adaptive bandwidth
        switch (+user['custom:video_quality']) {
            case 360:
                return 0;
            case 720:
                return 1;
            default:
                return 2;
        }
    } catch (e) {
        console.error(e);
        return 2;
    }
};

/**
 * It creates a video element, sets the preload attribute to metadata, and then resolves the promise
 * with the video element when the metadata has been loaded
 * @param file - The file object that you want to read the metadata from.
 */
export const readVideoMetadata = (file) =>
    new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = function () {
                resolve(this);
            };

            video.onerror = function () {
                reject('Invalid video. Please select a video file.');
            };

            video.src = window.URL.createObjectURL(file);
        } catch (e) {
            reject(e);
        }
    });

/**
 * It takes a file, resizes it, and returns a new file
 * @param e - the event object
 * @returns The return value of the async function is a promise.
 */
export const handleImageChange = async (e) => {
    e.preventDefault();
    try {
        return await resizeFile(e.target.files[0]);
    } catch (err) {
        throw new Error('ERROR with thumbnail::', err);
    }
};

/**
 * It takes a file, resizes it, and returns a new file.
 * @param file - The file to resize.
 */
export const resizeFile = (file) => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            'blob'
        );
    });
};

/**
 * It takes a blob and returns a promise that resolves to a data url
 * @param blob - The blob to convert to a URL
 */
export const blobToUrl = (blob) =>
    new Promise((resolve, reject) => {
        try {
            console.log("BLOB: ", blob)
            let reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = (e) => reject('error reading image', { e });
            reader.onabort = (e) => reject('error reading image', { e });

            reader.readAsDataURL(blob);
        } catch (e) {
            reject(e);
        }
    });

/**
 * It takes a string and returns a boolean if the passed string is JSON
 * @param str - The string to check
 */
    export const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }