import { w3cwebsocket as W3CWebSocket } from 'websocket';
import chatService from './core/service/chat.service';
import cognitoService from './core/service/cognito.service';
import { v4 as uuidv4 } from 'uuid';
import {
    CONVERSION_STATUS_RECEIVED,
    CONVERT_VIDEO_RECEIVED,
    EVENT_STREAMING_UPDATE,
    EVENT_UPDATE,
    MESSAGE_RECEIVED,
    REACTION_RECEIVED,
    REPORT_MESSAGE,
    REPORT_MESSAGE_CLEAR,
    SET_ACTIVE_WIDGET,
    SET_BLOCKED_USERS,
    SET_CHAT_MESSAGES,
    SET_CHAT_ROOM,
    SET_EVENT_TAB_ACTIVE, SET_FOLLOWERS,
    SET_PAST_WIDGETS, SET_USER_GROUP,
    SET_WIDGET_RESULT,
    WATCHING_NOW,
    SET_WATCH_COUNT_RESULT
} from './constants/actionTypes';
import { isJson } from './helpers/helpers';

const webSocketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}?Auth=`;
const companyId = `${process.env.REACT_APP_COMPANY_ID}`;

class Socket {
    constructor(dispatch) {
        this._dispatch = dispatch;
        this._url = webSocketUrl;
        this._client = null;
        this._roomId;
        this._eventId;
        this._blockedUsers = [];
        this._chatService = chatService;
    }

    get Client() {
        return this._client;
    }

    // eslint-disable-next-line
    connect = async () => {
        this._client = await this._getNewClient();
        if (this._client) {
            this._client.onopen = this._onOpen;
            this._client.onmessage = this._onMessage;
            this._client.onclose = this.connect;
            this._client.onerror = this._onError;
        }
    };

    disconnect() {
        this._client?.close();
        this._client = null;
    }

    async _getNewClient() {
        try {
            let token = await cognitoService.getUserToken();
            if (!token) {
                //? create public user.
                token = `temp-${uuidv4()}`;
            }

            return new W3CWebSocket(`${this._url}${token}&Id=${companyId}`);
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    // eslint-disable-next-line
    _onOpen = () => {
        if (this._roomId) {
            this._dispatch({ type: SET_CHAT_ROOM, payload: this._roomId });
        }
        return false;
    };

    // eslint-disable-next-line
    _onMessage = async (message) => {
        if (isJson(message.data)) {
            const messageData = JSON.parse(message.data);
            if (messageData.type === 'messages') {
                let messages = messageData.body.messages.filter(
                    (message) => this._blockedUsers.indexOf(message.user.userId) < 0
                );
                this._populateMessages(messages);
            } else if (messageData.type === 'room') {
                const miliSeconds = new Date().valueOf();
                this._roomId = messageData.body.room.id;
                this._eventId = messageData.body.room.event_id;
                this._dispatch({ type: SET_CHAT_ROOM, payload: this._roomId });

                this._client?.send(
                    JSON.stringify({
                        action: 'getmessages',
                        room_id: this._roomId,
                        timestamp: miliSeconds
                    })
                );
                this._client?.send(
                    JSON.stringify({
                        action: 'getwidgets',
                        event_id: this._eventId
                    })
                );
            } else if (messageData.type === 'widget' && messageData.status === 'create') {
                if (messageData.body.eventId === this._eventId) {
                    this._dispatch({ type: SET_ACTIVE_WIDGET, payload: messageData.body });
                }
            } else if (messageData.type === 'widget' && messageData.status === 'update') {
                if (messageData.body.eventId === this._eventId) {
                    this._dispatch({ type: SET_WIDGET_RESULT, payload: messageData.body });
                }
            } else if (messageData.type === 'widgets') {
                const widgets = messageData.body.widgets;
                if (widgets) {
                    this._dispatch({ type: SET_PAST_WIDGETS, payload: widgets });
                }
            } else if (messageData.type === 'event') {
                let eventUpdate = messageData.body;
                this._dispatch({ type: EVENT_UPDATE, payload: eventUpdate });
                if (messageData.status === 'update') {
                    this._dispatch({ type: EVENT_STREAMING_UPDATE, payload: eventUpdate });
                }
            } else if (messageData.type === 'message') {
                const messageRecieved = this._chatService.MapMessage(messageData.body.message);
                if (this._blockedUsers.indexOf(messageData.body.message.user.userId) < 0) {
                    if (messageRecieved.messageContent === 'REACTION MESSAGE') {
                        this._dispatch({ type: REACTION_RECEIVED });
                    } else {
                        this._dispatch({ type: MESSAGE_RECEIVED, payload: messageRecieved });
                    }
                }
            } else if (messageData.type === 'watchingnow') {
                this._dispatch({ type: WATCHING_NOW, payload: messageData.body.number });
            } else if (messageData.type === 'list_blocked_users') {
                this._dispatch({ type: SET_BLOCKED_USERS, payload: messageData.items });
            } else if (messageData.type === 'company_followers') {
                this._dispatch({ type: SET_FOLLOWERS, payload: messageData.items });
            } else if (messageData.type === 'change_user_group') {
                this._dispatch({ type: SET_USER_GROUP, payload: messageData.body });
            } else if (messageData.type === 'convertvideo') {
                this._dispatch({ type: CONVERT_VIDEO_RECEIVED, payload: messageData.body });
            } else if (messageData.type === 'conversionstatus') {
                this._dispatch({ type: CONVERSION_STATUS_RECEIVED, payload: messageData.body });
            } else if (messageData.type === 'setEventTabActive') {
                this._dispatch({ type: SET_EVENT_TAB_ACTIVE, payload: messageData.body });
            } else if (messageData.type === 'reportMessage') {
                this._dispatch({ type: REPORT_MESSAGE, payload: messageData.body });
            } else if (messageData.type === 'reportMessageClear') {
                this._dispatch({ type: REPORT_MESSAGE_CLEAR, payload: null });
            } else if (messageData.type === 'getwatchcount') {
                let {type , ...rest} = messageData;
                this._dispatch({ type: SET_WATCH_COUNT_RESULT, payload: {...rest} });
            }

            return false;
        } else {
            return false;
        }
    };

    _onError = (error) => {
        'WEBSOCKET ERROR', error;
    };

    async _populateMessages(messageData) {
        let messages = this._chatService.MapMessages(messageData);
        messages = messages.filter((message) => message.messageType === 'normal' && message.user);
        messages = messages.reverse();
        this._dispatch({ type: SET_CHAT_MESSAGES, payload: messages });
    }
}

export default function NewSocket(dispatch) {
    return new Socket(dispatch);
}
