import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SPINNER_VISIBLE } from '../../constants/actionTypes';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const AuthRoute = ({ component: Component, step, user, onSpinner, ...rest }) => {
    if (!user.loaded) {
        return null;
    }

    const redirect = user?.data?.family_name && user?.data?.given_name && user?.data?.email;
    if (redirect) {
        onSpinner(false);
    }

    return (
        <Route
            render={(props) =>
                redirect ? (
                    <Redirect to={{ pathname: '/events' }} />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
