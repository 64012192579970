import React from 'react';
import {connect} from 'react-redux';
import styles from './loginNotification.module.scss';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company
    };
};

const LoginNotification = (props) => {
    const { t } = useTranslation();
    // console.log(props.company.name);
    return (
        <div onClick={() => props.setIsClicked(false)}>
            {props.isClicked && (
                <div className={styles.wrapperOverlay}>
                    <div className={styles.eventOptions}>
                        <span className={styles.helloIcon}>{props.icon}</span>
                        <h3>{t('HiMessage', { given_name: `${props.user.data.given_name}` })}</h3>
                        <p>{t('WelcomeMessage', { company_name: `${props.company.name}` })}</p>
                        <p style={{ color: 'lightgray' }}>{props.title}</p>
                        <Link to={`/profile/settings/account`} className={styles.changeSettings}>
                            {t('ProfileSettings')}
                        </Link>
                    </div>
                </div>
            )}
            {props.isUserBlocked && (
                <div className={styles.wrapperOverlay}>
                    <div className={styles.eventOptions}>
                        <span className={styles.helloIcon}>{props.icon}</span>
                        <h3>{t('UserBlocked')}</h3>
                        <p style={{ color: 'lightgray', fontSize: '15px' }}>{props.title}</p>
                        <div className={styles.notificationControls}>
                            <Link to={`/profile/settings`} className={styles.changeSettingsReport}>
                                {t('Settings')}
                            </Link>
                            <button
                                className={styles.changeSettingsReport}
                                onClick={() => props.setIsUserBlocked(false)}>
                                {t('ProfileSettings')}
                                {t('Close')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps)(LoginNotification);
