export default class Message {
    constructor(user, userId , dateSent, content, roomId, messageType, id , avatar) {
        this.userId = userId;
        this.username = user;
        this.dateSent = dateSent;
        this.message = content;
        this.roomId = roomId;
        this.messageType = messageType;
        this.messageId = id;
        this.avatar = avatar;
    }
}
