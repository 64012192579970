import React, {useEffect, useState} from 'react';
import styles from './widget.module.scss';
import PollWidget from './PollWidget';
import PollVSWidget from './PollVSWidget';
import PollMOTMWidget from './PollMOTMWidget';
import QuizWidget from './QuizWidget';
import QuizMultiWidget from './QuizMultiWidget';
import SponsorWidget from './SponsorWidget';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';

import useInterval from '../hooks/useInterval';

export default function Widget(props) {
    const [widgetComponent, setWidgetComponent] = useState(null);
    const [timerDuration, setTimerDuration] = useState(0);
    let chatElement;
    let widgetElement;
    let videoElement;
    useEffect(() => {
        switch (props.widget.type) {
            case 'poll-motm':
                setWidgetComponent(<PollMOTMWidget type={'poll-motm'} data={props.widget} />);
                break;
            case 'poll-text':
                setWidgetComponent(<PollWidget type={'poll-text'} data={props.widget} />);
                break;
            case 'poll-text-image':
                setWidgetComponent(<PollWidget type={'poll-text-image'} data={props.widget} />);
                break;
            case 'poll-vs':
                setWidgetComponent(<PollVSWidget type={'poll-vs'} data={props.widget} />);
                break;
            case 'quiz-text':
                setWidgetComponent(<QuizWidget type={'quiz-text'} data={props.widget} />);
                break;
            case 'quiz-multi-question':
                setWidgetComponent(
                    <QuizMultiWidget type={'quiz-multi-question'} data={props.widget} />
                );
                break;
            case 'sponsor': {
                console.log(props);
                setWidgetComponent(<SponsorWidget type={'sponsor'} data={props.widget} />);
                console.log('now');
                break;
            }
        }

        const timeout = setTimeout(() => {
            chatElement = document.getElementById('chat-container');
            widgetElement = document.getElementById('widget');
            videoElement = document.getElementsByTagName('video')[0];
            let maxHeight = window.outerHeight - 60 - videoElement.scrollHeight;
            if (chatElement.offsetHeight < 72) {
                widgetElement.style.minHeight = widgetElement.scrollHeight + 'px';
                if (widgetElement.scrollHeight > maxHeight) {
                    widgetElement.style.minHeight = maxHeight + 'px';
                } else {
                    widgetElement.style.minHeight = widgetElement.scrollHeight + 'px';
                }
                widgetElement.style.maxHeight = maxHeight + 'px';
                widgetElement.style.overflowY = 'auto';
            }
        }, 1000);
        return () => clearTimeout(timeout);
    }, [props.widget]);

    const intervalRef = useInterval(() => {
        if (timerDuration >= 100) {
            window.clearInterval(intervalRef.current);
        }
        let newTimerDuration = timerDuration + 100 / (2 * props.widget.duration);
        setTimerDuration(newTimerDuration);
    }, 500);

    return (
        <div className={styles.widgetWrapper} id={'widget'}>
            <div className={styles.widgetTitle}>
                {props.widget.title}
                <CircularProgressbar
                    strokeWidth={50}
                    value={timerDuration}
                    className={styles.progress}
                    styles={buildStyles({
                        strokeLinecap: 'butt'
                    })}
                />
            </div>
            {widgetComponent && widgetComponent}
        </div>
    );
}
