import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../constants/actionTypes';
import styles from '../../styles/auth.module.scss';
import EnterUsernameStep from './EnterUsernameStep';
import SubmitButton from './SubmitButton';

import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import cognitoService from '../../core/service/cognito.service';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload })
});

const Register = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const title = t('RegisterWithMobileOrEmail');
    const [username, setUsername] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');
    const [errorMessagePhoneNumber, setErrorMessagePhoneNumber] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!username) {
                throw new Error(t('UserNotExistMessage'));
            }
            props.onSetUserData({ username, step: true });
            props.onSpinner(true);
            const authReq = await cognitoService.register(username);
            props.onSpinner(false);
            setErrorMessageEmail(null);
            setErrorMessagePhoneNumber(null);
            if (authReq) {
                history.push('confirmation');
            }
        } catch (e) {
            props.onSpinner(false);
            if (e.code && e.code === 'UserLambdaValidationException') {
                setErrorMessageEmail(t('UserNotExistMessage'));
                return;
            } else if (e.code && e.code === 'UsernameExistsException') {
                setErrorMessageEmail(t('EmailAccountExistsSignIn'));
                setErrorMessagePhoneNumber(t('MobileAccountExistsSignIn'));
                return;
            }
            setErrorMessageEmail(e.message);
        }
    };

    useEffect(() => {
        setErrorMessageEmail(null);
        setErrorMessagePhoneNumber(null);
    }, [username]);

    return (
        <IonPage>
            <IonContent>
                <div className={styles.authWrapper}>
                    <form onSubmit={handleSubmit} style={{ flex: 0 }}>
                        <EnterUsernameStep
                            title={title}
                            setUsername={setUsername}
                            errorMessageEmail={errorMessageEmail}
                            errorMessagePhoneNumber={errorMessagePhoneNumber}
                        />
                        <SubmitButton buttonText={t('Continue')} />
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
