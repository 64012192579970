import React, {useEffect, useState} from 'react';
import sanity from '../../sanity';
import styles from './widget.module.scss';
import {SET_ACTIVE_WIDGET, SET_WIDGET_RESULT} from '../../constants/actionTypes';
import {useDispatch} from 'react-redux';
import {Confirm} from '../Confirm/confirm';
import {useTranslation} from 'react-i18next';

export default function SponsorWidget(props) {
    const { t } = useTranslation();
    const sponsor = props.data.options[0];
    const [openConfirmationWindow, setOpenConfirmationWindow] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        let timer = setTimeout(() => {
            dispatch({
                type: SET_ACTIVE_WIDGET,
                payload: null
            });
            dispatch({
                type: SET_WIDGET_RESULT,
                payload: null
            });
        }, props.data.duration * 1000);
        if (timer) {
            return () => clearTimeout(timer);
        }
    }, []);

    // Check if the sponsor URL is valid
    function isValidURL(text) {
        var res = text.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g
        );

        if (res) {
            return true;
        } else return false;
    }

    // Open given URL depending user's device/platform
    const openURL = (sponsorText) => {
        let url = sponsorText;
        let optionWeb = 'location=yes,fullscreen=yes';
        let option = 'location=yes'; //for android
        let options = 'location=no,toolbarcolor=#D3D3D3'; //ios

        if (window.cordova) {
            if (device.platform === 'iOS') {
                window.cordova.InAppBrowser.open(url, '_blank', options);
            } else {
                window.cordova.InAppBrowser.open(url, '_blank', option);
            }
        } else {
            window.open(url, '_blank', optionWeb);
        }
        setOpenConfirmationWindow(false);
    };

    return (
        <React.Fragment>
            {/* Display sponsor widget */}
            {sponsor && (props.data.widget_text == undefined || props.data.widget_text == '') && (
                <div className={styles.sponsor}>
                    {window.cordova && (
                        <a onClick={() => setOpenConfirmationWindow(true)}>
                            <img
                                src={sanity.urlFor(sponsor.photo).width(200).auto('format')}
                                alt="Sponsor Photo"
                            />
                        </a>
                    )}
                    {!window.cordova && (
                        <a
                            href={isValidURL(sponsor.text) ? sponsor.text : ''}
                            target="_blank"
                            rel="noreferrer">
                            <img
                                src={sanity.urlFor(sponsor.photo).width(200).auto('format')}
                                alt="Sponsor Photo"
                            />
                        </a>
                    )}
                </div>
            )}

            {/* Display poll sponsor widget  */}
            {sponsor && props.data.widget_text && props.data.widget_text != '' && (
                <div className={styles.sponsor} style={{ display: 'flex', flexDirection: 'row' }}>
                    <img
                        src={sanity.urlFor(sponsor.photo).auto('format')}
                        style={{ height: '100px', width: '150px' }}
                        alt="Sponsor Photo"
                    />
                    <p
                        style={{
                            fontSize: '13px',
                            marginLeft: '5px',
                            marginRight: '5px',
                            lineHeight: '15.51px',
                            opacity: '0.8'
                        }}>
                        {props.data.widget_text}
                    </p>
                    <a onClick={() => setOpenConfirmationWindow(true)} style={{ fontSize: '13px' }}>
                        {t('Details')}
                    </a>
                </div>
            )}

            {/* Display confirmation dialog with 'Open' and 'Cancel' buttons */}
            {openConfirmationWindow && (
                <Confirm
                    successFunction={() =>
                        isValidURL(sponsor.text)
                            ? openURL(sponsor.text)
                            : setOpenConfirmationWindow(false)
                    }
                    cancelFunction={() => setOpenConfirmationWindow(false)}
                    title={t('OpenLinkInApp')}
                    subtitle={t('DoYouWantToOpenSponsorLink')}
                    cancelButtonText={t('Cancel')}
                    successButtonText={t('Open')}
                />
            )}
        </React.Fragment>
    );
}
