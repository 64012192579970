export default class ConvertVideo {
    constructor(props) {
        this.jobId = props.ConvertJobId;
        this.fileName = props.FileName;
    }

    static new(props) {
        return new ConvertVideo(props);
    }
}
