import React from 'react';
import styles from '../streaming.module.scss';
import {EventTypes} from './Helpers/eventTypes';
import {useTranslation} from 'react-i18next';

function LiveStreamInputs({ company, setEventTitle, setEventType, initialTags, setInitialTags }) {
    const eventTypes = EventTypes();
    const { t } = useTranslation();

    const tagList = [...initialTags];
    const removeTag = (e, index) => {
        e.preventDefault();
        if (index !== undefined) {
            if (e.target.value === '') {
                tagList.splice(index, 1);
            } else {
                tagList[index].tagName = e.target.value;
            }

            setInitialTags(tagList);
        }

        var key = e.keyCode || e.charCode;
        if (key == 8 || key === 46 || e.target.value === '') {
            if (e.target.value.length === 0) {
                tagList.splice(index, 1);
                setInitialTags(tagList);
            }
        }
    };

    const addNewTag = (name, status) => {
        if (name != '') {
            tagList.push({ tagName: name, tagStatus: status });
            setInitialTags(tagList);
        }
        let tmp = document.getElementById('temporaryInput');
        tmp.remove();
    };
    const checkTagStatus = (e, index) => {
        e.preventDefault();

        let element = e.target;
        console.log(e.target.value);
        if (index == undefined) {
            addNewTag(e.target.value, true);
            index = tagList.length - 1;
        } else {
            tagList[index].tagStatus = !tagList[index].tagStatus;
        }
        if (tagList[index]) {
            if (tagList[index].tagStatus) {
                element.style.backgroundColor = `${company ? company.primaryColor : `gray`}`;
                element.style.color = 'white';
            } else {
                element.style.backgroundColor = '#e5e5e5';
                element.style.color = 'gray';
            }
        }
    };

    return (
        <div className={styles.userInputs}>
            <input
                type="text"
                onChange={(e) => setEventTitle(e.target.value)}
                placeholder={t('DescribeStream')}
            />
            <select onChange={(e) => setEventType(e.target.value)}>
                {eventTypes.map((t) => {
                    return (
                        <option key={t.value} value={t.value}>
                            {t.title}
                        </option>
                    );
                })}
            </select>

            <h3>Event tags</h3>
            <div className={styles.tagsContainer} id="tags">
                {initialTags.map((element, index) => (
                    <input
                        key={index}
                        type="text"
                        defaultValue={element.tagName}
                        name={element.tagName}
                        style={
                            element.tagStatus
                                ? {
                                      background: `${company ? company.primaryColor : `gray`}`,
                                      color: 'white',
                                      borderRadius: '20px',
                                      border: 'none',
                                      textAlign: 'center'
                                  }
                                : {
                                      background: '#e5e5e5',
                                      color: 'gray',
                                      borderRadius: '20px',
                                      border: 'none',
                                      textAlign: 'center'
                                  }
                        }
                        // onKeyUp={(e) => handleChangeTag(e, element.id)}
                        onKeyUp={(e) => removeTag(e, index)}
                        onClick={(e) => checkTagStatus(e, index)}
                    />
                ))}
            </div>
            <div>
                <input
                    type="button"
                    value={`+ ${t('AddTag')}`}
                    style={{
                        background: '#e5e5e5',
                        color: 'gray',
                        borderRadius: '20px',
                        border: 'none',
                        textAlign: 'center'
                    }}
                    onClick={() => {
                        var element = document.createElement('input');
                        element.setAttribute('id', 'temporaryInput');

                        element.addEventListener('keyup', (e) => removeTag(e));
                        element.addEventListener('blur', (e) => checkTagStatus(e));

                        element.style.background = `${company ? company.primaryColor : `gray`}`;
                        element.style.color = 'white';
                        element.style.borderRadius = '20px';
                        element.style.border = 'none';
                        element.style.textAlign = 'center';
                        element.autofocus = true;

                        element.setAttribute('autofocus', '');

                        var divTags = document.getElementById('tags');
                        divTags.appendChild(element);
                    }}
                />
            </div>
        </div>
    );
}

export default LiveStreamInputs;
