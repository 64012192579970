import styles from '../Event/tabs.module.scss';
import {useTranslation} from 'react-i18next';

export default function Tabs(props) {
    const { t } = useTranslation();
    return (
        <div className={styles.tabsWrapper}>
            <div
                onClick={() => props.setActiveTab(0)}
                className={props.activeTab === 0 ? styles.tabActive : styles.inactiveTab}>
                {t('ScheduledTab')}
            </div>
            <div
                onClick={() => props.setActiveTab(1)}
                className={props.activeTab === 1 ? styles.tabActive : styles.inactiveTab}>
                {t('PublishedTab')}
            </div>
        </div>
    );
}
