import React, {useEffect, useState} from 'react';

const API_ENDPOINTS = {
    getStreamStatus: `${process.env.REACT_APP_API_URL}/ivs/getStream`
};

function PreviewLiveStream({ liveStreamStatus, config }) {
    const [streamState, setStreamState] = useState({
        health: '',
        state: 'loading',
        viewerCount: 0
    });
    useEffect(() => {
        const channelARN = config?.data?.channel?.arn ?? null;
        console.log({ channelARN, config, liveStreamStatus });
        if (channelARN && liveStreamStatus.live) {
            const interval = setInterval(function () {
                getStreamStatus(channelARN);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [config, liveStreamStatus]);

    const getStreamStatus = (channelArn) => {
        if (liveStreamStatus.live) {
            fetch(API_ENDPOINTS.getStreamStatus, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ channelArn })
            })
                .then((r) => r.json())
                .then((r) => setStreamState(r?.data?.stream))
                .catch((e) => console.error(e));
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative'
            }}>
            <canvas id="preview" style={{ aspectRatio: '16 / 9', width: '480px' }}></canvas>
            {/* IsLive? */}
            {liveStreamStatus.live && (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: 'black',
                        color: 'white',
                        top: '0',
                        opacity: '0.9',
                        padding: '10px'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '20px'
                        }}>
                        <div style={{ color: streamState?.state == 'LIVE' ? 'green' : 'red' }}>
                            {streamState?.state ?? 'OFFLINE'}
                        </div>
                        <div style={{ color: streamState?.health == 'LIVE' ? 'green' : 'yellow' }}>
                            {streamState?.health ?? 'N/A'}
                        </div>
                        <div>{streamState?.viewerCount ?? 0}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PreviewLiveStream;
