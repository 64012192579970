import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../../styles/auth.module.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function EnterUsernameStep(props) {
    const { t } = useTranslation();
    let location = useLocation();
    const availableCountries = ['SE', 'MK'];
    const [phoneLogin, setPhoneLogin] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    useEffect(() => {
        async function checkLocation() {
            try {
                if (location.pathname === '/register') {
                    setIsRegister(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
        checkLocation();
    }, [isRegister]);

    function toggleFilter(phoneLogin) {
        setPhoneLogin(phoneLogin);
        props.setUsername('');
    }
    const changePhoneNumber = (input) => {
        props.setUsername(input);
    };
    const changeEmailInput = (input) => {
        props.setUsername(input);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    const titleMailLogin = t('InviteMessageMail');
    const titlePhoneNumberLogin = t('InviteMessagePhone');

    const company = useSelector((state) => state.common.company);

    const [enablePhoneRegistration, setEnablePhoneRegistration] = useState(null);
    const [enableMailRegistration, setEnableMailRegistration] = useState(null);

    const [onlyMobile, setOnlyMobile] = useState(null);
    const [onlyMail, setOnlyMail] = useState(null);
    const [bothMailAndPhoneNumber, setBothMailAndPhoneNumber] = useState(null);

    const [enabledMobileRegistration, setEnabledMobileRegistration] = useState(false);
    const [enabledEmailRegistration, setEnabledEmailRegistration] = useState(false);

    const [onlyMobileRegister, setOnlyMobileRegister] = useState(null);
    const [onlyEmailRegister, setOnlyEmailRegister] = useState(null);
    const [bothEmailAndMobileRegister, setEmailAndMobileRegister] = useState(null);

    useEffect(() => {
        if (company !== null) {
            setEnablePhoneRegistration(company.enable_phone_register); // enabled phone login
            setEnableMailRegistration(company.enable_email_register); // enabled mail login
            setEnabledMobileRegistration(company.enable_mobile_registration); //enabled mobile register
            setEnabledEmailRegistration(company.enable_mail_registration); // enabled email register
        }

        if (enableMailRegistration == false && enablePhoneRegistration == true) {
            setOnlyMobile(true);
        } else if (enableMailRegistration == true && enablePhoneRegistration == false) {
            setOnlyMail(true);
        } else if (enableMailRegistration == true && enablePhoneRegistration == true) {
            setBothMailAndPhoneNumber(true);
        }

        if (enabledEmailRegistration == false && enabledMobileRegistration == true) {
            setOnlyMobileRegister(true);
        } else if (enabledEmailRegistration == true && enabledMobileRegistration == false) {
            setOnlyEmailRegister(true);
        } else if (enabledEmailRegistration == true && enabledMobileRegistration == true) {
            setEmailAndMobileRegister(true);
        }
    });

    return (
        <React.Fragment>
            {!isRegister && onlyMobile && <h1>{titlePhoneNumberLogin}</h1>}
            {!isRegister && onlyMail && <h1>{titleMailLogin}</h1>}
            {!isRegister && bothMailAndPhoneNumber && <h1>{props.title}</h1>}

            {isRegister && onlyMobileRegister && <h1>{t('RegisterPhone')}</h1>}
            {isRegister && onlyEmailRegister && <h1>{t('RegisterMail')}</h1>}
            {isRegister && bothEmailAndMobileRegister && <h1>{props.title}</h1>}
            <main>
                <div className={styles.authTabs}>
                    {enableMailRegistration && !isRegister && (
                        <div
                            onClick={() => toggleFilter(false)}
                            className={!phoneLogin ? styles.activeTab : ''}>
                            {t('Email')}
                        </div>
                    )}
                    {enablePhoneRegistration && !isRegister && onlyMobile != true && (
                        <div
                            onClick={() => toggleFilter(true)}
                            className={phoneLogin ? styles.activeTab : ''}>
                            {t('MobileNumber')}
                        </div>
                    )}
                    {enablePhoneRegistration && !isRegister && onlyMobile == true && (
                        <div
                            onClick={() => toggleFilter(false)}
                            className={!phoneLogin ? styles.activeTab : ''}>
                            {t('MobileNumber')}
                        </div>
                    )}

                    {isRegister && enabledEmailRegistration && (
                        <div
                            onClick={() => toggleFilter(false)}
                            className={!phoneLogin ? styles.activeTab : ''}>
                            {t('Email')}
                        </div>
                    )}
                    {isRegister && enabledMobileRegistration && onlyMobileRegister != true && (
                        <div
                            onClick={() => toggleFilter(true)}
                            className={phoneLogin ? styles.activeTab : ''}>
                            {t('MobileNumber')}
                        </div>
                    )}

                    {isRegister && enabledMobileRegistration && onlyMobileRegister == true && (
                        <div
                            onClick={() => toggleFilter(false)}
                            className={!phoneLogin ? styles.activeTab : ''}>
                            {t('MobileNumber')}
                        </div>
                    )}
                </div>
                {!isRegister && phoneLogin && !onlyMobile && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <PhoneInput
                            placeholder="ex. 0708720443"
                            onChange={(e) => changePhoneNumber(e)}
                            defaultCountry="SE"
                            countries={availableCountries}
                            addInternationalOption={false}
                        />
                        {/*<p>
                            Genom att fortsätta godkänner du våra <a href="">användarvillkor</a> &{' '}
                            <a href="">sekretessavtal</a>
                        </p>*/}
                    </div>
                )}
                {!isRegister && !phoneLogin && !onlyMobile && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <input
                            type="email"
                            placeholder={'ex. mail@mail.com'}
                            onChange={(e) => changeEmailInput(e.target.value)}
                        />
                        {/*<p>
                            Genom att fortsätta godkänner du våra <a href="">användarvillkor</a> &{' '}
                            <a href="">sekretessavtal</a>
                        </p>*/}
                    </div>
                )}

                {!isRegister && onlyMobile && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <PhoneInput
                            placeholder="ex. 0708720443"
                            onChange={(e) => changePhoneNumber(e)}
                            defaultCountry="SE"
                            countries={availableCountries}
                            addInternationalOption={false}
                        />
                        {/*<p>
                            Genom att fortsätta godkänner du våra <a href="">användarvillkor</a> &{' '}
                            <a href="">sekretessavtal</a>
                        </p>*/}
                    </div>
                )}

                {isRegister && phoneLogin && !onlyMobileRegister && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <PhoneInput
                            placeholder="ex. 0708720443"
                            onChange={(e) => changePhoneNumber(e)}
                            defaultCountry="SE"
                            countries={availableCountries}
                            addInternationalOption={false}
                        />
                    </div>
                )}
                {isRegister && !phoneLogin && !onlyMobileRegister && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <input
                            type="email"
                            placeholder={'ex. mail@mail.com'}
                            onChange={(e) => changeEmailInput(e.target.value)}
                        />
                    </div>
                )}

                {isRegister && onlyMobileRegister && (
                    <div
                        className={`${styles.emailForm}
                    ${props.errorMessage ? styles.errorForm : ''}
                    `}>
                        <PhoneInput
                            placeholder="ex. 0708720443"
                            onChange={(e) => changePhoneNumber(e)}
                            defaultCountry="SE"
                            countries={availableCountries}
                            addInternationalOption={false}
                        />
                    </div>
                )}

                {props.errorMessage && <p className={styles.authError}>{props.errorMessage}</p>}
                {!phoneLogin && props.errorMessageEmail && (
                    <p className={styles.authError}>{props.errorMessageEmail}</p>
                )}
                {phoneLogin && props.errorMessagePhoneNumber && (
                    <p className={styles.authError}>{props.errorMessagePhoneNumber}</p>
                )}
                {isRegister && (
                    <div>
                        <p>
                            {t('ContinuingYouAgreeToTerms')} <br />
                            <Link to={'/privacyPolicy'}>{t('PrivacyPolicy')}</Link>,{' '}
                            <Link to={'/termsAndConditions'}>{t('TermsAndConditions')}</Link>
                            {' & '}
                            <Link to={'/cookiePolicy'}>{t('CookiePolicy')}</Link>
                        </p>
                        <p>
                            {t('AlreadyHaveAccount')}{' '}
                            <a onClick={() => history.push('/login')}>{t('Login')}</a>
                        </p>
                    </div>
                )}
                {!isRegister && (enabledMobileRegistration || enabledEmailRegistration) && (
                    <p>
                        {t('HaveNoAccount')}{' '}
                        <a onClick={() => history.push('/register')}>{t('RegisterYourself')}</a>
                    </p>
                )}
            </main>
        </React.Fragment>
    );
}
