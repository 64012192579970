import sanity from '../../sanity';

export default class Blog {
    constructor(props) {
        console.log('props', props);
        // this.id = props._id;
        this.title = props.title;
        this.slug = props.slug;
        this.tags = props.tags;
        this.mainImage = props.mainImage;
        this.sports_team = props.sports_team;
        this.blogText = props.blogText;
        this.author = props.author;
        const blogDate = new Date(props.blogDate);
        this.blogDate = blogDate.toISOString();
    }

    getTitle() {
        return this.title;
    }

    getThumbnail() {
        if (this.thumbnail) {
            return sanity.urlFor(this.mainImage.image_asset);
        }
        return null;
    }

    static new(props) {
        return new Blog(props);
    }
}
