import OnBack from '../Auth/OnBack';
import styles from './settings.module.scss';
// import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { IonContent, IonPage } from '@ionic/react';
import { goBack } from 'connected-react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SET_USER_DATA, SPINNER_VISIBLE } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import { getVideoQualityPreset } from '../../helpers/helpers';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.data
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const Preferences = (props) => {
    const { t } = useTranslation();
    const [videoQuality, setVideoQuality] = useState(getVideoQualityPreset(props.user));

    const onBack = () => {
        props.onGoBack();
    };

    let images = '/images';

    if (window.cordova) {
        images = 'images';
    }

    const selectResolution = async (res) => {
        try {
            props.onSpinner(true);
            await cognitoService.updateDefaultVideoQuality(`${res}`);
            props.onSpinner(false);

            const currentUser = await cognitoService.getUserData();
            props.onSetUserData(currentUser);
            setVideoQuality(getVideoQualityPreset(currentUser.data));
        } catch (e) {
            console.error(e);
            props.onSpinner(false);
        }
    };

    return (
        <IonPage>
            <OnBack defaultHref={'/profile/settings'} title={t('Preferences')} />
            <IonContent>
                <div className={styles.profileWrapper}>
                    <main>
                        <div className={styles.settingsList} style={{ borderTop: 0 }}>
                            <div className={styles.itemTitle}>
                                <span>{t('Video')}</span>
                            </div>
                            <div onClick={() => selectResolution(720)}>
                                <div>
                                    <span>{t('HigherResolution')}</span>
                                </div>
                                {videoQuality === 1 && (
                                    <img src={`${images}/check.svg`} alt="Right Arrow" />
                                )}
                            </div>
                            <div onClick={() => selectResolution(360)}>
                                <div>
                                    <span>{t('LowerResolution')}</span>
                                </div>
                                {videoQuality === 0 && (
                                    <img src={`${images}/check.svg`} alt="Right Arrow" />
                                )}
                            </div>
                            <div onClick={() => selectResolution(999)}>
                                <div>
                                    <span>{t('AutoResolution')}</span>
                                </div>
                                {videoQuality === 2 && (
                                    <img src={`${images}/check.svg`} alt="Right Arrow" />
                                )}
                            </div>
                        </div>
                    </main>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
