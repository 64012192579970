import {BLOG_LIST_PAGE_LOADED, BLOG_LIST_PAGE_UNLOAD, BLOG_PAGE_LOADED} from '../constants/actionTypes';

export default (state = { blogPosts: [], currentBlogPost: null }, action) => {
    switch (action.type) {
        case BLOG_LIST_PAGE_LOADED: {
            let blogPosts = [];
            blogPosts = action.payload;
            return {
                ...state,
                blogPosts
            };
        }
        case BLOG_PAGE_LOADED:
            return {
                ...state,
                currentBlogPost: action.payload
            };

        case BLOG_LIST_PAGE_UNLOAD: {
            return {
                ...state,
                blogPosts: []
            };
        }
        default:
            return state;
    }
};
