import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_ACTIVE_WIDGET, SET_WIDGET_RESULT, VOTE_WIDGET} from '../../constants/actionTypes';
import QuizOption from './QuizOption';
import styles from './widget.module.scss';

export default function QuizWidget(props) {
    const [options, setOptions] = useState(props.data.options);
    const [title, setTitle] = useState(props.data.widget_text);
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    let widgetResults = useSelector((state) => state.widget.widgetResults);

    const pickOption = (optionId) => {
        if (!selected && !widgetResults) {
            setSelected(optionId);
            const selectedOptions = [...options];
            let indexOfSelected = selectedOptions.findIndex((option) => option._key === optionId);
            selectedOptions[indexOfSelected].isSelected = true;
            setOptions(selectedOptions);
            dispatch({
                type: VOTE_WIDGET,
                payload: { optionId, widgetId: props.data.id, username: currentUser.data.nickname }
            });
        }
    };

    useEffect(() => {
        const optionsWithResults = [];
        let timer;
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (widgetResults) {
                option.resultCount = widgetResults?.vote_results[i]?.count;
                option.resultPercentage = widgetResults?.vote_results[i]?.percentage;

                if (option._key === selected && option.important) {
                    setTitle('Du svarade rätt');
                } else if (option._key !== selected && option.important) {
                    setTitle('Nja...');
                }
                timer = setTimeout(() => {
                    dispatch({
                        type: SET_ACTIVE_WIDGET,
                        payload: null
                    });
                    dispatch({
                        type: SET_WIDGET_RESULT,
                        payload: null
                    });
                }, 20000);
            }
            optionsWithResults.push(option);
        }
        setOptions(optionsWithResults);
        if (timer) {
            return () => clearTimeout(timer);
        }
    }, [widgetResults]);
    return (
        <div className={styles.quizWrapper} id={'quiz'}>
            <h2 style={{ textAlign: 'center' }}>{title}</h2>
            {options.map((option) => {
                return (
                    <QuizOption
                        key={option._key}
                        value={option}
                        pickOption={pickOption}
                        selected={selected}
                    />
                );
            })}
        </div>
    );
}
