import {
    CLEAN_LAST_STREAM_DATA,
    SET_CURRENT_LIVESTREAM,
    SET_IVS_STREAM_CONFIG,
    SET_MEDIA_DEVICES,
    SET_STREAMING_CLIENT,
    SET_VIDEO_TAGS,
    SET_VIDEO_THUMBNAIL,
    SET_VIDEO_TITLE,
    SET_CAMERA_FACING_MODE,
    SET_MIC_MUTE, SET_VIDEO_DEVICES, SET_VIDEO_SELECT_DEVICE
} from '../constants/actionTypes';

export default (
    state = {
        config: null,
        mediaDevices: {
            videoDevices: null,
            audioDevices: [],
            showChoice: false,
            selectedVideoDevice: 0,
            selectedAudioDevice: 0,
            mutedMicrophone: false,
            hiddenVideo: false,
            devicesRegistered: false
        },
        liveStreamStatus: {
            live: false
        },
        streamingClient: null,
        videoTitle: '',
        videoDescription: '',
        tags: [],
        videoThumbnail: {},
        facingMode: 'user',
        selectedDevice: null,
        microphone: 'unmuted'
    },
    action
) => {
    switch (action.type) {
        case SET_IVS_STREAM_CONFIG: {
            return {
                ...state,
                config: action.payload
            };
        }
        case SET_MEDIA_DEVICES: {
            return {
                ...state,
                mediaDevices: action.payload
            };
        }
        case SET_CURRENT_LIVESTREAM: {
            return {
                ...state,
                liveStreamStatus: { ...state.liveStreamStatus, ...action.payload }
            };
        }
        case SET_STREAMING_CLIENT: {
            return {
                ...state,
                streamingClient: action.payload
            };
        }
        case CLEAN_LAST_STREAM_DATA: {
            // TODO: call delete live stream api.

            return {
                ...state,
                liveStreamStatus: { ...state.liveStreamStatus, live: false },
                streamingClient: null,
                mediaDevices: {
                    videoDevices: [],
                    audioDevices: [],
                    showChoice: false,
                    selectedVideoDevice: 0,
                    selectedAudioDevice: 0,
                    mutedMicrophone: false,
                    hiddenVideo: false,
                    devicesRegistered: false
                },
                config: null
            };
        }
        case SET_VIDEO_TITLE: {
            return {
                ...state,
                videoTitle: action.payload
            };
        }
        case SET_VIDEO_TAGS: {
            return {
                ...state,
                videoTags: action.payload
            };
        }
        case SET_VIDEO_THUMBNAIL: {
            return {
                ...state,
                videoThumbnail: action.payload
            };
        }
        case SET_CAMERA_FACING_MODE: {
            return {
                ...state,
                facingMode: action.payload
            };
        }
        case SET_MIC_MUTE: {
            return {
                ...state,
                microphone: action.payload
            };
        }
        case SET_VIDEO_DEVICES: {
            return {
                ...state,
                mediaDevices : {
                    ...state.mediaDevices,
                    videoDevices: action.payload
                },
                devicesRegistered: true
            };
        }
        case SET_VIDEO_SELECT_DEVICE: {
            return {
                ...state,
                selectedDevice: action.payload
            };
        }
        default:
            return state;
    }
};
