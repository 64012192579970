import {getBase64} from '../../helpers/FileReader';
import fetch from '../../fetch';

const uploadCompanyLogo = async (photoForUpload) => {
    const base64Image = await getBase64(photoForUpload);
    console.log("base64Image:",base64Image);
    const dataImg = base64Image.split(';base64,')[1];
    const res = await fetch.Company.PhotoInsert({
        photo: photoForUpload,
        data: dataImg
    });

    return res;
};

export { uploadCompanyLogo };
