import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './eventTag.module.scss';

const EventTag = (props) => {
    const history = useHistory();
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log('TAG', tag);
        history.push(`/search?tag=${tag}`);
    };

    const { tag } = props;

    return (
        <button
            className={styles.eventTag}
            onClick={(event) => handleClick(event)}
            style={{
                backgroundColor:
                    tag === 'Exclusive' ? '#32E7B2' : tag === 'Premium' ? 'black' : '#dbdde0',
                color: tag === 'Premium' && 'white'
            }}>
            {tag}
        </button>
    );
};

export default EventTag;
