import React, { useState } from 'react';
import styles from './eventListItem.module.scss';
import { Link } from 'react-router-dom';
import EventTag from './EventTag';
import actions from '../../assets/images/actions.svg';
import EventOptions from './EventOptions.js';
import ReactTimeAgo from 'react-time-ago';
import toast, { Toaster } from 'react-hot-toast';
import EventThumbnail from './EventThumbnail';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { checkmarkCircleSharp } from 'ionicons/icons';
import { useIonToast } from '@ionic/react';

export default function EventListItem(props) {
    const { t } = useTranslation();
    const event = props.event;
    const [isClicked, setIsClicked] = useState(false);
    const [present] = useIonToast();
    const presentToast = (message) => {
        present({
            message: message,
            duration: 5000,
            position: 'top',
            icon: checkmarkCircleSharp,
            color: 'success'
        });
    };

    const buttonClicked = (e, isOpen) => {
        e.preventDefault();
        setIsClicked(isOpen);
    };

    const notify = (message) => {
        if (message == 'Event already exists') {
            presentToast(message);
        } else {
            presentToast(message);
        }
    };

    return (
        <div
            style={{
                backgroundColor: (event.isPremium || event.subscriber_exclusive) && '#32e7b226'
            }}
            className={styles.eventItem}>
            <Link
                to={`/events/${event.slug}`}
                onClick={(e) =>
                    !props.isAdmin && new Date(event.start_date) > new Date()
                        ? e.preventDefault()
                        : null
                }>
                <EventThumbnail event={event} />
                <div className={styles.eventDetails} style={{ maxWidth: '53vw' }}>
                    <h3>{event.title}</h3>
                    <Typography variant="body2" sx={{ margin: '0px !important' }}>
                        <ReactTimeAgo date={Date.parse(event.start_date)} />
                    </Typography>
                    <div style={{ display: 'flex' }}>
                        {event.isPremium && <EventTag key="Premium" tag="Premium" />}
                        {event.subscriber_exclusive && <EventTag key="Exclusive" tag="Exclusive" />}
                        {event.tags &&
                            event.tags.map((tag) => {
                                return <EventTag key={tag} tag={tag} />;
                            })}
                    </div>
                </div>
            </Link>
            <button
                onClick={(e) => {
                    if (props.setOpenDrawer) {
                        props.setOpenDrawer((prevOpenDrawer) => !prevOpenDrawer);
                        props.setClickedVideoId(event.id);
                    } else {
                        buttonClicked(e, true);
                    }
                }}>
                <img src={actions} alt={t('EventActions')} />
            </button>
            {isClicked && (
                <EventOptions
                    event={event}
                    isClicked={isClicked}
                    setIsClicked={setIsClicked}
                    notify={notify}
                />
            )}

            {/* <Toaster
                reverseOrder={false}
                toastOptions={{
                    className: '',
                    style: {
                        boxShadow: 'none'
                    }
                }}
            /> */}
        </div>
    );
}
