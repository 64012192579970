//Express Country Support
export const expressCountries = [
    {name: "Australia" , iso: "AU"},
    {name: "Austria" , iso: "AT"},
    {name: "Belgium" , iso: "BE"},
    {name: "Brazil" , iso: "BR"},
    {name: "Bulgaria" , iso: "BG"},
    {name: "Canada" , iso: "CA"},
    {name: "Croatia" , iso: "HR"},
    {name: "Cyprus" , iso: "CY"},
    {name: "Czech Republic" , iso: "CZ"},
    {name: "Denmark" , iso: "DK"},
    {name: "Estonia" , iso: "EE"},
    {name: "Finland" , iso: "FI"},
    {name: "France" , iso: "FR"},
    {name: "Germany" , iso: "DE"},
    {name: "Greece" , iso: "GR"},
    {name: "Hong Kong" , iso: "HK"},
    {name: "Hungary" , iso: "HU"},
    {name: "Ireland" , iso: "IE"},
    {name: "Italy" , iso: "IT"},
    {name: "Japan" , iso: "JP"},
    {name: "Latvia" , iso: "LV"},
    {name: "Lithuania" , iso: "LT"},
    {name: "Luxembourg" , iso: "LU"},
    {name: "Malta" , iso: "MT"},
    {name: "Mexico" , iso: "MX"},
    {name: "Netherlands" , iso: "NL"},
    {name: "New Zealand" , iso: "NZ"},
    {name: "Norway" , iso: "NO"},
    {name: "Poland" , iso: "PL"},
    {name: "Portugal" , iso: "PT"},
    {name: "Romania" , iso: "RO"},
    {name: "Singapore" , iso: "SG"},
    {name: "Slovakia" , iso: "SK"},
    {name: "Slovenia" , iso: "SI"},
    {name: "Spain" , iso: "ES"},
    {name: "Sweden" , iso: "SE"},
    {name: "Switzerland" , iso: "CH"},
    {name: "Thailand" , iso: "TH"},
    {name: "United Kingdom" , iso: "GB"},
    {name: "United States" , iso: "US"}
];