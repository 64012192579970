import React from 'react';
import {useTranslation} from 'react-i18next';

function ChooseMediaDevices({
    mediaDevices,
    chooseVideoDevice,
    chooseAudioDevice,
    muteMicrophone,
    unmuteMicrophone
}) {
    const { t } = useTranslation();
    return (
        <div>
            {
                // list media devices
                mediaDevices.showChoice && (
                    <>
                        <div id="videoDevices">
                            <p>Video Devices: </p>
                            {mediaDevices.videoDevices.map((device, index) => (
                                <button
                                    key={'videoDeviceIndex-' + index}
                                    onClick={() => chooseVideoDevice(index)}
                                    disabled={mediaDevices.selectedVideoDevice === index}>
                                    {device?.label !== ''
                                        ? device.label
                                        : t('VideoDevice', { id: index })}
                                </button>
                            ))}
                        </div>
                        <div id="audioDevices">
                            <p>Audio Devices: </p>
                            {mediaDevices.audioDevices.map((device, index) => (
                                <button
                                    key={'audioDeviceIndex-' + index}
                                    onClick={() => chooseAudioDevice(index)}
                                    disabled={mediaDevices.selectedAudioDevice === index}>
                                    {device?.label !== ''
                                        ? device.label
                                        : t('AudioDevice', { id: index })}
                                </button>
                            ))}
                        </div>
                        <hr />
                        {mediaDevices.mutedMicrophone ? (
                            <button onClick={unmuteMicrophone}>{t('UnmuteMic')}</button>
                        ) : (
                            <button onClick={muteMicrophone}>{t('MuteMic')}</button>
                        )}
                    </>
                )
            }
        </div>
    );
}

export default ChooseMediaDevices;
