// General
export const APP_LOAD = 'APP_LOAD';
export const SPINNER_VISIBLE = 'SPINNER_VISIBLE';
export const SHOW_PWA_PROMPT = 'SHOW_PWA_PROMPT';
export const SET_CONTROL_BAR = 'SET_CONTROL_BAR';

// Events
export const EVENT_LIST_PAGE_LOADED = 'EVENT_LIST_PAGE_LOADED';
export const EVENT_PAGE_LOADED = 'EVENT_PAGE_LOADED';
export const EVENT_PAGE_UNLOADED = 'EVENT_PAGE_UNLOADED';
export const PEOPLE_WATCHING = 'PEOPLE_WATCHING';
export const WATCHING_NOW = 'WATCHING_NOW';
export const SET_WATCH_COUNT = 'SET_WATCH_COUNT';
export const GET_WATCH_COUNT = 'GET_WATCH_COUNT';

export const RESET_WATCH_COUNT = 'RESET_WATCH_COUNT';
export const SET_WATCH_COUNT_RESULT = 'SET_WATCH_COUNT_RESULT';

export const EVENT_UPDATE = 'EVENT_UPDATE';
export const EVENT_STREAMING_UPDATE = 'EVENT_STREAMING_UPDATE';
export const CLEAR_EVENT_STREAMING = 'CLEAR_EVENT_STREAMING';

export const CUSTOM_PHONE_CREATE_CHALLENGE = 'CUSTOM_PHONE_CREATE_CHALLENGE';
export const CUSTOM_PHONE_DEFINE_CHALLENGE = 'CUSTOM_PHONE_DEFINE_CHALLENGE';
export const CUSTOM_PHONE_VERIFY_CHALLENGE = 'CUSTOM_PHONE_VERIFY_CHALLENGE';

export const CREATE_EVENT = 'CREATE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const STOP_CHANNEL = 'STOP_CHANNEL';

// Video
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_VIDEO_FINISHED = 'UPLOAD_VIDEO_FINISHED';
export const UPLOAD_REMOVE = 'UPLOAD_REMOVE';

export const CONVERT_VIDEO = 'CONVERT_VIDEO';
export const CONVERT_VIDEO_RECEIVED = 'CONVERT_VIDEO_RECEIVED';
export const CONVERSION_STATUS = 'CONVERSION_STATUS';
export const CONVERSION_STATUS_RECEIVED = 'CONVERSION_STATUS_RECEIVED';
//Account
export const CONNECTED_ACCOUNT_LOAD_FINISHED = 'CONNECTED_ACCOUNT_LOAD_FINISHED';
//Products
export const CONNECTED_PRODUCTS_LOAD_FINISHED = 'CONNECTED_PRODUCTS_LOAD_FINISHED';
//Prices
export const PLATFORM_PRICES_LOAD_FINISHED = 'PLATFORM_PRICES_LOAD_FINISHED';
export const CONNECTED_PRICES_LOAD_FINISHED = 'CONNECTED_PRICES_LOAD_FINISHED';
export const CONNECT_PRICE_CHANGE = 'CONNECT_PRICE_CHANGE';
// Chat
export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const REACTION_RECEIVED = 'REACTION_RECEIVED';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';
export const REPORT_MESSAGE = 'REPORT_MESSAGE';
export const REPORT_MESSAGE_CLEAR = 'REPORT_MESSAGE_CLEAR';

// Websocket connectins
export const SOCKET_CONNNECT = 'SOCKET_CONNNECT';
export const SOCKET_DISCONNNECT = 'SOCKET_DISCONNNECT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const FETCH_NEW_MESSAGES = 'FETCH_NEW_MESSAGES';

// Cognito
export const SET_USER_DATA = 'SET_USER_DATA';
export const REFETCH_USER_DATA = 'REFETCH_USER_DATA';

// Widgets
export const SET_ACTIVE_WIDGET = 'SET_ACTIVE_WIDGET';

export const VOTE_WIDGET = 'VOTE_WIDGET';
export const SET_WIDGET_RESULT = 'SET_WIDGET_RESULT';
export const SET_PAST_WIDGETS = 'SET_PAST_WIDGETS';
export const SET_WIDGET_PAST_EVENT = 'SET_WIDGET_PAST_EVENT';

export const INVITE_BY_SMS = 'INVITE_BY_SMS';

export const PUSH_TOKEN_UPDATE = 'PUSH_TOKEN_UPDATE';

//Blog Posts
export const BLOG_LIST_PAGE_LOADED = 'BLOG_LIST_PAGE_LOADED';
export const BLOG_LIST_PAGE_UNLOAD = 'BLOG_LIST_PAGE_UNLOAD';
export const BLOG_PAGE_LOADED = 'BLOG_PAGE_LOADED';
// Company
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_COMPANY_STATS = 'SET_COMPANY_STATS';

export const GET_BLOCKED_USERS = 'GET_BLOCKED_USERS';
export const BLOCKING_USER = 'BLOCKING_USER';
export const UNBLOCKING_USER = 'UNBLOCKING_USER';
export const SET_BLOCKED_USERS = 'SET_BLOCKED_USERS';

export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const SET_FOLLOWERS = 'SET_FOLLOWERS';
export const CHANGE_USER_GROUP = 'CHANGE_USER_GROUP';
export const SET_USER_GROUP = 'SET_USER_GROUP';
// App State
export const SET_EVENT_TAB_ACTIVE = 'SET_EVENT_TAB_ACTIVE';
export const APP_IN_BACKGROUND = 'APP_IN_BACKGROUND';
export const APP_RESUME = 'APP_RESUME';
export const ORIENTATION_CHANGE = 'ORIENTATION_CHANGE';

// Ads
export const LAST_WATCHED_PREROLL_AD_AT = '_lastWatchedPreRollAdAt';
export const LONG_TERM_AD_STATE_STORAGE = '_adsState';
export const AD_VIEWED = 'AD_VIEWED';
export const SET_VIDEO_MUTED_STATE = 'SET_VIDEO_MUTED_STATE';

// Live Streaming
export const SET_IVS_STREAM_CONFIG = 'SET_IVS_STREAM_CONFIG';
export const SET_MEDIA_DEVICES = 'SET_MEDIA_DEVICES';
export const SET_CURRENT_LIVESTREAM = 'SET_CURRENT_LIVESTREAM';
export const SET_STREAMING_CLIENT = 'SET_STREAMING_CLIENT';
export const CLEAN_LAST_STREAM_DATA = 'CLEAN_LAST_STREAM_DATA';
export const SET_VIDEO_TITLE = 'SET_VIDEO_TITLE';
export const SET_VIDEO_TAGS = 'SET_VIDEO_TAGS';
export const SET_VIDEO_THUMBNAIL = 'SET_VIDEO_THUMBNAIL';
export const SET_CAMERA_FACING_MODE = 'SET_CAMERA_FACING_MODE';
export const SET_VIDEO_DEVICES = 'SET_VIDEO_DEVICES';
export const SET_VIDEO_SELECT_DEVICE = 'SET_VIDEO_SELECT_DEVICE';
export const SET_MIC_MUTE = 'SET_MIC_MUTE';

// Errors
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
