import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import { SET_VIDEO_TAGS } from '../../constants/actionTypes';

const mapDispatchToProps = (dispatch) => ({
    setTags: (tag) => dispatch({ type: SET_VIDEO_TAGS, payload: tag })
});

const TagsInput = ({ handleFocus, handleBlur, registerRef, classes, onChange, setTags }) => {
    const [inputValue, setInputValue] = useState('');
    const [chips, setChips] = useState([]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        onChange(chips);
        setTags(chips);
    }, [chips]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setChips((prevChips) => [...prevChips, inputValue.trim()]);
            setInputValue('');
        }
    };

    const handleDeleteChip = (chipToDelete) => {
        setChips((prevChips) => prevChips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <div>
            <TextField
                label="Enter Text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                fullWidth
                InputLabelProps={{
                    ref: registerRef
                }}
                inputProps={{
                    style: {
                        color: 'white'
                    }
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className={`${classes.textField} ${classes.label}`}
            />
            <div>
                <div style={{ margin: '2vh 0' }}>
                    {chips.map((chip) => (
                        <Chip
                            key={chip}
                            label={chip}
                            onDelete={() => handleDeleteChip(chip)}
                            sx={{
                                backgroundColor: 'lightgray',
                                color: 'black',
                                margin: '0.5vh'
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps)(TagsInput);
