import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        company: state.common.company,
        subscription: state.user?.currentUser?.premium_services
    };
};

const StreamingStats = ({ company, subscription }) => {
    const DEMO_TOTAL = 5;
    const SUBSCRIPTION_TOTAL = 50;
    const [percentage, setPercentage] = useState(0);
    const [streamed, setStreamed] = useState(0);
    const [interval, setInterval] = useState('');
    const { t } = useTranslation();
    const [total, setTotal] = useState(DEMO_TOTAL);

    useEffect(() => {
        if (company?.streamingStats) {
            let statsValue = company?.streamingStats?.storage;
            setStreamed(statsValue);
            let result = Math.max(0, Math.min(100, (statsValue / total) * 100));
            setPercentage(result);
        }
    }, [company , total]);

    useEffect(() => {
        if (subscription) {
            setInterval(subscription?.interval);
            setTotal(SUBSCRIPTION_TOTAL);
        }
    }, [subscription]);

    return (
        <Box>
            <Typography sx={{ color: 'gray' }}>{t('CurrentPlan')}</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography textTransform="capitalize" variant="caption">
                    {interval}
                </Typography>
                <Typography variant="caption">
                    {streamed} GB / {total} GB
                </Typography>
            </Box>
            <LinearProgress
                variant="determinate"
                color={percentage > total ? 'error' : 'primary'}
                value={percentage}
            />
        </Box>
    );
};

export default connect(mapStateToProps)(StreamingStats);
