import {call, cancel, fork, put, take, takeEvery} from 'redux-saga/effects';
import {
    CONVERSION_STATUS,
    CONVERT_VIDEO,
    UPDATE_EVENT,
    UPLOAD_PROGRESS,
    UPLOAD_REMOVE,
    UPLOAD_VIDEO,
    UPLOAD_VIDEO_FINISHED
} from '../constants/actionTypes';
import {buffers, END, eventChannel} from 'redux-saga';
import {Uploader} from '../components/Profile/UploadVideoMP';
import fetchData from '../fetch';
import axios from 'axios';
import {CONVERT_STATUS} from "../constants";

const convertVideo = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => convertVideo(socket, body), 300);
    } else {
        const payload = JSON.stringify({
            action: 'convertvideo',
            body: {
                ...body
            }
        });

        socket.Client.send(payload);
    }
};

const conversionStatus = (socket, body) => {
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => conversionStatus(socket, body), 300);
    } else {
        const payload = JSON.stringify({
            action: 'conversionstatus',
            body: {
                ...body
            }
        });

        socket.Client.send(payload);
    }
};

function* uploadVideo (channel , action) {
    const {file , jobId} = action.payload;
    if (file) {
        while (true) {
            const { progress = 0, error, url , show } = yield take(channel, buffers.sliding(5));

            if (error) {
                yield put({type: UPLOAD_PROGRESS , payload:{progress , error , jobId , show }});
                channel.close();
                return;
            }
            if (url) {
                yield put({type: UPLOAD_VIDEO_FINISHED , payload:{ progress: 100 , jobId , url }});
                yield put({type: UPDATE_EVENT , payload:{ progress , jobId , endpoint: url , processingStatus: CONVERT_STATUS }});
                channel.close();
                return;
            }
            yield put({type: UPLOAD_PROGRESS , payload: {progress , jobId , show }});
        }
   }
};

// Start the event channel in a forked task
function* startUpload(action) {
    const {file , type} = action.payload;
    const channel = yield call(uploadFileChannel, file , type);
    const task = yield fork(uploadVideo, channel , action);

    // Wait for a cancellation action
    yield take(UPLOAD_REMOVE); // Replace with your cancellation action type
    // Cancel the task and close the channel
    yield cancel(task);
}

function uploadFileChannel(file , type) {
    return eventChannel(emitter => {
      upload(emitter , file , type);
        // The subscriber must return an unsubscribe function
      return () => {
        emitter({END})
      }
    });
}

async function uploadDummy(emitter , file , type) {
    let interval;
    let count = 0;

    function emitEvent() {
        count++;
        const event = count === 100 ? 'end' : 'event';
        emitter({show: true, progress: count});

        if (event === 'end') {
            emitter({show: false , url: "http://dummy-url"});
            clearInterval(interval);
        }
    }

    interval = setInterval(emitEvent, 1000);

    // Function to cancel execution
    function cancel() {
        clearInterval(interval);
        console.log('Execution canceled.');
    }

    // Return a cancellation function
    return cancel;
}

async function upload(emitter , file , type) {
    let doneUploading = false;
    if (file?.size <= 10485760) {

        const { presignedPost, url } = await fetchData.Event.UploadVideo({
            method: 'POST',
            body: JSON.stringify({
                create: true,
                videoType: type
            })
        });

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );

                if (percentCompleted < 100) {
                    emitter({show: true, progress: percentCompleted});
                }
            }
        };

        const formData = new FormData();
        Object.entries(presignedPost.fields).forEach(([k, v]) => {
            formData.append(k, v);
        });
        formData.append('file', file);
        await axios
            .post(`${presignedPost.url}`, formData, config)
            .then(() => {
                emitter({show: false});
            })
            .catch((error) => {
                emitter({error});
            });

        const finalUrl = await handleUploadFinish(url, type);

        emitter({show: false , url: finalUrl});
    } else {
        const uploaderOptions = {
            file,
            chunkSize: 10,
            threadsQuantity: 6,
            useTransferAcceleration: false
        };
        const uploader = new Uploader(uploaderOptions);

        let percentage = undefined;


        uploader
            .onProgress(async ({ percentage: newPercentage }) =>  {
                // to avoid the same percentage to be logged twice
                if (percentage === 100 && !doneUploading) {
                    const finalUrl = await handleUploadFinish(uploader.fileKey , type);
                    doneUploading = true;
                    emitter({show: false , url: finalUrl});
                }
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                    emitter({show: true, progress: percentage});
                }
            })
            .onError((error) => {
               emitter({error});
            });

        uploader.start();
    }
}

async function handleUploadFinish(url , type) {
    const signedOriginalUrl = await fetchData.Event.UploadVideo({
        method: 'POST',
        body: JSON.stringify({
            get: true,
            url: url,
            videoFileType: type
        })
    });
    return signedOriginalUrl.signedOriginalUrl[0];
}

const handleNewVideoMessages = function* handleNewVideoMessages({ socket }) {
    yield takeEvery(CONVERT_VIDEO, (action) => {
        convertVideo(socket, action.payload);
    });

    yield takeEvery(CONVERSION_STATUS, (action) => {
        conversionStatus(socket, action.payload);
    });

    yield takeEvery(UPLOAD_VIDEO, startUpload);
};

export default handleNewVideoMessages;
