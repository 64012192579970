import React, {useEffect, useState} from 'react';
// import PollOption from './PollOption';
import {useDispatch, useSelector} from 'react-redux';
import {SET_ACTIVE_WIDGET, SET_WIDGET_RESULT, VOTE_WIDGET} from '../../constants/actionTypes';
import styles from './widget.module.scss';
import sanity from '../../sanity';
import {useTranslation} from 'react-i18next';

export default function PollWidget(props) {
    const { t } = useTranslation();
    let selectedPhoto = '/images/selected.png';

    if (window.cordova) {
        selectedPhoto = 'images/selected.png';
    }
    const [options, setOptions] = useState(props.data.options);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const [selected, setSelected] = useState(null);
    let widgetResults = useSelector((state) => state.widget.widgetResults);

    const pickOption = (optionId) => {
        if (!selected && !widgetResults) {
            setSelected(optionId);
            const selectedOptions = [...options];
            let indexOfSelected = selectedOptions.findIndex((option) => option._key === optionId);
            selectedOptions[indexOfSelected].isSelected = true;
            setOptions(selectedOptions);
            dispatch({
                type: VOTE_WIDGET,
                payload: { optionId, widgetId: props.data.id, username: currentUser.data.nickname }
            });
        }
    };

    useEffect(() => {
        const optionsWithResults = [];
        let timer;
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (widgetResults) {
                option.resultCount = widgetResults?.vote_results[i]?.count;
                option.resultPercentage = widgetResults?.vote_results[i]?.percentage + '%';
                timer = setTimeout(() => {
                    dispatch({
                        type: SET_ACTIVE_WIDGET,
                        payload: null
                    });
                    dispatch({
                        type: SET_WIDGET_RESULT,
                        payload: null
                    });
                }, 20000);
            }
            optionsWithResults.push(option);
        }
        setOptions(optionsWithResults);
        if (timer) {
            return () => clearTimeout(timer);
        }
    }, [widgetResults]);

    return (
        <div>
            {options.map((option) => {
                return (
                    <div
                        className={`${
                            selected === option._key && !option.resultCount
                                ? `${styles.pollOptionSelected} ${styles.pollOption}`
                                : styles.pollOption
                        } ${
                            selected === option._key && option.resultCount
                                ? styles.pollOptionSelectedResult
                                : ''
                        }`}
                        onClick={() => pickOption(option._key)}
                        key={option._key}>
                        {option.photo && (
                            <img
                                className={styles.optionPhoto}
                                src={sanity
                                    .urlFor(option.photo)
                                    .width(46)
                                    .height(46)
                                    .auto('format')}
                                alt="Option Photo"
                            />
                        )}
                        <div className={styles.optionText}>{option.text}</div>
                        {option.resultPercentage && (
                            <React.Fragment>
                                <div
                                    style={{ width: option.resultPercentage }}
                                    className={styles.resultsBackground}
                                />
                                <div className={styles.pollResults}>
                                    <span className={styles.resultCount}>
                                        {option.resultCount} {t('PollCount')}
                                    </span>
                                    <span className={styles.resultPercentage}>
                                        {option.resultPercentage}
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                        {selected === option._key && !option.resultCount && (
                            <div className={styles.selectedIcon}>
                                <img src={selectedPhoto} alt={t('SelectedOption')} />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}
