export const UPLOADING_STATUS = "uploading";
export const CONVERT_STATUS = "convert";
export const CONVERTING_STATUS = "converting";
export const CONVERT_FINISHED_STATUS = "convert_finished"
export const NOT_PUBLISHED = "unpublished"
export const WIDGETS_WITH_RESULTS = [
    'poll-motm',
    'poll-vs',
    'poll-text',
    'poll-text-image',
    'quiz-text'
];

export const WIDGETS_FOR_PAST_EVENTS = [
    'poll-motm',
    'poll-vs',
    'poll-text',
    'poll-text-image',
    'sponsor',
    'quiz-multi-question'
];

export const MAX_INVITES = 4;
