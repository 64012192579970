import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './menu.module.scss';
import UserProfile from './UserProfile';
import { useSelector } from 'react-redux';
import sanity from '../../sanity';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { calculateBrightness, isColorSimilar } from '../../utils';
import { IonBackButton, IonButtons, IonHeader, IonToolbar } from '@ionic/react';

export default function TopMenu({ renderSearch, renderCompany, renderBack, renderProfile }) {
    const { t } = useTranslation();
    // const history = useHistory();
    const company = useSelector((state) => state.common.company);
    const user = useSelector((state) => state.user.currentUser);
    const [enabledInvitations, setEnabledInvitations] = useState(false);
    let backgroundColor = '#DBDDE0';
    let iconColor = '#000000';

    if (company && company.primaryColor) {
        if (user && user.data) {
            if (!user.data.picture) {
                const isSimilar = isColorSimilar(company.primaryColor, '#DBDDE0', 50);
                backgroundColor = isSimilar ? '#444444' : '#DBDDE0';
            }
        }

        const brightness = calculateBrightness(company?.primaryColor);
        iconColor = brightness < 128 ? '#ffffff' : '#000000';
    }

    return (
        <IonHeader className={styles.menuWrapper}>
            <IonToolbar color={company?.primaryColor}>
                <IonButtons slot="start">
                    {renderBack && (
                        <IonBackButton style={{ color: iconColor }} defaultHref="/events" text="" />
                    )}
                    {renderCompany && (
                        <Link to={'/events'}>
                            {company && company.company_logo_text && (
                                <img
                                    src={sanity.urlFor(company.company_logo_text.asset._ref)}
                                    alt={t('Logo')}
                                />
                            )}
                        </Link>
                    )}
                </IonButtons>

                <IonButtons slot="end">
                    {renderSearch && (
                        <Link to="/search">
                            <SearchIcon sx={{ color: iconColor, fontSize: 30 }} />
                        </Link>
                    )}
                    {renderProfile && (
                        <UserProfile
                            backgroundColor={backgroundColor}
                            className={styles.userProfile}
                        />
                    )}
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
}
