import {
    AuthenticationDetails,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession
} from 'amazon-cognito-identity-js';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';

const PASSWORD = 'Test123!';
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;

class CognitoService {
    async register(username) {
        try {
            await this._signup(username, PASSWORD);

            return this.authenticate(username);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    authenticate(username, phone_number) {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            AdditionalUsername: phone_number
        });

        // console.log(authenticationDetails);

        const userPool = this._getUserPool();
        this.cognitoUser = this._getCognitoUser(username, userPool);

        this.cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

        return new Promise((resolve, reject) => {
            this.cognitoUser.initiateAuth(authenticationDetails, {
                onFailure: (error) => {
                    reject(error);
                },
                customChallenge: () => {
                    resolve(true);
                },
                onSuccess: () => {
                    resolve(true);
                }
            });
        });
    }

    respondToChallengeSMS(smsCode) {
        if (smsCode) {
            return new Promise((resolve, reject) => {
                this.cognitoUser.sendCustomChallengeAnswer(smsCode, {
                    onSuccess: (result) => {
                        resolve(result);
                    },
                    onFailure: (error) => {
                        reject(error);
                    },
                    customChallenge: () => {
                        reject(new Error('Felaktig kod'));
                    }
                });
            });
        }
        return Promise.reject(new Error('No sms code'));
    }

    async updateUser(given_name, family_name, nickname) {
        this.cognitoUser = await this.getAuthenticatedUser();

        const attributeList = [
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: given_name
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: family_name
            }),
            new CognitoUserAttribute({
                Name: 'nickname',
                Value: nickname
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async setSignInUserSession(idToken, accessToken, refreshToken) {
        const IdToken = new CognitoIdToken({
            IdToken: idToken
        });
        const AccessToken = new CognitoAccessToken({
            AccessToken: accessToken
        });

        const RefreshToken = new CognitoRefreshToken({
            RefreshToken: refreshToken
        });

        const sessionData = {
            IdToken: IdToken,
            AccessToken: AccessToken,
            RefreshToken: RefreshToken
        };

        const session = new CognitoUserSession(sessionData);

        const userPool = this._getUserPool();
        this.cognitoUser = this._getCognitoUser(AccessToken.payload.username, userPool);
        this.cognitoUser.setSignInUserSession(session);
    }

    getAuthenticatedUser() {
        return new Promise((resolve, reject) => {
            const userPool = this._getUserPool();
            this.cognitoUser = userPool.getCurrentUser();
            this.cognitoUser.getSession((error) => {
                if (error) {
                    reject(error);
                }
                resolve(this.cognitoUser);
            });
        });
    }

    async addPicture(picture) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'picture',
                Value: picture
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async addEmail(email) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async addPhoneNumber(phone_number) {
        console.log(phone_number);
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'phone_number',
                Value: phone_number
            })
        ];

        console.log(attributeList);

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async updateDefaultVideoQuality(quality) {
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'custom:video_quality',
                Value: quality
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async setInvitesLeft(invites) {
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'custom:invites_left',
                Value: invites.toString()
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async addPrimaryAuthInfo(primary_auth) {
        // console.log('dodavam na cognito');
        // console.log(primary_auth);
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'custom:primary_auth',
                Value: primary_auth.toString()
            })
        ];

        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async setLogNumber(number) {
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'custom:log_counter',
                Value: number.toString()
            })
        ];
        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    async verifyPhoneNumber(value) {
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'phone_number_verified',
                Value: value.toString()
            })
        ];
        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }
    async verifyEmail(value) {
        this.cognitoUser = await this.getAuthenticatedUser();
        const attributeList = [
            new CognitoUserAttribute({
                Name: 'email_verified',
                Value: value.toString()
            })
        ];
        return new Promise((resolve, reject) => {
            this.cognitoUser.updateAttributes(attributeList, (err, res) => {
                if (err) reject(err);

                resolve(res);
            });
        });
    }

    getCurrentUser() {
        return this._getUserPool().getCurrentUser();
    }

    getUserData() {
        const currentUser = this.getCurrentUser();
        if (!this.cognitoUser) {
            this.cognitoUser = currentUser;
        }
        if (currentUser) {
            return new Promise((resolve, reject) => {
                currentUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }

                    currentUser.getUserAttributes((error, result) => {
                        if (error) reject(error);
                        const mappedData = {};
                        const attr = {};
                        if (result) {
                            for (const res of result) {
                                attr[res.getName()] = res.getValue();
                            }
                        }
                        mappedData['data'] = attr;
                        mappedData['token'] = session.getIdToken().getJwtToken();
                        mappedData['groups'] = session.getIdToken().payload['cognito:groups'];
                        mappedData['loaded'] = true;
                        // mappedData['completed'] = attr.nickname;
                        resolve(mappedData);
                    });
                });
            });
        }

        return Promise.resolve(null);
    }

    logoutUser() {
        if (this.getCurrentUser()) {
            return this.getCurrentUser().signOut();
        } else {
            console.log('No user to logout');
        }
    }

    getUserToken() {
        return new Promise((resolve, reject) => {
            const currentUser = this.getCurrentUser();
            if (currentUser) {
                currentUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session.getAccessToken().getJwtToken());
                    }
                });
            } else {
                resolve(null);
            }
        });
    }

    _getUserPool() {
        return new CognitoUserPool({
            UserPoolId: COGNITO_USER_POOL_ID,
            ClientId: COGNITO_CLIENT_ID
        });
    }

    _getCognitoUser(Username, Pool) {
        return new CognitoUser({ Username, Pool });
    }

    _signup(username, password) {
        const userPool = this._getUserPool();
        return new Promise((resolve, reject) => {
            userPool.signUp(
                username,
                password,
                [
                    {
                        Name: 'custom:primary_auth',
                        Value: 'email'
                    },
                    {
                        Name: 'custom:invites_left',
                        Value: '5'.toString()
                    },
                    {
                        Name: 'custom:video_quality',
                        Value: '999'.toString()
                    }
                ],
                [],
                (err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                }
            );
        });
    }
}

const COGNITO_SERVICE = new CognitoService();
export default COGNITO_SERVICE;
