import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import camera from '../../assets/images/photo-camera.svg';
import styles from './createVideo.module.scss';

const Thumbnails = ({
    imagePreview,
    setImagePreview,
    handleImageChange,
    fileInputRef,
    handleFileUploadClick
}) => {
    const { t } = useTranslation();
    return (
        <div style={{ display: 'flex' }}>
            {imagePreview ? (
                <div
                    style={{
                        marginRight: '10px',
                        position: 'relative',
                        width: '140px',
                        height: '90px'
                    }}>
                    <img
                        src={imagePreview}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            border: '1px solid gray',
                            borderRadius: '10px'
                        }}
                    />
                    <IconButton
                        onClick={() => setImagePreview(null)}
                        sx={{
                            background: 'lightgray',
                            padding: '3px',
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            '&:active, &:focus, &:hover': {
                                background: 'lightgray',
                                padding: '3px',
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px'
                            }
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ) : (
                <div
                    style={{
                        width: '140px',
                        height: '90px',
                        border: '1px solid gray',
                        borderRadius: '10%',
                        marginRight: '10px'
                    }}></div>
            )}
            <div className={styles.fileUpload} style={{ margin: '0' }}>
                <>
                    <input
                        type="file"
                        id="upload"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleImageChange}
                    />
                    <div
                        htmlFor="upload"
                        className={styles.uploadLabel}
                        style={{
                            width: '140px',
                            height: '90px',
                            margin: '0'
                        }}
                        onClick={handleFileUploadClick}>
                        <img src={camera} />
                        <Typography fontSize={'12px'}>{t('ChooseYourOwn')}</Typography>
                    </div>
                </>
            </div>
        </div>
    );
};

export default Thumbnails;
