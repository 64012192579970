import { IonContent, IonPage } from '@ionic/react';
import { goBack } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GET_BLOCKED_USERS, UNBLOCKING_USER } from '../../constants/actionTypes';
import OnBack from '../Auth/OnBack';
import styles from './settings.module.scss';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.data,
        blockedUsers: state.user.currentUser.blockedUsers
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onUnBlockingUser: (payload) => dispatch({ type: UNBLOCKING_USER, payload }),
    onLoadBlockedUsers: (payload) => dispatch({ type: GET_BLOCKED_USERS, payload })
});

const BlockedUsers = (props) => {
    const { t } = useTranslation();
    const onBack = () => {
        props.onGoBack();
    };

    useEffect(() => {
        props.onLoadBlockedUsers(props.user.sub);
    }, []);

    const unblockUser = (userBlock) => {
        props.onUnBlockingUser({
            user_id: userBlock.user_id,
            blocked_by: userBlock.blocked_by
        });
        setTimeout(() => {
            props.onLoadBlockedUsers(userBlock.blocked_by);
        }, 1000);
    };

    const getInitials = (userForUnblocking) => {
        let nickname = userForUnblocking.userName;

        return nickname.length === 1
            ? nickname[0].substring(0, 2)
            : nickname[0].charAt(0) + nickname[nickname.length - 1].charAt(0);
    };

    return (
        <IonPage>
            <OnBack defaultHref={'/profile/settings/secrecy'} title={t('BlockedUsers')} />
            <IonContent>
                <div className={styles.profileWrapper}>
                    <main>
                        <div
                            className={styles.settingsList}
                            style={{ background: 'white', border: 'none' }}>
                            {props.blockedUsers &&
                                props.blockedUsers.map((user, index) => {
                                    return (
                                        <div key={index} style={{ border: 'none' }}>
                                            <div>
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        fontWeight: 'normal'
                                                    }}>
                                                    {user.avatar ? (
                                                        <img
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                                borderRadius: '40px',
                                                                objectFit: 'cover'
                                                            }}
                                                            src={user.avatar}
                                                            alt="Block Icon"
                                                        />
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.profilePictureGenerated
                                                            }>
                                                            {getInitials(user)}
                                                        </div>
                                                    )}

                                                    <span style={{ margin: '8px', color: 'black' }}>
                                                        {user.user_to_block_nickname}
                                                    </span>
                                                </span>
                                            </div>
                                            <button
                                                className={styles.unblockBtn}
                                                style={{ width: '36%', margin: '0px 0px' }}
                                                onClick={() => unblockUser(user)}>
                                                {t('Unblock')}
                                            </button>
                                        </div>
                                    );
                                })}
                        </div>
                    </main>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedUsers);
