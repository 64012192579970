import React from 'react';
import styles from '../Customize/customize.module.scss';
import { IonContent, IonPage } from '@ionic/react';

const CancelPayment = (props) => {
    return (
        <IonPage>
            <IonContent>
                <div className={styles.profileWrapper} style={{ minHeight: '100%' }}>
                    CHECKOUT SESSION WAS CANCELED
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CancelPayment;
