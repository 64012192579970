import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import common from './reducers/common';
import event from './reducers/event';
import user from './reducers/user';
import prices from './reducers/prices';
import widget from './reducers/widget';
import blog from './reducers/blog';
import schedule from './reducers/schedule';
import appState from './reducers/appState';
import liveStreaming from './reducers/liveStreamingReducer';
import errors from './reducers/errors';

export default (history) =>
    combineReducers({
        common,
        event,
        user,
        widget,
        prices,
        blog,
        router: connectRouter(history),
        schedule,
        appState,
        liveStreaming,
        errors
    });
