import {CLEAR_ERROR, SET_ERROR} from '../constants/actionTypes';

const initialState = {
    message: ''
};

const errors = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR:
            return { message: action.payload };
        case CLEAR_ERROR:
            return initialState;
        default:
            return state;
    }
};

export default errors;
