import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {EVENT_PAGE_LOADED, EVENT_PAGE_UNLOADED} from '../../constants/actionTypes';
import styles from './streamingChat.module.scss';
import chatminimize from '../../assets/images/chatminimize.svg';
import {useTranslation} from 'react-i18next';

const mapStateToProps = (state) => {
    return {
        chatMessages: state.event.chatMessages
    };
};

const mapDispatchToProps = (dispatch) => ({
    onLoad: async (payload) => {
        dispatch({ type: EVENT_PAGE_LOADED, payload });
    },
    onUnload: () => {
        dispatch({ type: EVENT_PAGE_UNLOADED });
    }
});

function StreamingChat(props) {
    const { t } = useTranslation();
    const streamingEvent = props.streamingEvent;
    const messages = props.chatMessages;
    const messageRef = useRef();

    let image = '/images/logo.png';
    if (window.cordova) {
        image = 'images/logo.png';
    }

    useEffect(() => {
        if (streamingEvent) {
            props.onUnload();
            props.onLoad(streamingEvent);
        }
    }, [streamingEvent]);

    // useEffect(() => {
    //     props.onLoad({
    //         id: 'd740c6a7-8d04-46f7-b7d0-07221fc9d1ee',
    //         title: 'Träning 3 dagar innan cupfinal',
    //         event_type: 'training_event',
    //         slug: 'traening-3-dagar-innan-cupfinal',
    //         live_stream_url: 'https://d1ksekbk96k8yi.cloudfront.net/event-1622105075723/index.m3u8',
    //         stream_status: 'active',
    //         tags: ['A-Herr', 'Träning', 'Cupfinal'],
    //         thumbnail: {
    //             image_asset: {
    //                 _type: 'image',
    //                 asset: {
    //                     _ref: 'image-c0fe3d028bcf426f0992c1f64f5ffd4272dd8c1f-224x126-png',
    //                     _type: 'reference'
    //                 },
    //                 title: 'training4'
    //             }
    //         },
    //         sports_team: {
    //             _ref: '9edf323b-efaf-4986-bb9d-e7ec97edd291',
    //             _type: 'reference'
    //         },
    //         end_date: '2021-05-27T09:31:00.000Z',
    //         startDate: '2021-05-27T08:50:00.000Z',
    //         start_date: '2021-05-27T08:50:00.000Z',
    //         start_stream_time: '2021-06-01T07:58:30.000Z',
    //         pinned: true,
    //         channel_status: 'RUNNING',
    //         mediaLivePushEndpoint:
    //             'rtmp://event-1622105075723.fantdemo.com:1935/event-1622105075723/stream',
    //         channel_id: '8388000'
    //     });
    // }, []);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView({});
        }

        return () => {
            props.onUnload();
        };
    }, []);

    return (
        <div className={styles.chatOverlay}>
            <div className={styles.chatItems}>
                <span className={styles.title}>{t('ChatTitle')}</span>
                <button onClick={() => props.setChatOpen(false)}>
                    <img src={chatminimize} alt={t('Minimize')} />
                </button>
                <span className={styles.chatTitle}>{t('ChatOpen')}</span>
                <hr />
            </div>
            <div className={styles.chatContainer}>
                {messages.map((m, i) => {
                    return (
                        <div className={styles.messages} key={i} ref={messageRef}>
                            <img src={m.user.picture || image} alt={t('UserPhoto')} />
                            <span className={styles.msgNickname}>{m.user.nickname}</span>
                            <span className={styles.msgText}>{m.messageContent}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamingChat);
