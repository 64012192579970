import React from 'react';
import styles from './reportListItem.module.scss';
import arrowLeft from '../../assets/images/arrow-left.svg';
import {useTranslation} from 'react-i18next';

export default function ReportListItem(props) {
    const { t } = useTranslation();
    return (
        <div className={styles.inputOption}>
            <input
                value={props.reportTitle}
                type="button"
                onClick={() => props.addReport(props.reportTitle)}
            />
            <img src={arrowLeft} alt={t('OptionClick')} className={styles.optionIcon} />
        </div>
    );
}
