import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { SET_USER_DATA, SOCKET_CONNNECT, SPINNER_VISIBLE } from '../../constants/actionTypes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
//import { Auth } from 'aws-amplify';
import cognitoService from '../../core/service/cognito.service';
import api from '../../fetch';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onSocketConnect: () => dispatch({ type: SOCKET_CONNNECT })
});

const Welcome = (props) => {
    const { token } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchSession = async () => {
            const { session } = await api.Company.WelcomeSession(token);

            if (session) {
                try {
                    const { AuthenticationResult } = JSON.parse(session);
                    const {
                        IdToken: idToken,
                        AccessToken: accessToken,
                        RefreshToken: refreshToken
                    } = AuthenticationResult;
                    await cognitoService.setSignInUserSession(idToken, accessToken, refreshToken);
                } catch (e) {
                    //ignore
                }
            }
            const current = await cognitoService.getUserData();
            props.onSpinner(true);
            if (current) {
                console.info(current);
                props.onSetUserData(current);
                history.push('/user-info'); // Redirect to events page
                props.onSpinner(false);
            } else {
                history.push('/login'); // Redirect to login page if no token
                props.onSpinner(false);
            }
        };
        fetchSession();
    }, []);
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
