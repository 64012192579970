import React, {useEffect, useState} from 'react';
// import PollOption from './PollOption';
import {useDispatch, useSelector} from 'react-redux';
import {SET_ACTIVE_WIDGET, SET_WIDGET_RESULT, VOTE_WIDGET} from '../../constants/actionTypes';
import sanity from '../../sanity';
import styles from './widget.module.scss';

export default function PollWidget(props) {
    console.log(props);
    const [options, setOptions] = useState(props.data.options);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const [selected, setSelected] = useState(null);
    let widgetResults = useSelector((state) => state.widget.widgetResults);
    const pickOption = (optionId) => {
        if (!selected && !widgetResults) {
            setSelected(optionId);
            const selectedOptions = [...options];
            let indexOfSelected = selectedOptions.findIndex((option) => option._key === optionId);
            selectedOptions[indexOfSelected].isSelected = true;
            setOptions(selectedOptions);
            dispatch({
                type: VOTE_WIDGET,
                payload: { optionId, widgetId: props.data.id, username: currentUser.data.nickname }
            });
        }
    };

    useEffect(() => {
        console.log('widget results changeds', widgetResults);
        const optionsWithResults = [];
        let timer;
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            if (widgetResults) {
                option.resultCount = widgetResults?.vote_results[i]?.count;
                option.resultPercentage = widgetResults?.vote_results[i]?.percentage + '%';
                timer = setTimeout(() => {
                    dispatch({
                        type: SET_ACTIVE_WIDGET,
                        payload: null
                    });
                    dispatch({
                        type: SET_WIDGET_RESULT,
                        payload: null
                    });
                }, 20000);
            }
            optionsWithResults.push(option);
        }
        setOptions(optionsWithResults);
        console.log(options);
        if (timer) {
            return () => clearTimeout(timer);
        }
    }, [widgetResults]);
    return (
        <div className={styles.pollVS}>
            <div
                className={
                    options[0].isSelected ? styles.pollOptionSelectedVS : styles.pollOptionVS
                }>
                <img
                    onClick={() => pickOption(options[0]._key)}
                    key={options[0]._key}
                    src={sanity.urlFor(options[0].photo).width(120).height(120).auto('format')}
                    alt="Option Photo"
                />
                {(options[0].resultCount || options[0].resultCount === 0) && (
                    <div className={styles.pollResult}>
                        <span>{options[0].resultPercentage}</span>
                    </div>
                )}
            </div>
            <div className={styles.pollCenter}>VS</div>
            <div
                className={
                    options[1].isSelected ? styles.pollOptionSelectedVS : styles.pollOptionVS
                }>
                <img
                    onClick={() => pickOption(options[1]._key)}
                    key={options[1]._key}
                    src={sanity.urlFor(options[1].photo).width(120).height(120).auto('format')}
                    alt="Option Photo"
                />
                {(options[1].resultCount || options[1].resultCount === 0) && (
                    <div className={styles.pollResult}>
                        <span>{options[1].resultPercentage}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
