import React, {useRef, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import { isMobile } from 'react-device-detect';
import cameraflip from '../../assets/images/cameraflip-white.svg';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        ...state.liveStreaming
    };
};


const CameraPreview = ({ mediaDevices, facingMode, setCameraFacingMode , setVideoDevices  , setVideoSelectDevice}) => {
    const videoRef = useRef(null);
    function toggleCamera() {
        if (facingMode === 'user') {
            setCameraFacingMode('environment');
        } else if (facingMode === 'environment') {
            setCameraFacingMode('user');
        }
    }

    async function selectBestCamera() {
        try {
            await navigator.mediaDevices.getUserMedia({audio: true, video: true});
            const devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach((d) => console.info(`DEVICE ID ===>>>>> ${d.deviceId}`));
            let bestCameras = {
                front: null,
                back: null,
                desktop: null
            };

            await Promise.all(devices.map(async device => {
                if (device.kind === 'videoinput') { // Check if the device is a video input (camera)
                    console.log('Device: ' + device.label);

                    // Get the stream for the device
                    const stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: device.deviceId
                        }
                    });

                    // Get the track from the stream
                    const track = stream.getVideoTracks()[0];
                    // Get capabilities of the track
                    const capabilities = track.getCapabilities();
                    // Parse the facing mode from capabilities to identify if it's a front, back, or desktop camera
                    if (capabilities && capabilities.facingMode && capabilities.facingMode.length > 0) {
                        if (capabilities.facingMode.includes('user') && (!bestCameras.front || isBetterCamera(capabilities, bestCameras.front.capabilities))) {
                            bestCameras.front = { deviceId: device.deviceId, capabilities };
                        } else if (capabilities.facingMode.includes('environment') && (!bestCameras.back || isBetterCamera(capabilities, bestCameras.back.capabilities))) {
                            bestCameras.back = { deviceId: device.deviceId, capabilities };
                        }
                    } else {
                        if (!bestCameras.desktop || isBetterCamera(capabilities, bestCameras.desktop.capabilities)) {
                            bestCameras.desktop = { deviceId: device.deviceId, capabilities };
                        }
                    }

                    // Close the stream
                    stream.getTracks().forEach(track => track.stop());
                }
            }));

            // Log the selected best cameras based on environment
            // console.info('Best front camera:', bestCameras.front ? bestCameras.front.deviceId : 'No front camera found.');
            // console.info('Best back camera:', bestCameras.back ? bestCameras.back.deviceId : 'No back camera found.');
            // console.info('Best desktop camera:', bestCameras.desktop ? bestCameras.desktop.deviceId : 'No desktop camera found.');
            setVideoDevices(bestCameras);
        } catch (error) {
            console.error('Error enumerating devices:', error);
        }
    }
// Function to compare two cameras and determine if the new camera is better
    function isBetterCamera(newCapabilities, oldCapabilities) {
        // Define the weights for each parameter
        const weights = {
            resolution: 0.4,
            frameRate: 0.3,
            exposureMode: 0.2,
            focusMode: 0.1
            // Add more parameters and adjust weights as needed
        };

        // Calculate the grades for the new and old cameras
        const newGrade = newCapabilities ? calculateGrade(newCapabilities, weights) : 0;
        const oldGrade = oldCapabilities ? calculateGrade(oldCapabilities, weights) : 0;

        // Compare the grades and return true if the new camera has a higher grade
        return newGrade > oldGrade;
    }

// Function to calculate the grade for a camera based on its capabilities and weights
    function calculateGrade(capabilities, weights) {
        let grade = 0;

        // Calculate the grade for each parameter and sum them up
        grade += (capabilities.width.max || 0) * (capabilities.height.max || 0) * weights.resolution;
        grade += (capabilities?.frameRate.max || 0) * weights.frameRate;
        grade += (capabilities?.exposureMode?.includes('manual') ? 1 : 0) * weights.exposureMode;
        grade += (capabilities?.focusMode?.includes('manual') ? 1 : 0) * weights.focusMode;
        // Add more parameters and calculations as needed

        return grade;
    }

    useEffect(() => {
        // Call the function to select the best camera
        // console.info("ABOUT TO QUERY BEST CAMERAS =====================>");
        selectBestCamera();
        // console.info("FINISHED TO QUERY BEST CAMERAS =====================>");
    } , []);


    useEffect(() => {
        let videoRefValue = null;
        //console.info(`ABOUT TO QUERY THE MEDIA DEVICES WITH FACEMODE ${facingMode}` );
        const initCamera = async () => {
            try {
                const bestCameras = mediaDevices?.videoDevices;
                // console.info(`BEST CAMERAS` ,  JSON.stringify(bestCameras));

                let deviceId;
                // Select the camera based on facing mode
                if (facingMode === 'user' && bestCameras.front) {
                    deviceId = bestCameras.front.deviceId;
                    setVideoSelectDevice(bestCameras.front.deviceId);
                } else if (facingMode === 'environment' && bestCameras.back) {
                    deviceId = bestCameras.back.deviceId;
                    setVideoSelectDevice(bestCameras.back.deviceId);
                } else if (bestCameras.desktop) {
                    deviceId = bestCameras.desktop.deviceId;
                    setVideoSelectDevice(bestCameras.desktop.deviceId);
                }


                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: {exact: deviceId}
                    }
                });
                // console.info("ACTIVE_DEVICE SET:" , deviceId);
                setCameraFacingMode(facingMode);
                videoRef.current.srcObject = stream;
                videoRefValue = videoRef.current.srcObject;
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        };
        if (mediaDevices?.videoDevices) {
           initCamera();
        }
        // Cleanup function to stop the camera stream when the component is unmounted
        return () => {
            const stream = videoRefValue;
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [facingMode , mediaDevices]);

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                maxWidth: '260px',
                borderRadius: '10%',
                overflow: 'hidden'
            }}>
            <video
                style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                ref={videoRef}
                autoPlay
                playsInline
                muted
            />

            {isMobile && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => toggleCamera()}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 1,
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        minWidth: 0,
                        backgroundColor: 'white',
                        color: 'black'
                    }}>
                    <img src={cameraflip} alt="cameraflip" />
                </Button>
            )}
            <h1>Media Devices and Constraints</h1>
            <ul id="deviceList"></ul>
        </div>
    );
};

export default connect(mapStateToProps, null)(CameraPreview);
