import { Button, LinearProgress, Typography } from '@mui/material';
import EventThumbnail from './EventThumbnail';
import {
    CONVERTING_STATUS,
    CONVERT_FINISHED_STATUS,
    CONVERT_STATUS,
    UPLOADING_STATUS
} from '../../constants.js';
import styles from './eventInfo.module.scss';
import { useTranslation } from 'react-i18next';

const PublishingQueue = (props) => {
    const { t } = useTranslation();

    return (
        <div>
            {props.publishingEvents.map((e) => {
                return (
                    <div key={e.id} className={styles.publishing}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <EventThumbnail event={e} />
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <div>
                                    <Typography color={'#888 !important'}>
                                        {e.processingStatus === UPLOADING_STATUS
                                            ? t('Uploading')
                                            : e.processingStatus === CONVERT_STATUS
                                            ? t('Queued')
                                            : e.processingStatus === CONVERTING_STATUS
                                            ? t('Publishing')
                                            : e.processingStatus === CONVERT_FINISHED_STATUS
                                            ? `${t('Done')}!`
                                            : t('Unknown')}
                                    </Typography>
                                    <Typography color={'white !important'}>{e.title}</Typography>
                                </div>
                                {e.processingStatus === UPLOADING_STATUS && (
                                    <Button
                                        onClick={() => props.handleCancel(e)}
                                        sx={{
                                            border: 'none',
                                            color: 'white !important',
                                            textTransform: 'none !important'
                                        }}>
                                        {t('Cancel')}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <LinearProgress
                            size={160}
                            variant="determinate"
                            value={e.processing_progress}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default PublishingQueue;
