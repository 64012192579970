import React, { useState } from 'react';
import sanity from '../../../sanity';
//import moreInfo from '../../assets/images/actions.svg';
import styles from './previewScreen.module.scss';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    company: state.common.company
});

const PreviewScreen = (props) => {
    const [company, setCompany] = useState(props.company);
    let [logoText, setLogoText] = useState(
        props.companyLogoAndTextPreview
            ? props.companyLogoAndTextPreview
            : company && company.company_logo_text
            ? sanity.urlFor(company.company_logo_text?.asset)
            : null
    );
    //logoText = props.company.company_logo_text;
    //logoText = logoText.options.source;
    const [logoIcon, setLogoIcon] = useState(
        props.company && props.company.photo ? sanity.urlFor(props.company.photo?.asset) : null
    );

    const pinnedEvent = {
        id: 1,
        title: 'Pinned event title',
        date: '2022-10-10, 19:30',
        tags: ['pinned1', 'pinned 2'],
        img: 'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg'
    };

    const eventsList = [
        {
            id: 1,
            title: 'Event Title 1',
            date: '2022-10-10, 08:30',
            tags: ['tag1', 'tag2'],
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQogEIzUmssX1ZtzGYBJIRM4GPbBNtIq9eijgSp_I2ZFQ&s'
        },
        {
            id: 2,
            title: 'Event Title 2 test test',
            date: '2022-10-10, 17:00',
            tags: ['tag1'],
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQogEIzUmssX1ZtzGYBJIRM4GPbBNtIq9eijgSp_I2ZFQ&s'
        },
        {
            id: 3,
            title: 'Event Title 3',
            date: '2022-10-10, 19:30',
            tags: ['tag1', 'tag2'],
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQogEIzUmssX1ZtzGYBJIRM4GPbBNtIq9eijgSp_I2ZFQ&s'
        }
    ];
    return (
        <React.Fragment>
            <div
                style={{
                    backgroundColor: props.company.backgroundCompanyColor
                }}
                className={styles.navBar}>
                {logoText && <img src={logoText} alt="CompanyLogo" />}
                <span
                    style={{
                        background: props.company.secondaryColor
                    }}>
                    MK
                </span>
            </div>

            <img src={pinnedEvent.img} className={styles.pinnedEventImage} />
            <div className={styles.pinnedEventInfo}>
                <span
                    style={{
                        backgroundColor: props.company.primaryColor
                    }}
                    className={styles.logoIconBg}>
                    <img src={logoIcon} alt="Company Logo Icon" />
                </span>
                <div className={styles.eventDetails}>
                    <h3>{pinnedEvent.title}</h3>
                    <p>{pinnedEvent.date}</p>
                    <div className={styles.tags}>
                        {pinnedEvent.tags.map((tag) => {
                            return (
                                <span
                                    style={{
                                        backgroundColor: props.company.secondaryColor
                                    }}
                                    key={tag}>
                                    {tag}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
            <hr />
            <div className={styles.eventList}>
                {eventsList.map((event) => {
                    return (
                        <div
                            key={event.title}
                            style={{ display: 'flex', marginBottom: '10px' }}
                            className={styles.eventListItem}>
                            <div className={styles.eventItem}>
                                <img
                                    height="70px"
                                    width="100px"
                                    alt="Event's Thumbnail"
                                    src={event.img}
                                />
                                <div className={styles.eventInfo}>
                                    <h3>{event.title}</h3>
                                    <p>{event.date}</p>
                                    <div className={styles.tags}>
                                        {event.tags.map((tag) => {
                                            return (
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            props.company.secondaryColor
                                                    }}
                                                    key={tag}>
                                                    {tag}
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <img style={{ padding: '10px' }} src={moreInfo} />
                            </div> */}
                        </div>
                    );
                })}
                {(props.step == 4 || props.step == 5 || props.step == 6) && (
                    <div
                        className={styles.logoIconPreview}
                        style={{
                            backgroundColor: props.company.primaryColor
                        }}>
                        <img src={logoIcon} alt="Company Logo Icon" height="45px" width="45px" />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default connect(mapStateToProps)(PreviewScreen);
