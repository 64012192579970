import {SET_ERROR} from './constants/actionTypes';

const promiseMiddleware = (store) => (next) => (action) => {
    if (isPromise(action.payload)) {
        action.payload.then(
            (res) => {
                action.payload = res;
                store.dispatch(action);
            },
            (error) => {
                store.dispatch({ type: SET_ERROR, payload: error });
            }
        );

        return;
    }

    next(action);
};

function isPromise(v) {
    return v && typeof v.then === 'function';
}

export { promiseMiddleware };
