import sanityClient from '@sanity/client';
import Event from './core/entitites/event.entity';
import Blog from './core/entitites/blog.entity';
import fetch from './fetch';
import imageUrlBuilder from '@sanity/image-url';
import toast from 'react-hot-toast';

const dataset = process.env.REACT_APP_SANITY_DATASET_NAME;
const projectId = process.env.REACT_APP_SANITY_PROJECT_ID;
// const useCdn = process.env.NODE_ENV === 'production';
const useCdn = false;
const companyId = process.env.REACT_APP_COMPANY_ID;

if (!dataset) {
    throw new Error('SANITY:: no dataset');
}

if (!projectId) {
    throw new Error('SANITY:: no projectId');
}

if (!companyId) {
    throw new Error('SANITY:: no companyId');
}

const cli = sanityClient({ dataset, projectId, companyId, useCdn });

const urlFor = (source) => {
    const builder = imageUrlBuilder(cli);
    return builder.image(source);
};

const adminEventFields = `
    ...,
    _id,
    title,
    type,
    tags,
    'slug': slug.current,
    sports_team,
    article_type,
    event_type,
    stream_type,
    startDate,
    liveStreamUrl,
    streamStatus,
    widgets,
    sticky_widgets[] -> {..., 'widgetInstance': widget->},
    start_stream_time,
    pinned,
    'thumbnail': thumbnail ->{image_asset},
    pay_per_view_price,
    subscriber_exclusive,
    user_id,
    channel_id,
    channel_status,
    stream_arn,
    chat_room_arn,
    processing_progress,
    processingStatus,
    correlation_id,
    description
`;

const eventFields = `
    _id,
	title,
	type,
	tags,
	'slug': slug.current,
	sports_team,
	article_type,
	event_type,
    stream_type,
	startDate,
	liveStreamUrl,
	streamStatus,
	widgets,
	sticky_widgets[] -> {..., 'widgetInstance': widget->},
	start_stream_time,
	pinned,
	'thumbnail': thumbnail ->{image_asset},
    pay_per_view_price,
    subscriber_exclusive,
    channel_status,
    channel_id,
    user_id,
    endDate,
    stream_arn,
    chat_room_arn,
    processing_progress,
    processingStatus,
    correlation_id,
    description
`;
const blogFields = `
    _id,
	title,
	tags,
	'slug': slug.current,
	sports_team,
	blogText,
	blogDate,
    mainImage,
    secondImage,
    author
`;

async function fetchWithRetry(query, options, retries = 3) {
    try {
        const response = await cli.fetch(query, options);
        if (response.status >= 500) {
            if (retries > 0) {
                return fetchWithRetry(query, options, retries - 1);
            } else {
                toast.error('Max retries reached for this query', {
                    duration: 1500,
                    position: 'top-center'
                });
                throw new Error('Max retries reached for this query');
            }
        }
        return response;
    } catch (error) {
        if (retries > 0) {
            return fetchWithRetry(query, options, retries - 1);
        } else {
            toast.error(error.message, { duration: 1500, position: 'top-center' });
        }
    }
}

const Events = {
    GetEvents: async (admin = false) => {
        let fields = admin ? adminEventFields : eventFields;
        return fetchWithRetry(
            `*[_type == "event" && sports_team._ref == $companyId] | order(startDate desc) {${fields}}`,
            {
                companyId
            }
        ).then((res) => res.map((ev) => Event.new(ev)));
    },

    Search: async (searchQuery) => {
        return fetchWithRetry(
            `*[_type == "event" && sports_team._ref == $companyId && (stream_status != 'archived' && stream_status != 'upcoming') && (title match $searchQuery || description match $searchQuery || $tag in tags) ] | order(startDate desc) {${eventFields}}`,
            {
                companyId,
                searchQuery: `*${searchQuery}*`,
                tag: `${searchQuery}`
            }
        ).then((res) => res.map((ev) => Event.new(ev)));
    },

    Tags: async (tag) => {
        return fetchWithRetry(
            `*[_type == "event" && sports_team._ref == $companyId && (stream_status != 'archived' && stream_status != 'upcoming') && ($tag in tags) ] | order(startDate desc) {${eventFields}}`,
            {
                companyId,
                tag: `${tag}`
            }
        ).then((res) => res.map((ev) => Event.new(ev)));
    },

    GetEventById: async (eventId) => {
        return fetchWithRetry(`*[_type == "event" && _id == $eventId] {${eventFields}} [0]`, {
            eventId
        }).then((ev) => Event.new(ev));
    },

    GetEventBySlug: async (eventSlug) => {
        return fetchWithRetry(
            `*[_type == "event" && slug.current == $eventSlug] {${eventFields}} [0]`,
            {
                eventSlug
            }
        ).then((ev) => Event.new(ev));
    },

    GetLastPrices: async (admin = false) => {
        return fetchWithRetry(
            `*[_type == "event" && sports_team._ref == $companyId && pay_per_view_price != "" && pay_per_view_price != undefined] | order(startDate desc) {pay_per_view_price} [0...3]`,
            {
                companyId
            }
        );
        //.then((res) => res.map((ev) => Event.new(ev)));
    },

    MapUpdatedEvent: (ev) => {
        return Event.new(ev);
    },
    SetThumbnail: (eventId, thumbnailId) => {
        return cli
            .patch(eventId)
            .set({ thumbnail: { _ref: thumbnailId, _type: 'reference' } })
            .commit();
    }
};
const Widgets = {
    GetQuizById: async (quizId) => {
        return fetchWithRetry(`*[_type == "quiz" && _id == $quizId] {..., reward_widget ->} [0]`, {
            quizId
        });
    }
};

const BlogPosts = {
    GetBlogPosts: async (blogPosts) => {
        return fetchWithRetry(
            `*[_type == "blog_post" && sports_team._ref == $companyId] {${blogFields}} [0]`,
            {
                blogPosts,
                companyId
            }
        ).then((res) => res.map((bl) => Blog.new(bl)));
    },
    GetBlogPostBySlug: async (blogSlug) => {
        return fetchWithRetry(`*[_type == "blog_post" && slug.current == $blogSlug] [0]`, {
            blogSlug
        }).then((bl) => Blog.new(bl));
    },
    GetAllBlogPosts: async () => {
        return fetchWithRetry(`*[_type == "blog_post" && sports_team._ref == $companyId]`, {
            companyId
        });
    }
};

const Companies = {
    GetCompanyById: async (companyId) => {
        return fetchWithRetry(`*[_type == "sports_team" && _id == $companyId] [0]`, {
            companyId
        });
    },
    EditCompany: async (editCompanyData, editedCompanyData, user) => {
        const editCompanyOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                action: 'editCompanyDocument',
                editCompany: editCompanyData,
                editedCompanyData: editedCompanyData,
                loggedUser: user
            })
        };
        return await fetch.Company.EditCompanyDocument(editCompanyOptions);
    },
    InsertPhoto: async (imgObject) => {
        var res;

        await cli.assets
            .upload('image', imgObject.data, { filename: imgObject.photo.name })
            .then((doc) => {
                cli.create({
                    _type: 'img',
                    image_asset: {
                        _type: 'image',
                        asset: {
                            _type: 'reference',
                            _ref: doc._id
                        },
                        title: imgObject.photo.name
                    }
                });
                res = doc;
            })
            .catch((error) => {
                console.error('Upload failed:', error.message);
            });
        return res;
    }
};
const General = {
    GetPage: async (type, companyId) => {
        return fetchWithRetry(
            `*[_type == "page" && type == $type && sports_team._ref == $companyId] [0]`,
            {
                type,
                companyId
            }
        );
    }
};

const GlobalParams = {
    GetGlobalParams: async () => {
        let params = await fetchWithRetry(`*[_type == "global_parameters"] [0]`);
        return params;
    }
};

const Notifications = {
    FetchNotification: async (companyId) => {
        return await fetchWithRetry(
            `*[_type == "notification" && sports_team._ref == $companyId] [0]`,
            {
                companyId
            }
        );
    },
    SetPinned: (notificationId) => {
        return cli.patch(notificationId).set({ pinned: true }).commit();
    }
};

export default {
    Events,
    Widgets,
    urlFor,
    BlogPosts,
    Companies,
    General,
    GlobalParams,
    Notifications
};
