import React, { useEffect, useState } from 'react';
import styles from './countdownplayer.module.scss';
import logo from '../../assets/images/logo.png';
import icon from '../../assets/images/event-icon.svg';
import logoText from '../../assets/images/bajen-idag.svg';
import { calculateTimeLeft, zeroPadding } from '../../utils.js';
import { useTranslation } from 'react-i18next';
import placeholder from '../../assets/images/placeholder.png';


export default function CountdownPlayer(props) {
    const { t } = useTranslation();
    const isStopped = props.stopped;
    const isOpened = props.opened;
    const isExternalStream = props.externalStream;
    const startDate = new Date(props.event.start_date);
    const scheduledDate = new Date(startDate.getTime() + 10 * 60000).toISOString();
    // console.log('props.event.thumbnail: ', props.event.thumbnail);
    const [thumbnail, setThumbnail] = useState(null);

    const [timeLeft, setTimeLeft] = useState(
        isExternalStream
            ? calculateTimeLeft(scheduledDate)
            : calculateTimeLeft(props.event.start_date)
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(
                isExternalStream
                    ? calculateTimeLeft(scheduledDate)
                    : calculateTimeLeft(props.event.start_date)
            );
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    });
    useEffect(() => {
        const fetchThumbnail = async () => {
            setThumbnail(await props.event.getThumbnail());
        };
        fetchThumbnail();
    }, []);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const scheduledMatch = isExternalStream
        ? new Date(scheduledDate).getDate() +
          ' ' +
          months[new Date(scheduledDate).getMonth()] +
          ', ' +
          zeroPadding(new Date(scheduledDate).getHours().toString()) +
          ':' +
          zeroPadding(new Date(scheduledDate).getMinutes().toString())
        : new Date(props.event.start_date).getDate() +
          ' ' +
          months[new Date(props.event.start_date).getMonth()] +
          ', ' +
          zeroPadding(new Date(props.event.start_date).getHours().toString()) +
          ':' +
          zeroPadding(new Date(props.event.start_date).getMinutes().toString());

    return (
        <div
            className={styles.splash}
            style={{
                backgroundImage: isExternalStream
                    ? props.event.thumbnail
                        ? `url(${thumbnail})`
                        : `url(${placeholder})`
                    : `url(${placeholder})`
            }}>
            <div>
                {/* <img
                    src={props.event.thumbnail ? props.event.thumbnail.asset?._ref : placeholder}
                /> */}
                {/* <img src={logo} className={styles.imgLogo} /> */}
                {/* <img src={logoText} alt="Logo" className={styles.imgText} /> */}
            </div>

            {(isOpened || isExternalStream) && (
                <div className={styles.countDownTimer}>
                    <img src={icon} alt={t('LiveEvent')} />
                    {isStopped ? (
                        <div className={styles.countDownInfo}>
                            <p>{props.event.title}</p>
                            <p>{t('EventEnded')}</p>
                        </div>
                    ) : (
                        <>
                            <div className={styles.countDownInfo}>
                                <p>{t('EventWillStartSoon')}</p>
                                <p>{scheduledMatch}</p>
                            </div>
                            {timeLeft ? (
                                <p className={styles.timer}>
                                    {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:
                                    {timeLeft.seconds}
                                </p>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
