import styles from './confirm.module.scss';
import {useTranslation} from 'react-i18next';

export const Confirm = (props) => {
    const {
        successFunction,
        cancelFunction,
        title,
        subtitle = '',
        cancelButtonText,
        successButtonText
    } = props;
    const { t } = useTranslation();
    if (!successFunction || !cancelFunction || !title || !cancelButtonText || !successButtonText)
        throw new Error(t('MissingRequiredProperties'));

    const onConfirm = (buttonIndex) => (buttonIndex === 2 ? successFunction() : cancelFunction());

    return (
        <>
            {!window.cordova && (
                <div className={styles.confirmationBackground}>
                    <div className={styles.confirmWindow}>
                        <h3>{title} </h3>
                        <p>{subtitle}</p>

                        <div className={styles.buttons}>
                            <button
                                className={`${styles.confirmationBtn} ${styles.cancel}`}
                                style={{ borderRight: '1px solid rgba(60, 60, 67, 0.36)' }}
                                onClick={() => cancelFunction()}>
                                {cancelButtonText}
                            </button>
                            <button
                                className={`${styles.confirmationBtn} ${styles.confirm}`}
                                onClick={() => successFunction()}>
                                {successButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {window.cordova &&
                (device.platform === 'iOS' || device.platform === 'Android') &&
                navigator.notification.confirm(`${subtitle}`, onConfirm, `${title}`, [
                    `${cancelButtonText}`,
                    `${successButtonText}`
                ])}
        </>
    );
};
