import Message from '../entitites/message.entity';

class ChatService {
    constructor() {
        this.messages = [];
        this.messageCounter = 0;
    }

    async SendMessage(message) {
        this.messageCounter += 1;
        this.messages.push(message);
    }

    async RefreshMessages() {
        return this.chatRepository.RefreshMessages();
    }

    MapMessages(messageData) {
        const messages = [];
        for (let i = 0; i < messageData.length; i++) {
            const message = messageData[i];
            const messageType = message.payload?.Attributes?.message_type || 'MESSAGE';
            if (messageType === 'MESSAGE') {
                const{ Content , Id , SendTime , Type , Sender : { UserId , Attributes: {avatar , username}}} = message.payload;
                const mappedMessage = new Message(
                    username || UserId,
                    UserId,
                    new Date(SendTime),
                    Content,
                    Id,
                    Type,
                    Id,
                    avatar
                );
                messages.push(mappedMessage);
            }

        }
        return messages;
    }

    MapMessage(message) {
        const{ Content , Id , SendTime , Type , Sender : { UserId , Attributes: {avatar , username}}} = message.payload;
        return new Message(
            username || UserId,
            UserId,
            new Date(SendTime),
            Content,
            Id,
            Type,
            Id,
            avatar
        );
    }
}

const CHAT_SERVICE = new ChatService();

export default CHAT_SERVICE;
