import {
    BLOCKING_USER,
    SET_BLOCKED_USERS,
    SET_USER_DATA,
    UNBLOCKING_USER,
    SET_FOLLOWERS,
    SET_USER_GROUP,
    REFETCH_USER_DATA
} from '../constants/actionTypes';

export default (
    state = {
        refetchUser: false,
        currentUser: { data: null, loaded: false, token: null, blockedUsers: [], followers: [] }
    },
    action
) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.payload
                }
            };
        case BLOCKING_USER: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    blockedUsers: [...state.currentUser.blockedUsers, action.payload]
                }
            };
        }
        case UNBLOCKING_USER: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    blockedUsers: [...state.currentUser.blockedUsers]
                }
            };
        }
        case SET_BLOCKED_USERS: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    blockedUsers: [...action.payload]
                }
            };
        }
        case SET_FOLLOWERS: {
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    followers: [...action.payload]
                }
            };
        }

        case SET_USER_GROUP: {
            console.info(action.payload);
            let _followers = state.currentUser.followers;
            if (_followers.find((x) => x.username === action.payload.user)) {
                _followers = _followers.map((x) => {
                    if (x.username === action.payload.user) {
                        x.attributes[action.payload.group.toLowerCase()] =
                            action.payload.action !== 'remove';
                    }
                    return x;
                });
            }
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    followers: [..._followers]
                }
            };
        }

        case REFETCH_USER_DATA: {
            return {
                ...state,
                refetchUser: true
            };
        }

        default:
            return state;
    }
};
