export default class ApiResponse {
    constructor(props) {
        this.success = props.success;
        this.error = props.error;
        this.data = props.data;
    }

    static new(props) {
        return new ApiResponse(props);
    }
}
