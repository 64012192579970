import OnBack from '../Auth/OnBack';
import styles from './settings.module.scss';
// import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { IonContent, IonPage } from '@ionic/react';
import { goBack } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser.data,
        company: state.common.company,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin')
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack())
});

const Secrecy = (props) => {
    const { t } = useTranslation();
    let images = '/images';

    if (window.cordova) {
        images = 'images';
    }

    return (
        <IonPage>
            <OnBack defaultHref={'/profile/settings'} title={t('Secrecy')} />
            <IonContent>
                <div className={styles.profileWrapper}>
                    <main>
                        <div
                            className={styles.settingsList}
                            style={{ background: 'white', border: 'none' }}>
                            <Link to="/profile/settings/secrecy/blocked-users">
                                <div>
                                    <span
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                        <svg
                                            width="28"
                                            height="29"
                                            viewBox="0 0 28 29"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14 24.6709C19.799 24.6709 24.5 19.9699 24.5 14.1709C24.5 8.37191 19.799 3.6709 14 3.6709C8.20101 3.6709 3.5 8.37191 3.5 14.1709C3.5 19.9699 8.20101 24.6709 14 24.6709Z"
                                                stroke={'black'}
                                                strokeWidth="1.75"
                                                strokeMiterlimit="10"
                                            />
                                            <path
                                                d="M6.57349 6.74432L21.4266 21.5974"
                                                stroke={'black'}
                                                strokeWidth="1.75"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                        <span style={{ margin: '10px', color: 'black' }}>
                                            {t('BlockedUsers')}
                                        </span>
                                    </span>
                                </div>
                                <img src={`${images}/right_arrow.svg`} alt="Right Arrow" />
                            </Link>
                        </div>
                    </main>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Secrecy);
