import {SET_ACTIVE_WIDGET, SET_PAST_WIDGETS, SET_WIDGET_PAST_EVENT, SET_WIDGET_RESULT} from '../constants/actionTypes';
import {WIDGETS_FOR_PAST_EVENTS, WIDGETS_WITH_RESULTS} from '../constants';

export default (
    state = { currentWidget: null, widgetResults: null, pastWidgets: [], nextWidgets: [] },
    action
) => {
    switch (action.type) {
        case SET_ACTIVE_WIDGET: {
            return {
                ...state,
                currentWidget: action.payload
            };
        }
        case SET_WIDGET_RESULT:
            if (!action.payload) {
                //? close of current
                if (state.nextWidgets.length > 0) {
                    let nextWidgetInQueue = state.nextWidgets.find(
                        (x) => x.beginDelay && x.beforeEndSwitch == false
                    );

                    if (!nextWidgetInQueue) {
                        const future = '2099-00-00T00:00';
                        nextWidgetInQueue = state.nextWidgets
                            .sort(
                                (a, b) =>
                                    new Date(a.beginAt ?? future).getTime() -
                                    new Date(b.beginAt ?? future).getTime()
                            )
                            .reverse()
                            .pop();
                    }

                    if (nextWidgetInQueue.beginAt) {
                        if (new Date(nextWidgetInQueue.beginAt) < new Date()) {
                            nextWidgetInQueue.beginAt = new Date(new Date().getSeconds() + 5);
                        }
                    }

                    return {
                        ...state,
                        currentWidget: nextWidgetInQueue,
                        nextWidgets: state.nextWidgets.filter(
                            (x) => x && x?._id != nextWidgetInQueue?._id
                        ),
                        pastWidgets: [...state.pastWidgets, state.currentWidget],
                        widgetResults: null
                    };
                }

                return {
                    ...state,
                    widgetResults: null,
                    currentWidget: null,
                    pastWidgets: [...state.pastWidgets, state.currentWidget]
                };
            } else if (action.payload && WIDGETS_WITH_RESULTS.includes(action.payload.type)) {
                if (state.currentWidget) {
                    return {
                        ...state,
                        widgetResults: action.payload
                    };
                } else {
                    return {
                        ...state,
                        currentWidget: action.payload,
                        widgetResults: action.payload
                    };
                }
            } else return { ...state };
        case SET_WIDGET_PAST_EVENT: {
            if (action.payload && WIDGETS_FOR_PAST_EVENTS.includes(action.payload.type)) {
                if (state.currentWidget) {
                    //? if current is currently on

                    return {
                        ...state,
                        nextWidgets: [...state.nextWidgets, action.payload]
                    };
                }

                return {
                    ...state,
                    currentWidget: action.payload
                };
            } else {
                return { ...state };
            }
        }
        case SET_PAST_WIDGETS: {
            const currentDateInUtc = new Date();
            const shouldBeNext = action.payload.filter(
                (x) => new Date(x?.beginAt ?? '2000-00-00T00:00') > currentDateInUtc
            );

            return {
                ...state,
                pastWidgets: action.payload.filter((widget) =>
                    WIDGETS_FOR_PAST_EVENTS.includes(widget.type)
                ),
                nextWidgets: [...state.nextWidgets, ...shouldBeNext]
            };
        }
        default:
            return state;
    }
};
