import {
    CONNECT_PRICE_CHANGE,
    CONNECTED_PRICES_LOAD_FINISHED,
    PLATFORM_PRICES_LOAD_FINISHED
} from '../constants/actionTypes';

export default (state = { platform_prices: [] , connected_prices: [] }, action) => {
    switch (action.type) {
        case PLATFORM_PRICES_LOAD_FINISHED: {
            let platform_prices = [];
            platform_prices = action.payload;
            return {
                ...state,
                platform_prices
            };
        }
        case CONNECTED_PRICES_LOAD_FINISHED: {
            let connected_prices = [];
            connected_prices = action.payload;
            return {
                ...state,
                connected_prices
            };
        }
        case CONNECT_PRICE_CHANGE: {
            let _prices = state.connected_prices;
            if (_prices.find((x) => x.product === action.payload.product)) {
                _prices = _prices.map((x) => {
                    if ( x.product === action.payload.product) {
                        return action.payload
                    }
                    return x;
                });
            } else {
                _prices = [..._prices ,action.payload]
            }
            return {
                ...state,
                connected_prices: _prices
            };
        }
        default:
            return state;
    }
};
