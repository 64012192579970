import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IonBackButton, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography
} from '@mui/material';
import searchIcon from '../../assets/images/search-icon.svg';
import sanity from '../../sanity';
import EventListItem from '../Event/EventListItem';
import styles from './search.module.scss';

function useDebounce(value, delay, initialValue) {
    const [state, setState] = useState(initialValue);

    useEffect(() => {
        const timer = setTimeout(() => setState(value), delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return state;
}

const Search = () => {
    const [items, setItems] = useState([]);
    const [searchMode, setSearchMode] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedValue = useDebounce(searchQuery, 1000) || '';
    const [searchHistory, setSearchHistory] = useState(() => {
        const storedHistory = localStorage.getItem('searchHistory');
        return storedHistory ? JSON.parse(storedHistory) : [];
    });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tag = queryParams.get('tag');

    useEffect(() => {
        setSearchMode(false);
    }, []);

    useEffect(() => {
        async function fetchData() {
            const events = await sanity.Events.Search(tag);
            const filtered = events.filter((event) => event.stream_status !== 'unpublished');

            setItems(filtered);
            setSearchMode(true);
        }
        tag && fetchData();
    }, [tag]);

    useEffect(() => {
        async function fetchData() {
            const events = await sanity.Events.Search(debouncedValue);
            const filtered = events.filter((event) => event.stream_status !== 'unpublished');

            setItems(filtered);
        }
        if (debouncedValue) {
            fetchData();

            if (debouncedValue.trim() === '') return;
            if (!searchHistory.includes(debouncedValue)) {
                const newSearchHistory = [...searchHistory, debouncedValue];
                setSearchHistory(newSearchHistory);
                localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
            }
        }
    }, [debouncedValue]);

    const removeItem = (index) => {
        const newSearchHistory = searchHistory.filter((_, i) => i !== index);
        setSearchHistory(newSearchHistory);
        localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div className={styles.header}>
                        <IonBackButton text="" />
                        {tag ? (
                            <div>
                                <p className={styles.title}>{tag}</p>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <FormControl fullWidth>
                                    <OutlinedInput
                                        sx={{
                                            paddingRight: '0',
                                            borderColor: 'transparent',
                                            backgroundColor: '#E4E4E4',
                                            '& fieldset': { border: 'none' }
                                        }}
                                        id="outlined-adornment-amount"
                                        placeholder="Search"
                                        size="small"
                                        value={searchQuery}
                                        onClick={() => setSearchMode(true)}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={searchIcon} />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            searchQuery && (
                                                <IconButton
                                                    sx={{ color: '#ccc' }}
                                                    onClick={() => {
                                                        setSearchMode(true);
                                                        setSearchQuery('');
                                                    }}>
                                                    <CancelIcon />
                                                </IconButton>
                                            )
                                        }
                                    />
                                </FormControl>
                                {searchMode && (
                                    <Button
                                        sx={{ color: '#666', textTransform: 'capitalize' }}
                                        onClick={() => {
                                            setSearchMode(false);
                                            setSearchQuery('');
                                        }}>
                                        Cancel
                                    </Button>
                                )}
                            </div>
                        )}
                        <div></div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {!searchMode ? (
                    <>
                        <h3 className={styles.searchLabel}>{`Recent searches`}</h3>
                        <div className={styles.divider} />

                        <ul>
                            {searchHistory.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                    <div
                                        onClick={() => {
                                            setSearchQuery(item);
                                            setSearchMode(true);
                                        }}>
                                        <Typography>{item}</Typography>
                                    </div>
                                    <IconButton
                                        sx={{ color: '#ccc' }}
                                        onClick={() => removeItem(index)}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </ul>
                    </>
                ) : (
                    <div>
                        <h3 className={styles.searchLabel}>
                            {items.length ? `Search results (${items.length})` : `Search results`}
                        </h3>
                        <div className={styles.divider} />
                        {items.map((item) => {
                            return <EventListItem key={item.id} event={item} />;
                        })}
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default Search;
