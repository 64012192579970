import {takeEvery} from 'redux-saga/effects';
import {
    REPORT_MESSAGE,
    REPORT_MESSAGE_CLEAR,
    BLOCKING_USER, UNBLOCKING_USER, GET_BLOCKED_USERS
} from '../constants/actionTypes';

let isPublicUser = true;

const blockingUser = (socket, payload) => {
    if (isPublicUser) return;
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => blockingUser(socket, payload), 300);
    } else {
         socket.Client.send(
            JSON.stringify({
                action: 'block_user',
                user_id: payload.user_id,
                blocked_by: payload.blocked_by,
                username: payload.userName,
                avatar: payload.avatar,
                ttl : payload.ttl
            })
        );
    }
};

const unBlockingUser = (socket, payload) => {
    if (isPublicUser) return;
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => unBlockingUser(socket, payload), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'block_user',
                type: 'remove',
                user_id: payload.user_id,
                blocked_by: payload.blocked_by
            })
        );
    }
};

const getBlockedUsers = (socket, payload) => {
    if (isPublicUser) return;
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => getBlockedUsers(socket, payload), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'list_blocked_users',
                blocked_by: payload
            })
        );
    }
};

const reportMessage = (socket, body) => {
    if (isPublicUser) return;
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => reportMessage(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'reportMessage',
                ...body
            })
        );
    }
};

const reportMessageClear = (socket, body) => {
    if (isPublicUser) return;
    if (!socket.Client || socket.Client.readyState !== WebSocket.OPEN) {
        setTimeout(() => reportMessage(socket, body), 300);
    } else {
        socket.Client.send(
            JSON.stringify({
                action: 'reportMessageClear',
                ...body
            })
        );
    }
};



const handleNewMessage = function* handleNewMessage({ socket, createdStore }) {
    createdStore.subscribe(() => {
        isPublicUser = createdStore.getState()?.user?.currentUser?.token === null;
    });

    yield takeEvery(BLOCKING_USER, (action) => {
        blockingUser(socket, action.payload);
    });

    yield takeEvery(UNBLOCKING_USER, (action) => {
        unBlockingUser(socket, action.payload);
    });

    yield takeEvery(REPORT_MESSAGE, (action) => {
        reportMessage(socket, action.payload);
    });

    yield takeEvery(REPORT_MESSAGE_CLEAR, (action) => {
        reportMessageClear(socket, action.payload);
    });

    yield takeEvery(GET_BLOCKED_USERS, (action) => {
        getBlockedUsers(socket, action.payload);
    });


};

export default handleNewMessage;
