import { IonContent, IonPage, useIonToast } from '@ionic/react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IosShare from '@mui/icons-material/IosShare';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Drawer,
    Modal,
    Typography
} from '@mui/material';
import { goBack } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
    CONVERTING_STATUS,
    CONVERT_STATUS,
    NOT_PUBLISHED,
    UPLOADING_STATUS
} from '../../constants';
import { GET_FOLLOWERS, SPINNER_VISIBLE } from '../../constants/actionTypes';
import fetchData from '../../fetch';
import sanity from '../../sanity';
import OnBack from '../Auth/OnBack';
import EventListItem from '../Event/EventListItem';
import styles from '../Profile/Customize/customize.module.scss';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';

const mapStateToProps = (state) => ({
    events: state.event.events,
    upload: state.common.upload,
    followers: state.user.currentUser.followers
});

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onLoadFollowers: (payload) => dispatch({ type: GET_FOLLOWERS, payload })
});

const MyVideos = (props) => {
    const [videos, setVideos] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [clickedVideoId, setClickedVideoId] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const [present] = useIonToast();
    const presentToast = (message, duration, color) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: color === 'success' ? checkmarkCircleSharp : closeCircleSharp,
            color: 'success'
        });
    };

    useEffect(() => {
        props.onLoadFollowers({ company_id: process.env.REACT_APP_COMPANY_ID });
    }, []);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                props.onSpinner(true);
                let events = await sanity.Events.GetEvents(true);

                const publishingEvents = events
                    .filter(
                        (event) =>
                            event.processingStatus === CONVERTING_STATUS ||
                            event.processingStatus === UPLOADING_STATUS ||
                            event.processingStatus === CONVERT_STATUS
                    )
                    .map((e) => {
                        const runtimeProps = props.upload.find(
                            (uploadedEvent) => uploadedEvent.jobId === e.correlation_id
                        );
                        return runtimeProps ||
                            e.processingStatus === CONVERTING_STATUS ||
                            e.processingStatus === CONVERT_STATUS
                            ? Event.new({ ...e, ...runtimeProps })
                            : null;
                    })
                    .filter((e) => e !== null);
                const eventList = events.filter(
                    (e) =>
                        !publishingEvents.some((p) => p.id === e.id) &&
                        e.stream_status !== NOT_PUBLISHED
                );
                console.info(eventList);
                setVideos(eventList);
                // setPublishingEvents([...publishingEvents, ...finishedEvents]);
                // if (finishedEvents.length > 0) {
                //     props.onRemoveUpload(finishedEvents);
                // }
            } catch (error) {
                console.log(error.message);
            } finally {
                props.onSpinner(false);
            }
        };
        fetchVideos();
    }, [props.events, props.upload]);

    const deleteEvent = async (id) => {
        setLoading(true);
        await fetchData.Event.DeleteEvent(id);
        setLoading(false);
        setOpenModal(false);
        setOpenDrawer(false);
    };

    const shareContent = () => {
        if (!clickedVideoId) return;
        const url = `${window.location.origin}/share/event/${clickedVideoId}`;
        if (navigator.share) {
            navigator
                .share({
                    url: url
                })
                .then(() => {
                    console.log('Content shared successfully');
                })
                .catch((error) => {
                    console.error('Error sharing content:', error);
                });
        } else {
            navigator.clipboard.writeText(url);
            setOpenDrawer(false);
            presentToast('Event link copied to clipboard.', '2000', 'success');
            console.warn('Web Share API is not supported in this browser');
        }
    };

    return (
        <IonPage>
            <OnBack defaultHref={'/profile'} title={t('MyVideos')} />
            <IonContent>
                <div className={styles.myVideosWrapper}>
                    <div className={styles.header} style={{ padding: '5% 12px 0%' }}></div>
                    <div style={{ padding: '2vh 2vw 0vh' }}>
                        <>
                            <div>
                                <div
                                    className={styles.flexBetween}
                                    style={{
                                        marginBottom: '2vh'
                                    }}>
                                    <Link
                                        to={'/profile/myvideos/followers'}
                                        style={{
                                            flex: 1,
                                            textAlign: 'center',
                                            borderRight: '1px solid #888'
                                        }}>
                                        <Typography
                                            textAlign={'center'}
                                            fontSize={'30px'}
                                            fontFamily="Urbanist-Bold">
                                            {props.followers.length}
                                        </Typography>
                                        <Typography
                                            color={'#888 !important'}
                                            textAlign={'center'}
                                            fontSize={'14px'}>
                                            {t('Followers')}
                                        </Typography>
                                    </Link>
                                    {/* <div
                                        style={{
                                            flex: 1,
                                            textAlign: 'center',
                                            borderLeft: '1px solid #888',
                                            borderRight: '1px solid #888',
                                            padding: '0 4vw'
                                        }}>
                                        <Typography
                                            textAlign={'center'}
                                            fontSize={'30px'}
                                            fontFamily="Urbanist-Bold">
                                            111
                                        </Typography>
                                        <Typography
                                            color={'#888 !important'}
                                            textAlign={'center'}
                                            fontSize={'14px'}>
                                            {t('Views')}
                                        </Typography>
                                    </div> */}
                                    <div
                                        style={{
                                            flex: 1,
                                            textAlign: 'center',
                                            borderLeft: '1px solid #888'
                                        }}>
                                        <Typography
                                            textAlign={'center'}
                                            fontSize={'30px'}
                                            fontFamily="Urbanist-Bold">
                                            {videos.length > 0 ? videos.length : 0}
                                        </Typography>
                                        <Typography
                                            color={'#888 !important'}
                                            textAlign={'center'}
                                            fontSize={'14px'}>
                                            {t('Videos')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>

                            <Drawer
                                anchor="bottom"
                                open={openDrawer}
                                onClose={() => setOpenDrawer(false)}>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            position: 'fixed',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            zIndex: 999,
                                            borderRadius: '8px 8px 0px 0px'
                                        }}>
                                        <div>
                                            <div
                                                className={styles.button}
                                                onClick={() => shareContent()}
                                                style={{
                                                    display: 'flex',
                                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                                    alignItems: 'center'
                                                }}>
                                                <IosShare
                                                    // onClick={() =>
                                                    //     history.push(`/report/${eventOptionsProps.eventId}`)
                                                    // }
                                                    sx={{
                                                        width: 26,
                                                        height: 26,
                                                        marginRight: '1%'
                                                    }}
                                                />
                                                <Typography sx={{ fontFamily: 'Urbanist-Bold' }}>
                                                    {t('ShareVideo')}
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    margin: '0 auto',
                                                    width: isMobile ? '92%' : '97%',
                                                    borderBottom: '1px solid #0000001A'
                                                }}></div>
                                            <div
                                                className={styles.button}
                                                onClick={() => {
                                                    if (clickedVideoId) {
                                                        setOpenDrawer(false);
                                                        history.push(
                                                            `/events/${clickedVideoId}/modify`
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                                    alignItems: 'center'
                                                }}>
                                                <EditOutlinedIcon
                                                    sx={{
                                                        width: 26,
                                                        height: 26,
                                                        marginRight: '1%'
                                                    }}
                                                />
                                                <Typography sx={{ fontFamily: 'Urbanist-Bold' }}>
                                                    {t('EditVideo')}
                                                </Typography>
                                            </div>
                                            <div
                                                style={{
                                                    margin: '0 auto',
                                                    width: isMobile ? '92%' : '97%',
                                                    borderBottom: '1px solid #0000001A'
                                                }}></div>

                                            <div
                                                style={{
                                                    margin: '0 auto',
                                                    width: isMobile ? '92%' : '97%',
                                                    borderBottom: '1px solid #0000001A'
                                                }}></div>
                                            <div
                                                className={styles.button}
                                                style={{
                                                    display: 'flex',
                                                    padding: isMobile ? '5% 2%' : '2% 1%',
                                                    alignItems: 'center'
                                                }}
                                                onClick={() => {
                                                    if (clickedVideoId) {
                                                        setOpenModal(true);
                                                    }
                                                }}>
                                                <DeleteOutlineOutlinedIcon
                                                    sx={{ color: 'red', marginRight: '1%' }}
                                                />
                                                <Typography
                                                    sx={{
                                                        color: 'red',
                                                        fontFamily: 'Urbanist-Bold'
                                                    }}
                                                    fontWeight={'bold'}>
                                                    {t('DeleteVideo')}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Drawer>
                            <Modal open={openModal}>
                                <div className={isMobile ? styles.modalMobile : styles.modal}>
                                    <Typography
                                        marginTop={isMobile ? '3%' : '-1%'}
                                        fontSize={'18px'}
                                        textAlign={'center'}>
                                        {t('DeleteVideoQuestion')}
                                    </Typography>
                                    <div className={styles.flexContainer}>
                                        {!loading ? (
                                            <>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.confirmButtonMobile
                                                            : styles.confirmButton
                                                    }
                                                    onClick={() => {
                                                        if (clickedVideoId) {
                                                            deleteEvent(clickedVideoId);
                                                        }
                                                        setOpenModal(false);
                                                    }}>
                                                    {t('Yes')}
                                                </Button>
                                                <Button
                                                    className={
                                                        isMobile
                                                            ? styles.cancelButtonMobile
                                                            : styles.cancelButton
                                                    }
                                                    onClick={() => {
                                                        setOpenModal(false);
                                                    }}>
                                                    {t('Close')}
                                                </Button>
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    margin: '0 auto'
                                                }}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Modal>
                        </>
                    </div>
                    {videos.length > 0 && (
                        <Accordion defaultExpanded sx={{ marginTop: '8%' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography>
                                    {t('Videos')} ({videos.length})
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: '0 !important' }}>
                                {videos.map((video) => {
                                    return (
                                        <EventListItem
                                            key={video.id}
                                            event={video}
                                            setOpenDrawer={setOpenDrawer}
                                            setClickedVideoId={setClickedVideoId}
                                        />
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(MyVideos);
