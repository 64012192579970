import {useTranslation} from 'react-i18next';

export const EventTypes = () => {
    const { t } = useTranslation();
    return [
        { title: t('VideoEvent'), value: 'video_event' },
        { title: t('MatchEvent'), value: 'match_event' },
        { title: t('LiveMatchEvent'), value: 'live_match_event' },
        { title: t('TrainingEvent'), value: 'training_event' },
        { title: t('LiveTrainingEvent'), value: 'live_training_event' },
        { title: t('SinglePersonInterviewEvent'), value: 'single_person_interview_event' },
        { title: t('LiveSinglePersonInterviewEvent'), value: 'live_single_person_interview_event' },
        { title: t('MultiPersonInterviewEvent'), value: 'multi_person_interview_event' },
        { title: t('LiveMultiPersonInterviewEvent'), value: 'live_multi_person_interview_event' },
        { title: t('SupporterEvent'), value: 'supporter_event' },
        { title: t('SupporterLiveEvent'), value: 'supporter_live_event' },
        { title: t('PlayerEvent'), value: 'player_event' },
        { title: t('PlayerLiveEvent'), value: 'player_live_event' }
    ];
};
