// theme.js
import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#32E7B2' // Your primary color
        },
        secondary: {
            main: '#2196f3' // Your secondary color
        },
        gray: {
            main: 'rgba(60, 60, 67, 0.6)'
        }
    },
    typography: {
        fontFamily: 'Urbanist',
        fontWeightRegular: 600
    }
});

export default theme;
