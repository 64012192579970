import {
    APP_LOAD,
    CONNECTED_ACCOUNT_LOAD_FINISHED,
    CONNECTED_PRODUCTS_LOAD_FINISHED,
    SET_COMPANY_DATA,
    SET_COMPANY_STATS,
    SET_CONTROL_BAR,
    SHOW_PWA_PROMPT,
    SPINNER_VISIBLE,
    UPLOAD_PROGRESS,
    UPLOAD_REMOVE,
    UPLOAD_VIDEO_FINISHED
} from '../constants/actionTypes';

const defaultState = {
    appName: 'Fantisy',
    spinner: false,
    company: null,
    upload: [],
    connected_account: null,
    connected_products: [],
    pwaPromptFlag: false,
    controlBar: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                appLoaded: true
            };
        case SPINNER_VISIBLE:
            return {
                ...state,
                spinner: action.payload
            };
        case SET_COMPANY_DATA:
            return {
                ...state,
                company: action.payload
            };
        case SET_COMPANY_STATS:
            return {
                ...state,
                company: { ...state.company, ...{ streamingStats: { ...action.payload } } }
            };
        case CONNECTED_ACCOUNT_LOAD_FINISHED:
            return {
                ...state,
                connected_account: action.payload
            };
        case CONNECTED_PRODUCTS_LOAD_FINISHED:
            return {
                ...state,
                connected_products: action.payload
            };
        case UPLOAD_PROGRESS:
        case UPLOAD_VIDEO_FINISHED: {
            let _upload = state.upload;
            if (_upload.find((x) => x.jobId == action.payload.jobId)) {
                _upload = _upload.map((x) => {
                    if (x.jobId == action.payload.jobId) {
                        return action.payload;
                    }
                    return x;
                });
            } else {
                _upload = [..._upload, action.payload];
            }
            return {
                ...state,
                upload: _upload
            };
        }
        case UPLOAD_REMOVE:
            return {
                ...state,
                upload: [
                    ...state.upload.filter((e) => {
                        action.payload.some((u) => e.jobId !== u.correlation_id);
                    })
                ]
            };
        case SHOW_PWA_PROMPT:
            return {
                ...state,
                pwaPromptFlag: action.payload
            };
        case SET_CONTROL_BAR:
            return {
                ...state,
                controlBar: action.payload
            };

        default:
            return state;
    }
};
