import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import styles from './premiumContent.module.scss';
import GetPremium from './GetPremium';
import EventSummary from './EventSummary';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import sanity from '../../sanity';

const mapStateToProps = (state) => {
    return {
        company: state.common.company
    };
};

const PremiumContent = (props) => {
    const { t } = useTranslation();
    const { isPreview, event, isLive, user } = props;
    const [flag, setFlag] = useState(false);
    const [bannerVisible, setBannerVisible] = useState(false);
    const [logo, setLogo] = useState(false);
    const [currentUser, setCurrentUser] = useState(user);
    const history = useHistory();

    useEffect(() => {
        setLogo(
            props.company &&
                props.company.company_logo_text &&
                sanity.urlFor(props.company.company_logo_text.asset._ref)
        );
        if (props.user.data) {
            setCurrentUser(props.user.data);
        }
    }, [props]);

    useEffect(() => {
        let interval = setInterval(() => {
            (() => {
                if (currentUser.loaded && !currentUser.data) {
                    setBannerVisible(true);
                    setFlag(true);
                    clearInterval(interval);
                }
            })();
        }, 4000);
    }, [currentUser]);

    return (
        <Box>
            <div className={styles.wrapper}>
                <div className={styles.innerContent}>
                    <div className={styles.iconWrapper}>
                        <LockIcon className={styles.lockIcon} />
                    </div>
                    <Typography className={styles.textContent}>
                        {t('AvailableOnlyForPremium')}
                    </Typography>
                </div>
                {flag ? (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setFlag(false);
                            history.push('/register');
                        }}
                        className={styles.child}>
                        <Typography
                            color={'white !important'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                width: '100%',
                                padding: '1%'
                            }}>
                            {t('SignupForFullVideo')}
                        </Typography>
                    </div>
                ) : null}

                {bannerVisible && (
                    <div
                        onClick={() => setBannerVisible(false)}
                        className={`${styles.wrapperOverlay} ${styles.unselectable}`}
                        style={{ backdropFilter: 'blur(4px)' }}>
                        <Box className={styles.content}>
                            <Typography variant="h5" sx={{ marginY: 4 }}>
                                {t('SignupContinueWatching')}
                            </Typography>
                            <img
                                src={logo}
                                style={{ height: '100px', width: '100px', borderRadius: '50%' }}
                                alt="Logo"
                            />
                            <Typography variant="h6" sx={{ marginY: 4 }}>
                                {t('SignupForOtherContent')}
                            </Typography>
                            <Button
                                onClick={() => {
                                    setFlag(false);
                                    history.push('/register');
                                }}
                                sx={{
                                    color: 'white',
                                    backgroundColor: 'black',
                                    paddingY: '12px',
                                    marginY: '12px',
                                    width: '100%',
                                    textTransform: 'capitalize',
                                    '&:active, &:focus, &:hover': {
                                        color: 'white',
                                        backgroundColor: 'black'
                                    }
                                }}>
                                {t('SignUp')}
                            </Button>
                            <Button
                                onClick={() => setBannerVisible(false)}
                                variant="text"
                                sx={{
                                    color: 'black',
                                    paddingY: '12px',
                                    textTransform: 'capitalize',
                                    width: '100%',
                                    border: '1px solid black',
                                    '&:active, &:focus, &:hover': {
                                        color: 'black',
                                        backgroundColor: 'white'
                                    }
                                }}>
                                {t('Cancel')}
                            </Button>
                        </Box>
                    </div>
                )}
            </div>
            <EventSummary event={event} isLive={isLive} />
            {!isPreview && (
                <Box
                    py={2}
                    px={3}
                    sx={{ borderTop: '1px solid lightgray', borderBottom: '1px solid lightgray' }}>
                    <Typography variant="h6">{t('Comments')}</Typography>
                </Box>
            )}
            {!isPreview && <GetPremium event={event} user={user} />}
        </Box>
    );
};

export default connect(mapStateToProps, null)(PremiumContent);
