import { IonContent, IonPage } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SET_USER_DATA } from '../../constants/actionTypes';
import cognitoService from '../../core/service/cognito.service';
import styles from '../../styles/auth.module.scss';
import OnBack from './OnBack';
import SubmitButton from './SubmitButton';

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload })
});

const PermissionsStep = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentUser = await cognitoService.getUserData();
        props.onSetUserData(currentUser);
        history.push('/events');
    };

    useEffect(() => {
        window.ContactsX &&
            window.ContactsX.hasPermission(
                function (success) {
                    if (success.read) {
                        console.log(success);
                    } else {
                        window.ContactsX.requestPermission(
                            function (success) {
                                console.log(success);
                            },
                            function (error) {
                                console.error(error);
                            }
                        );
                    }
                    console.log(success);
                },
                function (error) {
                    console.error(error);
                }
            );
    }, []);

    return (
        <IonPage>
            <OnBack defaultHref={'/upload-image'} />
            <IonContent>
                <div className={styles.authWrapper}>
                    <form onSubmit={handleSubmit} style={{ flex: 0 }}>
                        <h1>
                            {t('AccessPermissionQuestion', {
                                company_name: `${props.company.name}`
                            })}
                        </h1>
                        <p>
                            {t('AllowSeeFriendsPermission', {
                                company_name: `${props.company.name}`
                            })}
                        </p>
                        <SubmitButton buttonText={t('Next')} />
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsStep);
