
const zeroDecimal = [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
];

const threeDecimal  = [
    'BHD',
    'JOD',
    'KWD',
    'OMR',
    'TND'
]

function roundToNearestTen(amount) {
    // Multiply the amount by 100 to move the decimal point two places to the right
    // Use Math.round() to round to the nearest whole number
    // Then, divide by 100 to move the decimal point back to the original position
    const roundedAmount = Math.round(amount * 100) / 100;

    // Multiply the rounded amount by 10 to round to the nearest ten
    return Math.round(roundedAmount * 10);
}

const toUnitAmount = ( amount , isoCurrency = 'EUR' ) => {
    if (!isoCurrency) {
        throw Error(`isoCurrency is required parameter!`)
    }
    if (threeDecimal.includes(isoCurrency)) {
        return roundToNearestTen(amount);
    } else if (zeroDecimal.includes(isoCurrency)) {
        return amount;
    } else {
        return amount * 100;
    }
}

const fromUnitAmount = ( amount, isoCurrency = 'EUR' ) => {
    if (!isoCurrency) {
        throw Error(`isoCurrency is required parameter!`)
    }
    if (threeDecimal.includes(isoCurrency) || zeroDecimal.includes(isoCurrency)) {
        return amount;
    } else {
        return amount / 100;
    }
}

module.exports = {
    toUnitAmount,
    fromUnitAmount
}