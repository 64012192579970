import { IonRouterLink } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './menu.module.scss';
const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser
    };
};

const UserProfile = (props) => {
    const { t } = useTranslation();
    const user = props.user;
    let profilePicture;
    let initials;
    if (user && user.data) {
        if (user.data.picture) {
            profilePicture = user.data.picture;
        } else {
            initials = user.data.given_name?.charAt(0) + user.data.family_name?.charAt(0);
        }
    }

    return (
        <React.Fragment>
            {user.loaded && user.data && (
                <div className={styles.profileWrapper}>
                    <IonRouterLink routerLink="/profile">
                        {user.data.picture ? (
                            <img
                                src={profilePicture}
                                alt={t('UserProfile')}
                                style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                    marginTop: '8%'
                                }}
                            />
                        ) : (
                            <div
                                style={{ backgroundColor: props.backgroundColor }}
                                className={styles.profilePictureGenerated}>
                                {initials}
                            </div>
                        )}
                    </IonRouterLink>
                </div>
            )}
        </React.Fragment>
    );
};

export default connect(mapStateToProps, null)(UserProfile);
