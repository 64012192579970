import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from '../../styles/auth.module.scss';

const OnBack = ({
    disableBack,
    showButtonOrLoader,
    buttonDisabled,
    buttonText,
    title,
    onClick,
    defaultHref
}) => {
    const history = useHistory();

    return (
        <IonHeader mode="md" className={styles.authHeader}>
            <IonToolbar mode="md">
                <IonButtons slot="start">
                    {!disableBack && history.length > 1 && (
                        <IonBackButton defaultHref={defaultHref} text="" />
                    )}
                </IonButtons>

                <IonTitle
                    style={{
                        marginLeft: showButtonOrLoader === 'button' ? '' : '-24px',
                        textWrap: 'wrap'
                    }}>
                    <div className={styles.centerTitle} style={{ width: '100%', textWrap: 'wrap' }}>
                        {title}
                    </div>
                </IonTitle>

                {showButtonOrLoader === 'button' ? (
                    <IonButtons slot="end">
                        <IonButton
                            id="open-loading"
                            className={styles.saveButton}
                            onClick={() => onClick()}
                            disabled={buttonDisabled}>
                            {buttonText}
                        </IonButton>
                    </IonButtons>
                ) : showButtonOrLoader === 'loader' ? (
                    <CircularProgress
                        sx={{
                            padding: '0px !important',
                            display: 'inline',
                            position: 'absolute',
                            right: '0',
                            top: '10px',
                            fontSize: '18px',
                            width: 'auto'
                        }}
                    />
                ) : null}
            </IonToolbar>
        </IonHeader>
    );
};

export default OnBack;
