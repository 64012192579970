import { IonContent, IonPage, useIonToast } from '@ionic/react';
import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { goBack } from 'connected-react-router';
import { checkmarkCircleSharp, closeCircleSharp } from 'ionicons/icons';
import countryService from 'iso-country-currency';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import stripeGrey from '../../assets/images/stripe-grey.svg';
import {
    CUSTOM_PHONE_CREATE_CHALLENGE,
    SET_USER_DATA,
    SPINNER_VISIBLE
} from '../../constants/actionTypes';
import { expressCountries } from '../../constants/express';
import cognitoService from '../../core/service/cognito.service';
import fetchData from '../../fetch';
import { getBase64 } from '../../helpers/FileReader';
import { isColorSimilar } from '../../utils';
import OnBack from '../Auth/OnBack';
import useImageConversion from '../hooks/useImageConversion';
import styles from './settings.module.scss';

const baseDomain = process.env.REACT_APP_API_URL?.split('api')[1];

const mapStateToProps = (state) => {
    return {
        user: state.user.currentUser,
        company: state.common.company,
        isAdmin: state.user.currentUser.groups && state.user.currentUser.groups.includes('Admin'),
        connected_account: state.common.connected_account
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGoBack: () => dispatch(goBack()),
    onSpinner: (payload) => dispatch({ type: SPINNER_VISIBLE, payload }),
    onSetUserData: (payload) => dispatch({ type: SET_USER_DATA, payload }),
    onSendCode: (payload) => dispatch({ type: CUSTOM_PHONE_CREATE_CHALLENGE, payload })
});

const Account = (props) => {
    const { t } = useTranslation();
    const hiddenFileInput = React.useRef(null);
    const [changed, setChanged] = useState(false);
    const [photo, setPhoto] = useState('');
    const [imagePreview, setImagePreview] = useState(props.user.imagePreview || '');
    // const [errorMessage, setErrorMessage] = useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
    const [nicknameErrorMessage, setNicknameErrorMessage] = useState('');
    const [photoErrorMessage, setPhotoErrorMessage] = useState('');
    const [stripeErrorMessage, setStripeErrorMessage] = useState('');
    // const [phoneErrorMessage,setPhoneErrorMessage]= useState("")
    const [invalidNumber, setInvalidNumber] = useState(false);
    const { data: user } = props.user;
    let backgroundColor = '#DBDDE0';
    const [present] = useIonToast();
    const presentToast = (message, icon, color, duration) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            color: color
        });
    };

    const location = useLocation();
    const showMessage = location.state?.flag;

    if (!user.picture) {
        if (props.company !== null && props.company.primaryColor !== null) {
            const isSimilar = isColorSimilar(props.company.primaryColor, '#DBDDE0', 50);
            backgroundColor = isSimilar ? '#444444' : '#DBDDE0';
        }
    }

    // const [disabledMailField, setDisabledMailField] = useState(false);
    // const [disabledPhoneField, setDisabledPhoneField] = useState(false);

    // const [validPhone, setValidPhone] = useState(true);
    // const [validMail, setValidMail] = useState(true);
    const [loading, setLoading] = useState(false);
    const [onboardingMessage, setOnboardingMessage] = useState('');

    const metadata = props.connected_account?.metadata
        ? JSON.parse(props.connected_account?.metadata)
        : {};

    const {
        register,
        control,
        handleSubmit,
        getValues,
        formState: { isDirty, errors }
    } = useForm({
        defaultValues: {
            business_type: 'individual',
            country: metadata.country,
            email: user.email,
            firstName: user.given_name,
            lastName: user.family_name,
            nickname: user.nickname,
            phoneNumber: user?.phone_number
        }
    });

    let profilePicture;
    let initials;

    const accountTypes = [
        { title: t('Private'), value: 'individual' },
        { title: t('Business'), value: 'company' }
    ];

    // const [isMessageShown, setIsMessageShown] = useState(true);
    if (user.picture) {
        profilePicture = user.picture;
    } else {
        initials = user.given_name?.charAt(0) + user.family_name?.charAt(0);
    }

    const validateNumber = (number) => {
        const phoneRegex = /^[+\d]?(?:[\d-.\s()]*)$/;
        const valid =
            phoneRegex.test(number) &&
            number.replace(/\D/g, '').length >= 10 &&
            number.replace(/\D/g, '').length <= 15;
        setInvalidNumber(!valid);
        return valid;
    };

    // const validateEmail = (data) => {
    //     let regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

    //     let status = false;
    //     if (data.match(regexEmail)) {
    //         status = true;
    //         setValidMail(true);
    //     } else {
    //         setValidMail(false);
    //     }

    //     //console.log(status);
    //     return status;
    // };

    const onSave = async () => {
        const [name, surname, nickname, phoneNumber] = getValues([
            'firstName',
            'lastName',
            'nickname',
            'phoneNumber'
        ]);
        if (!name.trim()) {
            setFirstNameErrorMessage(t('RequiredName'));
            return;
        }

        if (!surname.trim()) {
            setLastNameErrorMessage(t('RequiredSurname'));
            return;
        }

        if (!nickname) {
            setNicknameErrorMessage(t('RequiredNickname'));
            return;
        }
        if (phoneNumber.trim() !== '') {
            const valid = validateNumber(phoneNumber);
            if (!valid) {
                return;
            }
        }
        setLoading(true);
        try {
            if (photo) {
                const base64Image = await getBase64(photo);
                const image = base64Image.split(';base64,')[1];

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ image })
                };
                const { thumbnailUrl } = await fetchData.Image.Upload(requestOptions);

                await cognitoService.addPicture(thumbnailUrl);
            }
            setPhotoErrorMessage(null);
        } catch (e) {
            setPhotoErrorMessage(t('UploadError'));
            setLoading(false);
            return;
        }

        try {
            await cognitoService.addPhoneNumber(phoneNumber);
        } catch (e) {
            console.log(e.message);
            setLoading(false);
            return;
        }

        try {
            const resp = await cognitoService.updateUser(
                name.trim(),
                surname.trim(),
                nickname.trim()
            );
            console.log(resp);
        } catch (e) {
            setLoading(false);
            console.log(e.message);
            return;
        }

        const currentUser = await cognitoService.getUserData();
        props.onSetUserData(currentUser);
        setLoading(false);
        presentToast('Saved changes.', checkmarkCircleSharp, 'success', 1500);
    };

    const stripeOnboard = async (data) => {
        try {
            props.onSpinner(true);
            let localDomain = undefined;
            const hostname = window.location.hostname;
            if (hostname === 'localhost' || hostname === '127.0.0.1') {
                localDomain = 'http://localhost:3000';
            }
            const domain = !props.company.domain
                ? `${props.company?.name}${baseDomain}`
                : props.company.domain;
            const companyDomain = localDomain || `https://${domain}`;
            const stripeAccountOnboardingInput = {
                companyId: props.company._id,
                companyDomain,
                country: data.country,
                email: data.email,
                business_type: data.business_type,
                firstName: data.firstName,
                lastName: data.lastName,
                callback_path: '/profile/settings/products?fromStripe=true'
            };
            let stripeLink = await fetchData.StripeAccount.createAccountStripe({
                method: 'POST',
                body: JSON.stringify(stripeAccountOnboardingInput)
            });

            if (stripeLink) {
                window.location.replace(stripeLink.url);
            }
        } catch (e) {
            props.onSpinner(false);
            setStripeErrorMessage(e.message);
            return;
        } finally {
            props.onSpinner(false);
        }
    };

    const handleClick = () => {
        hiddenFileInput.current.accept = 'image/*';
        hiddenFileInput.current.click();
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let currentFile = e.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = currentFile.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            presentToast(
                'Please select a valid image file (jpg, jpeg, png).',
                closeCircleSharp,
                'danger',
                1500
            );
            return;
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        currentFile = await useImageConversion(currentFile);

        reader.onloadend = () => {
            setImagePreview(reader.result);
            setChanged(true);
        };
        setPhoto(currentFile);
        reader.readAsDataURL(currentFile);
        if (e.target?.files.length > 0) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setChanged(true);
            };
            setPhoto(currentFile);
            reader.readAsDataURL(currentFile);
        }
    };

    useEffect(() => {
        if (showMessage) {
            setOnboardingMessage(t('OnboardingExplanation'));
        }
    }, []);

    return (
        <IonPage>
            <OnBack
                defaultHref={'/profile/settings'}
                onClick={onSave}
                title={t('Account')}
                showButtonOrLoader={loading ? 'loader' : 'button'}
                buttonDisabled={!isDirty && !changed}
                buttonText={t('Save')}
            />
            <IonContent>
                <div
                    className={styles.profileWrapper}
                    style={{ minHeight: props.isAdmin ? '100vh' : '100%' }}>
                    <div className={styles.profilePhoto} style={{ alignItems: 'flex-start' }}>
                        <div className={styles.profilePhotoEdit}>
                            {!imagePreview && user.picture && (
                                <img
                                    style={{
                                        width: 123,
                                        height: 120,
                                        borderRadius: '85px',
                                        cursor: 'pointer'
                                    }}
                                    src={profilePicture}
                                    alt={t('Avatar')}
                                    onClick={handleClick}
                                />
                            )}
                            {!imagePreview && !user.picture && (
                                <div
                                    style={{ backgroundColor: backgroundColor, cursor: 'pointer' }}
                                    className={styles.profilePhotoGenerated}
                                    onClick={handleClick}>
                                    {initials}
                                </div>
                            )}
                            {imagePreview && (
                                <img
                                    style={{
                                        width: 123,
                                        height: 120,
                                        borderRadius: '85px',
                                        cursor: 'pointer'
                                    }}
                                    src={imagePreview}
                                    alt={t('Avatar')}
                                    onClick={handleClick}
                                />
                            )}
                            <svg
                                style={{ cursor: 'pointer' }}
                                className={styles.edit}
                                onClick={handleClick}
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill={'black'} />
                                <path
                                    d="M17.5 26.8749H13.75C13.5842 26.8749 13.4253 26.8091 13.3081 26.6918C13.1908 26.5746 13.125 26.4157 13.125 26.2499V22.7588C13.125 22.6767 13.1412 22.5954 13.1726 22.5196C13.204 22.4438 13.25 22.3749 13.3081 22.3168L22.6831 12.9418C22.8003 12.8246 22.9592 12.7588 23.125 12.7588C23.2908 12.7588 23.4497 12.8246 23.5669 12.9418L27.0581 16.433C27.1753 16.5502 27.2411 16.7091 27.2411 16.8749C27.2411 17.0407 27.1753 17.1996 27.0581 17.3168L17.5 26.8749Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.625 15L25 19.375"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear"
                                        x1="36"
                                        y1="-1.44541e-06"
                                        x2="3.5"
                                        y2="40"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#57B177" />
                                        <stop offset="1" stopColor="#367B4E" />
                                    </linearGradient>
                                </defs>
                            </svg>

                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={hiddenFileInput}
                                onChange={handleImageChange}
                            />
                        </div>
                        {/* <p className="error">ASDasd asdasda</p> */}
                    </div>
                    {photoErrorMessage && (
                        <div style={{ textAlign: 'center', color: 'red' }}>{photoErrorMessage}</div>
                    )}
                    {props.isAdmin && (
                        <div>
                            {props.connected_account?.onboarded ? (
                                <p>{t('AccountOnboardSuccess')}</p>
                            ) : (
                                <p>{t('AccountNotOnboarded')}</p>
                            )}
                        </div>
                    )}
                    {onboardingMessage && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {onboardingMessage}
                        </div>
                    )}
                    <form
                        style={{ minHeight: '0px' }}
                        className={styles.resetInput}
                        onSubmit={handleSubmit(stripeOnboard)}>
                        <main className={`${styles.profileInputWrapper}`}>
                            {props.isAdmin && (
                                <>
                                    <h4>{t('AccountType')}</h4>
                                    <Controller
                                        name="business_type"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                            fieldState: { error },
                                            formState
                                        }) => (
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                value={value}
                                                name="radio-buttons-group"
                                                onChange={onChange}

                                                // onChange={(e) => {
                                                //     setBusinessTypes(e.target.value);
                                                //     setChanged(true);
                                                // }}
                                            >
                                                {accountTypes.map((t) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={t.value}
                                                            disabled={
                                                                props.connected_account
                                                                    ?.onboarded || false
                                                            }
                                                            value={t.value}
                                                            control={<Radio />}
                                                            label={t.title}
                                                        />
                                                    );
                                                })}
                                            </RadioGroup>
                                        )}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="country-label">{t('Country')}</InputLabel>
                                        <Select
                                            labelId="country-label" // Associate the Select with the InputLabel
                                            label={t('Country')}
                                            sx={{ marginBottom: '12px' }}
                                            defaultValue={metadata.country ?? 'AT'}
                                            {...register('country', {
                                                required: !props.connected_account?.onboarded
                                            })}
                                            disabled={props.connected_account?.onboarded || false}>
                                            {countryService
                                                .getAllISOCodes()
                                                .filter((c) =>
                                                    expressCountries.some((v) => v.iso === c.iso)
                                                )
                                                .map((t) => {
                                                    return (
                                                        <MenuItem key={t.iso} value={t.iso}>
                                                            {t.countryName}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                            <TextField
                                error={firstNameErrorMessage.length > 0}
                                sx={{
                                    marginBottom: '12px'
                                }}
                                onFocus={() => setFirstNameErrorMessage('')}
                                type="text"
                                {...register('firstName')}
                                fullWidth
                                // InputLabelProps={{ shrink: true }}
                                label={t('Name')}
                            />
                            {firstNameErrorMessage && (
                                <div style={{ marginBottom: '12px', color: 'red' }}>
                                    {firstNameErrorMessage}
                                </div>
                            )}
                            <TextField
                                error={lastNameErrorMessage.length > 0}
                                sx={{
                                    marginBottom: '12px'
                                }}
                                onFocus={() => setLastNameErrorMessage('')}
                                type="text"
                                {...register('lastName')}
                                label={t('Surname')}
                                fullWidth
                            />
                            {lastNameErrorMessage && (
                                <div style={{ marginBottom: '12px', color: 'red' }}>
                                    {lastNameErrorMessage}
                                </div>
                            )}
                            <TextField
                                error={nicknameErrorMessage.length > 0}
                                sx={{
                                    marginBottom: '12px'
                                }}
                                onFocus={() => setLastNameErrorMessage('')}
                                type="text"
                                label={t('Nickname')}
                                {...register('nickname')}
                                fullWidth
                            />
                            {nicknameErrorMessage && (
                                <div style={{ marginBottom: '12px', color: 'red' }}>
                                    {nicknameErrorMessage}
                                </div>
                            )}
                            <TextField
                                sx={{ marginBottom: '12px' }}
                                type="email"
                                {...register('email')}
                                placeholder={'ex. mail@mail.com'}
                                style={{ color: 'lightgray' }}
                                disabled={true}
                                label={'Email'}
                                fullWidth
                            />
                            {/* {isMessageShown && !email && (
                                <span style={{ color: 'red' }} id="mail">
                                    {t('AddEmailAddress')}
                                </span>
                            )} */}
                            <TextField
                                error={invalidNumber}
                                sx={{ marginBottom: '12px' }}
                                type="text"
                                {...register('phoneNumber')}
                                // pattern="[0-9]+"
                                placeholder={'ex. +46722222222'}
                                // style={disabledPhoneField ? { color: 'lightgray' } : {}}
                                // disabled={disabledPhoneField}
                                onFocus={() => setInvalidNumber(false)}
                                // onBlur={(e) => validateNumber(e.target.value)}
                                label={t('MobileNumber')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                            />
                            {/* {!user?.phone_number && (
                                <span style={{ color: 'red' }} id="phone">
                                    {t('AddMobileNumber')}
                                </span>
                            )} */}
                            {invalidNumber && (
                                <span style={{ color: 'red' }} id="phone">
                                    {t('EnterValidMobileNumber')}
                                </span>
                            )}
                            {props.isAdmin && (
                                <>
                                    <h4>{t('PaymentInformation')}</h4>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '16px 14px',
                                            border: '1px solid #00000030',
                                            lineHeight: '1.4375rem'
                                        }}>
                                        <div>{t('PaymentSetupInstructions')}</div>
                                        <img style={{ height: '31px' }} src={stripeGrey} />
                                    </div>
                                    {stripeErrorMessage && (
                                        <div style={{ marginTop: '12px', color: 'red' }}>
                                            {stripeErrorMessage}
                                        </div>
                                    )}
                                    <button style={{ marginTop: '2vh' }} type="submit">
                                        {props.connected_account?.onboarded
                                            ? t('ContinueToEdit')
                                            : t('SaveAndContinue')}
                                    </button>
                                </>
                            )}
                        </main>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
